import { FC, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Colors } from "../../../../configs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { cloneDeep } from "lodash";
import { CartItem } from "../../../../typings/interfaces/pos/cart-item";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { getSecondSubCategoryAction } from "../../../../redux/actions/pricingAction";
import { sortArray } from "../../../../util/array-functions";
import { AlterationSubCategoryResponse } from "../../../../typings/interfaces/responses/alteration-sub-category-response";
import { IAlterationItem } from "../../../../typings/interfaces/pos/alterationItem";
import { CustomButton } from "../../../atoms";

export interface IGarmentModificationSectionProps {}

const GarmentModificationSection: FC<IGarmentModificationSectionProps> = () => {
  const { selectedServiceType, selectedCartItem } = useAppSelector(
    (state) => state.cart
  );
  const { alterationModificationType } = useAppSelector(
    (state) => state.pricing
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSecondSubCategoryAction());
  }, [selectedServiceType]);

  const handleOnSelectType = (
    alterationSubCat: AlterationSubCategoryResponse
  ) => {
    let cartItem: CartItem;
    if (selectedCartItem) {
      cartItem = cloneDeep(selectedCartItem);

      // Check for incomplete alteration items and update if found
      const uncompletedItemIndex = cartItem.alterationItems.findIndex(
        (item) => !item.alterationSubCategory || !item.alterationPrice
      );

      if (uncompletedItemIndex !== -1) {
        cartItem.alterationItems[uncompletedItemIndex].alterationSubCategory =
          alterationSubCat;
      } else {
        // If no incomplete item is found, add a new one
        const newItem: IAlterationItem = {
          alterationSubCategory: alterationSubCat,
        };
        cartItem.alterationItems.push(newItem);
      }

      dispatch(updateCartSessionItem({ cartItem }));
    }
  };

  const getIncompleteItemType = (cartItem: CartItem | null) => {
    const incompleteItem = cartItem?.alterationItems.find(
      (item) => !item.alterationSubCategory || !item.alterationPrice
    );
    return incompleteItem ? incompleteItem.alterationSubCategory?.id : null;
  };

  const incompleteItemType = getIncompleteItemType(selectedCartItem);

  const garmentModificationSorted = sortArray({
    array: alterationModificationType || [],
    sortByKey: "materialName",
  });

  const itemsPerColumn =
    garmentModificationSorted?.length <= 12
      ? 3
      : Math.ceil(garmentModificationSorted?.length / 4);
  const totalRows = Math.ceil(
    (garmentModificationSorted?.length || 0) / itemsPerColumn
  );

  return (
    <Box
      sx={{
        flex: 1,
        flexShrink: 1,
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        padding: "0.928571429rem",
        height: "20.7857143rem",
        maxHeight: "20.7857143rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          height: "18.1428572rem",
          maxHeight: "18.1428572rem",
          width: "21.5714286rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignContent: "flex-start",
          gap: "0.928571429rem",
          overflowX: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          touchAction: "pan-x",
        }}
      >
        {[...Array(totalRows)].map((_, rowIndex) => {
          const startingIndex = rowIndex * itemsPerColumn;
          const lastIndex = startingIndex + itemsPerColumn;

          const currentRowItems = garmentModificationSorted
            ? garmentModificationSorted.slice(startingIndex, lastIndex)
            : [];

          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
              key={rowIndex}
            >
              {currentRowItems.map((modification: any, i) => (
                <CustomButton
                  onClick={() =>
                    handleOnSelectType({
                      id: modification.materialId,
                      name: modification.materialName,
                      deleted: false,
                    })
                  }
                  sx={{
                    width: "6.85714286rem",
                    maxHeight: "3.78571429rem",
                    height: "3.78571429rem",
                    background:
                      incompleteItemType === modification.materialId
                        ? Colors.SECONDARY
                        : Colors.MAIN_GRAY,
                    borderRadius: "0.71rem",
                    textTransform: "capitalize",
                    color: Colors.WHITE,
                    fontSize: "1rem",
                    lineHeight: "16.41px",
                    "&:hover": { background: `${Colors.MAIN_GRAY} !important` },
                  }}
                  key={i}
                >
                  {modification.materialName}
                </CustomButton>
              ))}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default GarmentModificationSection;
