import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {IUserResponse} from "../../interfaces";

const PrivateRoute = (props:{token?:string, children:JSX.Element, user?:IUserResponse, authDetailsInitialized?: boolean, disableAdminGuard?: boolean, disablePosGuard?: boolean}) => {
    if(props.user) {
        if((props.user.role.name==="ADMIN" || props.user.role.name==="MERCHANT_ADMIN") && !props.disableAdminGuard) {
            return <Navigate to={'/admin/dashboard'}/>
        } else if(props.user.role.name.includes("CASHIER") && !props.disablePosGuard) {
            return <Navigate to={'/pos/lobby'}/>
        }
    }
    if(!props.authDetailsInitialized){
        return <p>Loading...</p>;
    }
    return (props.token !== null && props.user!==null) ? props.children:<Navigate to={'/auth/login'}/>
}

const mapStateToProps = (state:any) => state.auth;
export default connect(mapStateToProps, null)(PrivateRoute);