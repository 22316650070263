import { createSlice } from "@reduxjs/toolkit";
import { BaseResponseSingle } from "../../typings";
import { IStoreConfigResponse } from "../../typings/interfaces/responses/store-config-response";
import { addStoreConfig, getStoreConfig } from "../actions/storeActions";

export interface IStoreSlice {
  addStoreConfigResponse: BaseResponseSingle<IStoreConfigResponse> | null;
  addStoreConfigLoading: boolean;
  addStoreConfigError: string | null;

  getStoreConfigResponse: BaseResponseSingle<IStoreConfigResponse> | null;
  getStoreConfigLoading: boolean;
  getStoreConfigError: string | null;
}

const initialState: IStoreSlice = {
  addStoreConfigResponse: null,
  addStoreConfigLoading: false,
  addStoreConfigError: null,

  getStoreConfigResponse: null,
  getStoreConfigLoading: false,
  getStoreConfigError: null,
};

const storeSlice = createSlice({
  initialState,
  name: "store",
  reducers: {
    resetStoreConfig: (state) => {
      state.addStoreConfigResponse = null;
      state.addStoreConfigLoading = false;
      state.addStoreConfigError = null;

      state.getStoreConfigResponse = null;
      state.getStoreConfigLoading = false;
      state.getStoreConfigError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addStoreConfig.pending, (state) => {
        state.addStoreConfigResponse = null;
        state.addStoreConfigLoading = true;
        state.addStoreConfigError = null;
      })
      .addCase(addStoreConfig.fulfilled, (state, action) => {
        state.addStoreConfigResponse = action.payload;
        state.addStoreConfigLoading = false;
        state.addStoreConfigError = null;
      })
      .addCase(addStoreConfig.rejected, (state, action) => {
        state.addStoreConfigResponse = null;
        state.addStoreConfigLoading = false;
        state.addStoreConfigError = action.error.message || null;
      })
      .addCase(getStoreConfig.pending, (state) => {
        state.getStoreConfigResponse = null;
        state.getStoreConfigLoading = true;
        state.getStoreConfigError = null;
      })
      .addCase(getStoreConfig.fulfilled, (state, action) => {
        state.getStoreConfigResponse = action.payload;
        state.getStoreConfigLoading = false;
        state.getStoreConfigError = null;
      })
      .addCase(getStoreConfig.rejected, (state, action) => {
        state.getStoreConfigResponse = null;
        state.getStoreConfigLoading = false;
        state.getStoreConfigError = action.error.message || null;
      });
  },
});

export default storeSlice.reducer;
export const storeSliceActions = storeSlice.actions;
