import PayLaterImg from "./PayLater.png";

export const PayLaterIcon = ({ color = "#515151" }) => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   enable-background="new 0 0 24 24"
    //   viewBox="0 0 24 24"
    //   fill={color}
    // >
    //   <path
    //     d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M15,13h-3c-0.6,0-1-0.4-1-1V7c0-0.6,0.4-1,1-1s1,0.4,1,1v4h2c0.6,0,1,0.4,1,1S15.6,13,15,13z"
    //     fill={color}
    //   />
    // </svg>
    // <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M 250.19 5.256 C 115.687 5.256 5.637 115.176 5.637 249.523 C 5.637 383.869 115.687 493.791 250.19 493.791 C 384.696 493.791 494.745 383.869 494.745 249.523 C 494.745 115.176 384.696 5.256 250.19 5.256 Z M 323.557 273.951 L 250.19 273.951 C 235.518 273.951 225.736 264.179 225.736 249.523 L 225.736 127.389 C 225.736 112.734 235.518 102.963 250.19 102.963 C 264.865 102.963 274.646 112.734 274.646 127.389 L 274.646 225.096 L 323.557 225.096 C 338.231 225.096 348.013 234.868 348.013 249.523 C 348.013 264.179 338.231 273.951 323.557 273.951 Z"
    //     fill={color}
    //   />
    // </svg>
    <svg
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
    >
      <path
        d="M 250.19 5.256 C 115.687 5.256 5.637 115.176 5.637 249.523 C 5.637 383.869 115.687 493.791 250.19 493.791 C 384.696 493.791 494.745 383.869 494.745 249.523 C 494.745 115.176 384.696 5.256 250.19 5.256 Z M 323.557 273.951 L 250.19 273.951 C 235.518 273.951 225.736 264.179 225.736 249.523 L 225.736 127.389 C 225.736 112.734 235.518 102.963 250.19 102.963 C 264.865 102.963 274.646 112.734 274.646 127.389 L 274.646 225.096 L 323.557 225.096 C 338.231 225.096 348.013 234.868 348.013 249.523 C 348.013 264.179 338.231 273.951 323.557 273.951 Z"
        fill={color}
      />
    </svg>
  );
};
