import { Box, Button, IconButton, Typography } from "@mui/material";
import { FC } from "react";
import CustomizationsTabMenu from "../../../common/pos/customizations-tab-menu/customizations-tab-menu";
import InstructionSection from "../../../common/pos/instruction-section/instruction-section";
import { Colors } from "../../../../configs";
import ReplayIcon from "@mui/icons-material/Replay";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { cloneDeep } from "lodash";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { PosSliceAction } from "../../../../redux/slice/posSlice";
import { Service } from "../../../../typings/enum/Service";
import { CustomButton } from "../../../atoms";
import { CustomizationTypes } from "../../../../typings/enum/customizationTypes";
import UpchargesSection from "../../../common/pos/upcharges-section/upchargesSection";
import SpotSection from "../../../common/pos/spot-section/spotSection";
import DamageSection from "../../../common/pos/damage-section/damageSection";

const Customization: FC = () => {
  const { selectedCartItem, selectedCustomizationType } = useAppSelector(
    (state) => state.cart
  );
  const { activeServiceTab } = useAppSelector((state) => state.pos);
  const dispatch = useAppDispatch();

  const onDone = () => {
    dispatch(
      PosSliceAction.setActiveServiceTab(Service.GARMENT_WASH_AND_CLEAN)
    );
  };

  return (
    <>
      <Typography sx={{ fontSize: "2.57rem", color: "#4D4D4D" }}>
        Customization
      </Typography>
      <CustomizationsTabMenu />
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          {selectedCustomizationType == CustomizationTypes.INSTRUCTIONS && (
            <InstructionSection />
          )}
          {selectedCustomizationType == CustomizationTypes.UPCHARGES && (
            <UpchargesSection />
          )}
          {selectedCustomizationType == CustomizationTypes.SPOT && (
            <SpotSection />
          )}
          {selectedCustomizationType == CustomizationTypes.DAMAGE && (
            <DamageSection />
          )}
        </Box>
        <Box
          sx={{
            marginTop: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "right",
            padding: "0px 13px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "17px",
              height: "5.85rem",
            }}
          >
            <IconButton
              sx={{
                height: "100%",
                aspectRatio: "1/1",
                background: Colors.SECONDARY,
                borderRadius: "0.71rem",
                "&:hover": {
                  background: `${Colors.SECONDARY} !important`,
                },
                "&:disabled": {
                  background: `${Colors.ACCENT_GRAY} !important`,
                },
              }}
              // onClick={() => onResetInstructions()}
              disabled={selectedCartItem?.instructions.length === 0}
            >
              <ReplayIcon style={{ fontSize: "42px", color: Colors.WHITE }} />
            </IconButton>
            <CustomButton
              disabled={false}
              sx={{
                background: Colors.PRIMARY,
                color: Colors.WHITE,
                fontSize: "36px",
                lineHeight: "42px",
                padding: "16.12px 42px",
                borderRadius: "0.71rem",
                textTransform: "capitalize",
                fontWeight: 400,
                height: "100%",
                whiteSpace: "nowrap",
                "&:hover": {
                  background: `${Colors.PRIMARY} !important`,
                },
              }}
              onClick={() => onDone()}
            >
              Done
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Customization;
