import { Box, Grid } from "@mui/material";
import { useTranslation } from "../../../util/translation-provider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BarChart,
  MonetizationOn,
  LocalOffer,
  PeopleAlt,
  StoreOutlined,
} from "@mui/icons-material";
import StoreIcon from "@mui/icons-material/Store";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

const menuItems = [
  {
    title: "Dashboard",
    link: "/admin/dashboard",
    icon: <DashboardIcon sx={{ color: "primary.main" }} />,
  },
  {
    title: "Analytics",
    link: "/admin/analytics",
    icon: <BarChart sx={{ color: "primary.main" }} />,
  },
  {
    title: "Customers",
    link: "/admin/customers",
    icon: <PersonIcon sx={{ color: "primary.main" }} />,
  },
];

const settings = [
  {
    title: "Price List",
    link: "/admin/config/upload-price",
    icon: <MonetizationOn sx={{ color: "primary.main" }} />,
  },
  {
    title: "Discounts",
    link: "/admin/discounts",
    icon: <LocalOffer sx={{ color: "primary.main" }} />,
  },
  {
    title: "System Users",
    link: "/admin/customers",
    icon: <PeopleAlt sx={{ color: "primary.main" }} />,
  },
  {
    title: "Rack Config",
    link: "/admin/config/rack-config",
    icon: <PeopleAlt sx={{ color: "primary.main" }} />,
  },
  {
    title: "Merchants",
    link: "/admin/config/merchants",
    icon: <PeopleAlt sx={{ color: "primary.main" }} />,
  },
  {
    title: "Stores",
    link: "/admin/config/merchants/presto/stores?merchantId=1",
    icon: <StoreIcon sx={{ color: "primary.main" }} />,
  },
  {
    title: "Cashier Points",
    link: "/admin/config/merchants/presto/stores/1/points",
    icon: <PointOfSaleIcon sx={{ color: "primary.main" }} />,
  },
];

const MenuItem: FC<{
  title: string;
  link: string;
  icon: any;
  active: boolean;
}> = (props) => {
  const navigate = useNavigate();
  const { title, link, icon, active } = props;

  const handleOnClick = () => {
    navigate(link);
  };

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: active ? "adminMenuItem.main" : "none",
        p: "0.71rem",
        pl: "1rem",
      }}
      onClick={handleOnClick}
    >
      {icon}
      <Box sx={{ pl: "1rem" }}>
        <Typography color="primary.main" fontSize={"1.6rem"}>
          {title}
        </Typography>
      </Box>
    </Grid>
  );
};

export const AdminSideNav = () => {
  const translation = useTranslation();
  const location = useLocation();
  const thisYear = new Date().getFullYear();
  return (
    <Grid
      sx={{
        height: "100%",
        width: "250px",
        boxShadow: " 2px 0px 0.71rem -4px rgba(0,0,0,0.18)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid flex={1}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: "0.71rem",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "center", height: "50px" }}
          >
            <img
              src={translation.loginLogo}
              alt="LOGO"
              width={180}
              style={{
                objectFit: "contain",
                marginTop: "0.5rem",
              }}
            />
          </Box>
          <Box sx={{ pl: "0.71rem", pb: "6px", mt: "60px" }}>
            <Typography
              color="textHeader.main"
              sx={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              MENU
            </Typography>
          </Box>
          <Grid flex={1}>
            {menuItems.map((item) => {
              return (
                <MenuItem
                  link={item.link}
                  title={item.title}
                  icon={item.icon}
                  active={location.pathname === item.link}
                />
              );
            })}
          </Grid>

          <Box sx={{ pl: "0.71rem", pb: "6px", mt: "30px" }}>
            <Typography
              color="textHeader.main"
              sx={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              SETTINGS
            </Typography>
          </Box>
          {settings.map((item) => {
            return (
              <MenuItem
                link={item.link}
                title={item.title}
                icon={item.icon}
                active={location.pathname === item.link}
              />
            );
          })}
        </Box>
      </Grid>
      <Grid sx={{ pb: "0.71rem" }}>
        <p style={{ fontSize: "1rem", color: "#515151", textAlign: "center" }}>
          All rights reserved. {thisYear} ©<br /> Teach Me It (Pvt) Ltd
          <br /> <u>Privacy Policy</u> and <u>Terms and Conditions</u>
        </p>
      </Grid>
    </Grid>
  );
};
