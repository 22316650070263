import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Colors } from "../../../configs";
import {
  deleteStoreCreditAction,
  getCustomerByIdAction,
  getStoreCreditHistoryAction,
} from "../../../redux/actions/customerAction";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { formatCurrency, formatDateTime } from "../../../util/common-functions";
import { paymentTypeLabels } from "../../../typings/enum/paymentTypes";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import ConfirmDialog from "../../atoms/confirm-box/confirm-box";

export interface IStoreCreditHistoryProps {
  customerID: number;
  isCustomerEdited: boolean;
  resetDirtyState(isDirty: Boolean): void;
}

const StoreCreditHistory: FC<IStoreCreditHistoryProps> = ({
  customerID,
  isCustomerEdited,
  resetDirtyState,
}) => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbarContext();
  const {
    storeCreditHistoryResponse,
    getStoreCreditHistoryLoading,
    deleteStoreCreditLoading,
  } = useAppSelector((state) => state.customer);
  const createData = (
    dateTime: string,
    addedBy: string,
    paymentType: string,
    amount: number,
    customerId: number,
    noteId: number
  ) => {
    console.log(dateTime);
    // const dateTimeObj = new Date(dateTime);
    // const date = dateTimeObj.toLocaleDateString();
    const dateTimeString = formatDateTime(dateTime);
    const paymentTypeString = paymentTypeLabels(paymentType);
    return {
      dateTimeString,
      addedBy,
      paymentTypeString,
      amount,
      customerId,
      noteId,
    };
  };

  useEffect(() => {
    customerID && dispatch(getStoreCreditHistoryAction(customerID));
  }, [customerID]);

  const rows = storeCreditHistoryResponse?.body?.map((item) => {
    return createData(
      item.createdTime,
      item.storeUser.user.firstName + " " + item.storeUser.user.lastName,
      item.paymentType,
      item.amount,
      item.customer.id,
      item.id
    );
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(11);
  const [discardDialogOpen, setDiscardDialogOpen] = useState<{
    open: boolean;
    customerId: number | null;
    noteId: number | null;
  }>({
    open: false,
    customerId: null,
    noteId: null,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, (rows?.length || 0) - page * rowsPerPage);

  const onDelete = (customerId: number, noteId: number) => {
    setDiscardDialogOpen({
      open: false,
      customerId: null,
      noteId: null,
    });
    dispatch(
      deleteStoreCreditAction({ customerId: customerId, noteId: noteId })
    ).then((res) => {
      if (res.payload) {
        if ((res.payload as any).status === "SUCCESS") {
          showSnackbar("Store credit deleted successfully", "success");
        } else {
          showSnackbar("Failed to delete store credit", "error");
        }
        dispatch(getStoreCreditHistoryAction(customerID));
        dispatch(getCustomerByIdAction(customerID.toString()));
      }
    });
    resetDirtyState(false);
  };
  const handleOnClickDelete = (customerId: number, noteId: number) => {
    if (isCustomerEdited) {
      setDiscardDialogOpen({
        open: true,
        customerId: customerId,
        noteId: noteId,
      });
    } else {
      onDelete(customerId, noteId);
    }
  };

  return (
    <Box>
      <ConfirmDialog
        open={discardDialogOpen.open}
        title="Discard Confirmation"
        message="If you proceed, the changes you've made for the user will be discarded."
        onCancel={() =>
          setDiscardDialogOpen({
            open: false,
            customerId: null,
            noteId: null,
          })
        }
        onConfirm={() =>
          discardDialogOpen.customerId &&
          discardDialogOpen.noteId &&
          onDelete(discardDialogOpen.customerId, discardDialogOpen.noteId)
        }
      />
      <Box
        sx={{
          height: "49rem",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          scrollbarWidth: "none",
          // background: "red",
        }}
      >
        <Table stickyHeader sx={{ minWidth: "46.4285714rem" }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", color: Colors.TEXT_GRAY_DARK }}
              >
                Date & Time
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: "bold", color: Colors.TEXT_GRAY_DARK }}
              >
                Added By
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: "bold", color: Colors.TEXT_GRAY_DARK }}
              >
                Payment Type
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: "bold", color: Colors.TEXT_GRAY_DARK }}
              >
                Amount ($)
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: "bold", color: Colors.TEXT_GRAY_DARK }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rows ? rows : [])
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.dateTimeString}
                  </TableCell>
                  <TableCell align="right">{row.addedBy}</TableCell>
                  <TableCell align="right">{row.paymentTypeString}</TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color:
                        row.amount < 0
                          ? Colors.ERROR_RED
                          : row.amount > 0
                          ? Colors.SUCCESS_GREEN
                          : Colors.TEXT_GRAY_DARK,
                    }}
                  >
                    {formatCurrency({
                      amount: row.amount,
                    })}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() =>
                        handleOnClickDelete(row.customerId, row.noteId)
                      }
                      disabled={deleteStoreCreditLoading}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {getStoreCreditHistoryLoading && (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ height: 53 * 11, textAlign: "center" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 11, 25]}
        component="div"
        count={rows?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ marginTop: "0.5rem" }}
      />
    </Box>
  );
};

export default StoreCreditHistory;
