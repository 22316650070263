import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BaseResponse,
  BaseResponseSingle,
  CustomerBillsResponse,
  ICustomerResponse,
} from "../../typings";
import {
  ADD_STORE_CREDIT,
  GET_CUSTOMER_BILLS_BY_ID,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_CART_INFO_BY_ID,
  CREATE_AND_UPDATE_USER_NOTE,
  GET_USER_NOTES_BY_ID,
  DELETE_USER_NOTE,
  GET_STORE_CREDIT_HISTORY,
  DELETE_STORE_CREDIT,
} from "../../configs";
import { AddStoreCreditRequest } from "../../typings/interfaces/requests/addStoreCreditRequest";
import { CustomerCartInfoResponse } from "../../typings/interfaces/responses/customer-cart-info-response";
import {
  CreateUserNoteRequest,
  UpdateUserNoteRequest,
} from "../../typings/interfaces/requests/user-note-requests";
import { CustomerNoteResponse } from "../../typings/interfaces/responses/customer-note-response";
import { StoreCreditHistoryResponse } from "../../typings/interfaces/responses/store-credit-history-response";
import {
  ICreateCustomerRequest,
  IUpdateCustomerRequest,
} from "../../typings/interfaces/requests/customer-requests";
import { CREATE_CUSTOMER, UPDATE_CUSTOMER } from "../../configs";

export const createCustomerAction = createAsyncThunk(
  "customer/create",
  async (customer: ICreateCustomerRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<BaseResponse<ICustomerResponse>>(
        CREATE_CUSTOMER,
        customer
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCustomerAction = createAsyncThunk(
  "customer/update",
  async (customer: IUpdateCustomerRequest, { rejectWithValue }) => {
    try {
      const response = await axios.put<BaseResponse<ICustomerResponse>>(
        UPDATE_CUSTOMER,
        customer
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCustomerByIdAction = createAsyncThunk(
  "customer/getById",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponseSingle<ICustomerResponse>>(
        GET_CUSTOMER_BY_ID(customerId)
      );
      return response.data.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteStoreCreditAction = createAsyncThunk(
  "customer/delete-store-credit",
  async ({ customerId, noteId }: { customerId: number; noteId: number }) => {
    try {
      const response = await axios.delete<BaseResponse<null>>(
        DELETE_STORE_CREDIT(customerId, noteId)
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export const getCustomerCartInfoByIdAction = createAsyncThunk(
  "customer/get-cart-info",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponseSingle<CustomerCartInfoResponse>
      >(GET_CUSTOMER_CART_INFO_BY_ID(customerId));
      return response.data.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCustomerBillHistoryAction = createAsyncThunk(
  "customer/get-bill-history",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponseSingle<Array<CustomerBillsResponse>>
      >(GET_CUSTOMER_BILLS_BY_ID(customerId));
      return response.data.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addStoreCreditAction = createAsyncThunk(
  "customer/add-store-credit",
  async (
    { customerId, paymentType, amount, storeUserId }: AddStoreCreditRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<BaseResponse<AddStoreCreditRequest>>(
        ADD_STORE_CREDIT,
        {
          customerId,
          paymentType,
          amount,
          storeUserId,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getStoreCreditHistoryAction = createAsyncThunk(
  "customer/get-store-credit-history",
  async (customerId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponse<StoreCreditHistoryResponse>
      >(GET_STORE_CREDIT_HISTORY(customerId));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addUserNoteAction = createAsyncThunk(
  "customer/create-note",
  async (
    { note, occasions, merchantUserId, customerId }: CreateUserNoteRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<BaseResponse<CreateUserNoteRequest>>(
        CREATE_AND_UPDATE_USER_NOTE,
        {
          note,
          occasions,
          merchantUserId,
          customerId,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserNotesByIdAction = createAsyncThunk(
  "customer/get-notes",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<CustomerNoteResponse>>(
        GET_USER_NOTES_BY_ID(customerId)
      );
      let sorted = response.data.body?.sort(
        (a: any, b: any) =>
          new Date(b.createdTime).getTime() - new Date(a.createdTime).getTime()
      );
      return sorted;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateUserNoteAction = createAsyncThunk(
  "customer/update-noet",
  async (
    { id, note, occasions, merchantUserId, customerId }: UpdateUserNoteRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put<BaseResponse<UpdateUserNoteRequest>>(
        CREATE_AND_UPDATE_USER_NOTE,
        {
          id,
          note,
          occasions,
          merchantUserId,
          customerId,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteUserNoteAction = createAsyncThunk(
  "customer/delete-note",
  async (noteId: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete<BaseResponse<null>>(
        DELETE_USER_NOTE(noteId)
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
