export enum PaymentTypeEnum {
  CASH = "CASH",
  CHECK = "CHEQUE",
  CARD = "CARD",
  STORE_CREDIT = "STORE_CREDIT",
  MONTHLY = "MONTHLY",
  LATER = "LATER",
}
const paymentTypeLabelsMap: Record<PaymentTypeEnum, string> = {
  [PaymentTypeEnum.CASH]: "Cash",
  [PaymentTypeEnum.CHECK]: "Check",
  [PaymentTypeEnum.CARD]: "Card",
  [PaymentTypeEnum.STORE_CREDIT]: "Store Credit",
  [PaymentTypeEnum.MONTHLY]: "Monthly",
  [PaymentTypeEnum.LATER]: "Later",
};

export const paymentTypeLabels = (paymentType: string): string => {
  return paymentTypeLabelsMap[paymentType as PaymentTypeEnum] || "";
};
