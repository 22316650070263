import React, { FC, useState } from "react";
import CustomModal from "../../common/custom-modal/custom-modal";
import { Box, Typography } from "@mui/material";
import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import { he } from "@faker-js/faker";

export interface TaxExceptionModalProps {
  open: boolean;
  onClose(): void;
}

const TaxExceptionModal: FC<TaxExceptionModalProps> = ({ open, onClose }) => {
  const [taxID, setTaxID] = useState("");

  return (
    <CustomModal
      title={"Tax Exception"}
      openModal={open}
      width="45rem"
      height="31rem"
      onClose={onClose}
    >
      <Box
        sx={{
          paddingTop: "3rem",
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          <Typography sx={{ color: Colors.TEXT_GRAY_DARK, fontSize: "1.5rem" }}>
            Enter Tax ID:
          </Typography>
          <CustomTextBox
            placeholder="Your tax id here"
            onChange={(e) => setTaxID(e.target.value)}
            value={taxID}
            inputProps={{
              style: {
                fontSize: "1.7rem",
                height: "2rem",
                padding: "1rem 1.5rem",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <CustomButton
            sx={{
              height: "3.85714286rem",
              width: "14.2857143rem",
              fontSize: "1.42857143rem",
              background: "none",
              color: Colors.TEXT_GRAY_DARK,
              border: `0.1114285714rem solid ${Colors.TEXT_GRAY_DARK}`,
            }}
          >
            Remove Exception
          </CustomButton>
          <CustomButton
            sx={{
              height: "3.85714286rem",
              width: "14.2857143rem",
              fontSize: "1.42857143rem",
            }}
          >
            Add Exception
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default TaxExceptionModal;
