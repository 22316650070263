import { FC } from "react";
import { PosInfoSectionMode } from "../../../typings";
import Cart from "./cart/cart";
import Insight from "./insights/insight";

export interface IInfoSectionProps {
  mode?: PosInfoSectionMode;
}

const InfoSection: FC<IInfoSectionProps> = ({
  mode = PosInfoSectionMode.CART,
}) => {
  return (
    <>
      {mode === PosInfoSectionMode.CART && <Cart />}

      {mode === PosInfoSectionMode.INSIGHT && <Insight />}
    </>
  );
};

export default InfoSection;
