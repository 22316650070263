import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks";
import axios from "axios";
import { Colors, SEARCH_CUSTOMER } from "../../../configs";
import AddIcon from "@mui/icons-material/Add";

import { logoutAction } from "../../../redux/actions/authAction";
import { CustomButton, CustomTextBox, PosLayout } from "../../../components";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import TopRightButton from "../../../components/common/top-right-button/top-right-button";
import ConfirmDialog from "../../../components/atoms/confirm-box/confirm-box";
import { useTranslation } from "../../../util/translation-provider";
import useBarcodeScanner from "../../../hooks/useBarcodeScanner";
import LoaderModal from "../../../components/atoms/loader-modal/loader-modal";

const ConfigPage: FC = () => {
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchResults, setSearchResuts] = useState([]);
  const [query, setQuery] = useState("");
  const [debouncedText, setDebouncedText] = useState(query);
  const dispatch = useAppDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const translations = useTranslation();
  const navigate = useNavigate();
  const [showBill, setShowBill] = useState(false);
  const code = useBarcodeScanner();
  const [barCode, setBarCode] = useState("");

  useEffect(() => {
    if (barCode) {
      setShowBill(true);
    }
  }, [barCode]);

  useEffect(() => {
    if (code) {
      let extractedCode = code.split("000");
      const finalCode = extractedCode.length > 0 ? extractedCode[1] : "";
      setBarCode(finalCode);
    }
  }, [code]);

  const handleOnCancelClick = () => {
    setShowBill(false);
  };

  const handleOpenConfirm = (openState: boolean) => {
    setConfirmOpen(openState);
  };

  const handleConfirm = () => {
    handleOnLogoutClick();
    handleOpenConfirm(false);
  };

  // const onClickMenuItem = (menuItem: string) => {
  //   switch (menuItem) {
  //     case "Bags to Rack":
  //       navigate("/pos/bag-rack-register");
  //   }
  // };

  // Update debouncedText after a delay
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedText(query);
    }, 800); // Adjust this delay as needed

    return () => {
      clearTimeout(timerId);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedText.length > 0) {
      searchCustomers();
    } else {
      setSearchResuts([]);
    }
  }, [debouncedText]);

  const searchCustomers = () => {
    setSearchInProgress(true);
    axios.get(SEARCH_CUSTOMER(debouncedText)).then(
      (res) => {
        res.data.body ? setSearchResuts(res.data.body) : setSearchResuts([]);
        setSearchInProgress(false);
      },
      (err) => {
        setSearchInProgress(false);
      }
    );
  };

  const onChangeQuery = (data: string) => {
    setQuery(data);
  };

  const handleOnLogoutClick = () => {
    dispatch(logoutAction());
  };

  const handleOnCreateCustomerClick = () => {
    navigate("/pos/customer/create");
  };

  return (
    <PosLayout>
      <Grid
        sx={{
          display: "flex",
          height: "100%",
        }}
      >
        <TopRightButton
          variation="Lock"
          onClick={() => handleOpenConfirm(true)}
        />
        <ConfirmDialog
          open={confirmOpen}
          title="Confirmation"
          message="Are you sure you want to proceed?"
          onCancel={() => handleOpenConfirm(false)}
          onConfirm={handleConfirm}
        />
        <LoaderModal
          onClose={handleOnCancelClick}
          open={showBill}
          billId={code || ""}
        />
        <Grid
          flex={7}
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "40px",
                lineHeight: "47px",
                color: Colors.TEXT_GRAY_DARK,
                fontWeight: "700",
              }}
            >
              {translations.companyName}
            </Typography>
            <Typography
              sx={{
                fontSize: "24px",
                lineHeight: "27px",
                color: Colors.TEXT_GRAY_DARK,
              }}
            >
              Main Lobby
            </Typography>
          </Box>
          <Box sx={{ flex: 1, position: "relative" }}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                position: "absolute",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
                borderRight: "2px solid",
                borderRightColor: Colors.ACCENT_GRAY,
              }}
            ></Box>
          </Box>
        </Grid>
        <Grid flex={5} sx={{ pl: "20px", pt: "75px" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: Colors.BACKGROUND_GRAY,
              borderRadius: "0.71rem",
            }}
          >
            <Box
              sx={{
                px: "24px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "end", pt: "24px" }}>
                <CustomButton
                  variant="contained"
                  onClick={handleOnCreateCustomerClick}
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "15px",
                  }}
                >
                  <AddIcon />
                </CustomButton>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{
                    fontSize: "32px",
                    lineHeight: "37px",
                    color: Colors.TEXT_GRAY_DARK,
                  }}
                >
                  Customer Search
                </Typography>
              </Box>
              <Box sx={{ my: "20px" }}>
                <CustomTextBox
                  styles={{
                    borderRadius: "15px",
                    height: "56px",
                  }}
                  sx={{ width: "100%" }}
                  onChange={(e) => onChangeQuery(e.target.value)}
                  placeholder="Enter Name, Phone Number, Customer ID"
                />
              </Box>
              <Box sx={{ flex: 1, position: "relative" }}>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    overflow: "auto",
                    position: "absolute",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    "-ms-overflow-style": "none",
                    "scrollbar-width": "none",
                  }}
                >
                  {searchInProgress && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PosLayout>
  );
};

export { ConfigPage };
