import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICartSession } from "../../typings/interfaces/pos/cart-session";
import { IReduxStore } from "../../typings/interfaces/reduxStore";
import { cloneDeep } from "lodash";
import { CartItem } from "../../typings/interfaces/pos/cart-item";
import { BaseResponse, BaseResponseSingle } from "../../typings";
import { PricingResponse } from "../../typings/interfaces/responses/pricing-response";
import {
  IBillItemRequest,
  IBillRequest,
} from "../../typings/interfaces/requests/new-bill-request";
import {
  ADD_PAYMENT,
  CREATE_AND_UPDATE_BILL_NOTE,
  DELETE_BILL_NOTE,
  GET_BILL_BY_ID,
  GET_BILL_NOTES_BY_ID,
  NEW_BILL,
} from "../../configs";
import { IBillResponse } from "../../typings/interfaces/responses/new-bill-response";
import axios from "axios";
import { BillNoteResponse } from "../../typings/interfaces/responses/bill-note-response";
import {
  createBillNoteRequest,
  updateBillNoteRequest,
} from "../../typings/interfaces/requests/bill-note-requests";
import { PaymentTypeEnum } from "../../typings/enum/paymentTypes";
import { PaymentRequest } from "../../typings/interfaces/requests";
import { PaymentCreateResponse } from "../../typings/interfaces/responses/paymentResponse";

export const addSession = createAsyncThunk(
  "cart/addSession",
  async (sessionData: ICartSession, { rejectWithValue, getState }) => {
    try {
      const { cart } = getState() as IReduxStore;
      const copiedCart = cloneDeep(cart.cart);
      const sessionId = copiedCart.findIndex(
        (cartSession) => cartSession.sessionId === sessionData.sessionId
      );
      if (sessionId > -1) {
        copiedCart[sessionId] = sessionData;
      } else {
        copiedCart.push(sessionData);
      }

      return copiedCart;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addCartItem = createAsyncThunk(
  "cart/addItem",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { cart } = getState() as IReduxStore;
      const copiedCartSessions = cloneDeep(cart.cart);
      const copiedCart = copiedCartSessions.findIndex(
        (cartItem) => cartItem.sessionId === cart.selectedSession?.sessionId
      );
      const itemId = copiedCartSessions[copiedCart].cartItems.findIndex(
        (cartSession) => cartSession.itemId === cart.selectedCartItem?.itemId
      );

      copiedCartSessions[copiedCart].cartItems[itemId].finalized = true;
      const cartSession = copiedCartSessions[copiedCart];

      return { copiedCartSessions, cartSession };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const removeSession = createAsyncThunk(
  "cart/removeSession",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { cart } = getState() as IReduxStore;
      if (cart.cart.length === 1) {
        return cart.cart;
      }
      const filteredSession = cart.cart.filter(
        (session) => session.sessionId !== cart.selectedSession?.sessionId
      );
      return filteredSession;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCartSessionItem = createAsyncThunk(
  "cart/updateCartSessionItem",
  async (
    { cartItem }: { cartItem: CartItem },
    { rejectWithValue, getState }
  ) => {
    try {
      const { cart, pricing } = getState() as IReduxStore;
      const clonedCart = cloneDeep(cart.cart);
      const selectedSessionIndex = clonedCart.findIndex(
        (session) => session.sessionId === cart.selectedSession?.sessionId
      );

      // Check whether if item in cart selected or not
      const updateItem = () => {
        const subCategoryId = cartItem.garmentType?.id || 0;
        const secondSubCategory = cartItem.garmentStyle?.id || 0;
        const fabricType = cartItem.materialType?.id || 0;
        const category = cart.selectedServiceType?.id;

        const dcPrice = pricing.dryCleanResponse?.body.filter(
          (item: PricingResponse) => {
            return (
              item.categoryId ===
                (category === 100000 ? cartItem.serviceType?.id : category) &&
              item.subCategoryId === subCategoryId &&
              item.secondSubCategoryId === secondSubCategory &&
              item.materialId === fabricType
            );
          }
        );

        const price = (dcPrice && dcPrice[0]?.price) || 0;

        cartItem.unitPrice = price;

        const sessionItems =
          clonedCart[selectedSessionIndex].cartItems.map((crtItem) => {
            return crtItem.itemId === cart.selectedCartItem?.itemId
              ? cartItem
              : crtItem;
          }) || [];
        clonedCart[selectedSessionIndex].cartItems = sessionItems;
      };

      if (cart.selectedCartItem && cart.selectedCartItem.finalized === false) {
        updateItem();
      } else {
        const isItemAlreadyExists = clonedCart[
          selectedSessionIndex
        ].cartItems.find((item: any) => item.itemId === cartItem.itemId);

        if (isItemAlreadyExists === undefined) {
          clonedCart[selectedSessionIndex].cartItems = [
            ...clonedCart[selectedSessionIndex].cartItems,
            cartItem,
          ];
        } else {
          updateItem();
        }
      }

      const session = clonedCart[selectedSessionIndex];

      return { clonedCart, cartItem, session };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const removeCartItem = createAsyncThunk(
  "cart/removeCartItem",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { cart } = getState() as IReduxStore;
      const clonedCart = cloneDeep(cart.cart);
      const sessionIndex = clonedCart.findIndex(
        (session) => session.sessionId === cart.selectedSession?.sessionId
      );
      const filteredItems = clonedCart[sessionIndex]?.cartItems.filter(
        (item) => item.itemId !== cart.selectedCartItem?.itemId
      );
      clonedCart[sessionIndex].cartItems = filteredItems || [];
      const session = clonedCart[sessionIndex];
      return { clonedCart, session };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const attachAlteration = createAsyncThunk(
  "cart/attachAlteration",
  async (
    { cartItem }: { cartItem: CartItem },
    { rejectWithValue, getState }
  ) => {
    try {
      const { cart } = getState() as IReduxStore;
      const clonedCart = cloneDeep(cart.cart);
      const selectedSessionIndex = clonedCart.findIndex(
        (session) => session.sessionId === cart.selectedSession?.sessionId
      );
      // Check wether if item in cart selected or not
      const clonedCartItem = cloneDeep(cartItem);
      if (clonedCartItem) {
        clonedCartItem.alterationSecondSubCategory =
          cart.alterationMergeItem?.alterationSecondSubCategory;
        clonedCartItem.alterationSubCategory =
          cart.alterationMergeItem?.alterationSubCategory;
        const sessionItems = (
          clonedCart[selectedSessionIndex].cartItems.map((crtItem) => {
            return crtItem.itemId === cartItem?.itemId
              ? clonedCartItem
              : crtItem;
          }) || []
        ).filter((item) => item.itemId !== cart.alterationMergeItem?.itemId);
        clonedCart[selectedSessionIndex].cartItems = sessionItems;
      }

      const session = clonedCart[selectedSessionIndex];

      return { clonedCart, clonedCartItem, session };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const newBillAction = createAsyncThunk(
  "cart/newBill",
  async (
    { tenant, paymentType }: { tenant: string; paymentType: PaymentTypeEnum },
    { rejectWithValue, getState }
  ) => {
    try {
      const { cart, auth, customer } = getState() as IReduxStore;
      const bill: IBillRequest = {
        billItemList: [],
        serviceTypeId: "1",
        merchantId: tenant,
        cashierId: `${auth.user?.id}`,
        customerId: customer.customer?.id || 0,
        paymentType: paymentType,
      };

      const billItemList: IBillItemRequest[] = [];
      cart.selectedSession?.cartItems.forEach((item) => {
        const billItem: IBillItemRequest = {
          firstCategoryId: `${item.garmentType?.id}`,
          secondCategoryId: `${item.materialType}`,
          secondSubCategoryId: `${item.garmentStyle?.id}`,
          itemPrice: item.unitPrice,
          itemQty: item.qty,
          total: item.unitPrice * item.qty,
          color: item.garmentColor as string[],
          textureType: item.texture as string,
          instructions: item.instructions,
          alterationFirstCategoryId: `${item.garmentType?.id}`,
          alterationSecondCategoryId: `${item.alterationSubCategory?.id}`,
          alterationSecondSubCategoryId: `${item.alterationSecondSubCategory?.id}`,
        };
        billItemList.push(billItem);
      });
      bill.billItemList = billItemList;

      const response = await axios.post<BaseResponseSingle<IBillResponse>>(
        NEW_BILL,
        bill
      );

      return response.data.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBillByIdAction = createAsyncThunk(
  "cart/getBillById",
  async ({ billId }: { billId: string }, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponseSingle<IBillResponse>>(
        GET_BILL_BY_ID(billId)
      );
      return response.data.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
/* 
export const addUserNoteAction = createAsyncThunk(
  "customer/create-note",
  async (
    { note, occasions, merchantUserId, customerId }: CreateUserNoteRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<BaseResponse<CreateUserNoteRequest>>(
        CREATE_AND_UPDATE_USER_NOTE,
        {
          note,
          occasions,
          merchantUserId,
          customerId,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

*/
export const addBillNoteAction = createAsyncThunk(
  "cart/addBillNote",
  async (
    {
      note,
      occasions,
      merchantUserId,
      customerId,
      billId,
    }: createBillNoteRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<BaseResponse<createBillNoteRequest>>(
        CREATE_AND_UPDATE_BILL_NOTE,
        {
          note,
          occasions,
          merchantUserId,
          customerId,
          billId,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBillNoteByIdAction = createAsyncThunk(
  "cart/getBillNoteById",
  async ({ billId }: { billId: string }, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<BillNoteResponse>>(
        GET_BILL_NOTES_BY_ID(billId)
      );
      let sorted = response.data.body?.sort(
        (a: any, b: any) =>
          new Date(b.createdTime).getTime() - new Date(a.createdTime).getTime()
      );
      return sorted;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateBillNoteAction = createAsyncThunk(
  "cart/updateBillNote",
  async (
    {
      id,
      note,
      occasions,
      merchantUserId,
      customerId,
      billId,
    }: updateBillNoteRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put<BaseResponse<updateBillNoteRequest>>(
        CREATE_AND_UPDATE_BILL_NOTE,
        {
          id,
          note,
          occasions,
          merchantUserId,
          customerId,
          billId,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteBillNoteAction = createAsyncThunk(
  "cart/deleteBillNote",
  async (noteId: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete<BaseResponse<null>>(
        DELETE_BILL_NOTE(noteId)
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const newPaymentAction = createAsyncThunk(
  "cart/newPayment",
  async (newPayment: PaymentRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<BaseResponse<PaymentCreateResponse>>(
        ADD_PAYMENT,
        newPayment
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
