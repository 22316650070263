import { createSlice } from "@reduxjs/toolkit";
import {
  BaseResponse,
  BaseResponseSingle,
  IDiscountResponse,
} from "../../typings";
import {
  createDiscount,
  deleteDiscount,
  getDiscountsByStoreId,
} from "../actions/discountAction";

export interface DiscountState {
  createDiscountResponse: BaseResponseSingle<IDiscountResponse> | null;
  createDiscountLoading: boolean;
  createDiscountError: string | null;

  getDiscountByStoreResponse: BaseResponse<IDiscountResponse> | null;
  getDiscountByStoreLoading: boolean;
  getDiscountByStoreError: string | null;

  deleteDiscountResponse: BaseResponse<null> | null;
  deleteDiscountLoading: boolean;
  deleteDiscountError: string | null;

  selectedDiscount: string | null;

  eligibleDiscounts: IDiscountResponse | null;
}

const initialState: DiscountState = {
  createDiscountResponse: null,
  createDiscountLoading: false,
  createDiscountError: null,

  getDiscountByStoreResponse: null,
  getDiscountByStoreLoading: false,
  getDiscountByStoreError: null,

  deleteDiscountResponse: null,
  deleteDiscountLoading: false,
  deleteDiscountError: null,

  selectedDiscount: null,
  eligibleDiscounts: null
};

const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    clearCreateDiscount: (state: DiscountState) => {
      state.createDiscountResponse = null;
      state.createDiscountError = null;
      state.createDiscountLoading = false;
    },
    clearGetDiscountByStore: (state: DiscountState) => {
      state.getDiscountByStoreResponse = null;
      state.getDiscountByStoreError = null;
      state.getDiscountByStoreLoading = false;
    },
    clearDeleteDiscount: (state: DiscountState) => {
      state.deleteDiscountResponse = null;
      state.deleteDiscountError = null;
      state.deleteDiscountLoading = false;
    },
    setSelectedDiscount: (state: DiscountState, action) => {
      state.selectedDiscount = action.payload;
    },
    setEligibleDiscount: (state: DiscountState, action) => {
      state.eligibleDiscounts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createDiscount.rejected, (state: DiscountState) => {
      state.createDiscountResponse = null;
      state.createDiscountError = "Error creating discount";
      state.createDiscountLoading = false;
    });
    builder.addCase(createDiscount.pending, (state: DiscountState) => {
      state.createDiscountResponse = null;
      state.createDiscountError = null;
      state.createDiscountLoading = true;
    });
    builder.addCase(
      createDiscount.fulfilled,
      (state: DiscountState, action) => {
        state.createDiscountResponse = action.payload;
        state.createDiscountError = null;
        state.createDiscountLoading = false;
      }
    );

    builder.addCase(getDiscountsByStoreId.rejected, (state: DiscountState) => {
      state.getDiscountByStoreResponse = null;
      state.getDiscountByStoreError = "Error creating discount";
      state.getDiscountByStoreLoading = false;
    });
    builder.addCase(getDiscountsByStoreId.pending, (state: DiscountState) => {
      state.getDiscountByStoreResponse = null;
      state.getDiscountByStoreError = null;
      state.getDiscountByStoreLoading = true;
    });
    builder.addCase(
      getDiscountsByStoreId.fulfilled,
      (state: DiscountState, action) => {
        state.getDiscountByStoreResponse = action.payload;
        state.getDiscountByStoreError = null;
        state.getDiscountByStoreLoading = false;
      }
    );

    builder.addCase(deleteDiscount.rejected, (state: DiscountState) => {
      state.deleteDiscountResponse = null;
      state.deleteDiscountError = "Error creating discount";
      state.deleteDiscountLoading = false;
    });
    builder.addCase(deleteDiscount.pending, (state: DiscountState) => {
      state.deleteDiscountResponse = null;
      state.deleteDiscountError = null;
      state.deleteDiscountLoading = true;
    });
    builder.addCase(
      deleteDiscount.fulfilled,
      (state: DiscountState, action) => {
        state.deleteDiscountResponse = action.payload;
        state.deleteDiscountError = null;
        state.deleteDiscountLoading = false;
      }
    );
  },
});

export default discountSlice.reducer;
export const DiscountSliceAction = discountSlice.actions;
