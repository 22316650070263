import { FC, useEffect, useState } from "react";
import { Colors } from "../../../configs";
import { Button } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "2.99214286rem", // 42
  height: "1.64285714rem", //26
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "0.163214283rem",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(1.3696428605rem)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          /*theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466'*/ Colors.WHITE,
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "& + .MuiSwitch-track": {
      border: `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-checked .MuiSwitch-thumb": {
      color: Colors.PRIMARY,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "1.17357143rem",
    height: "1.17357143rem",
  },
  "& .MuiSwitch-track": {
    borderRadius: "0.82142857rem",
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default IOSSwitch;
