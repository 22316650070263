const enum GarmentColor{
    RED= "RED",
    YELLOW= "YELLOW",
    BROWN= "BROWN",
    GREEN= "GREEN",
    BLUE= "BLUE",
    PURPLE= "PURPLE",
    BLACK= "BLACK",
    WHITE= "WHITE",
    GRAY= "GRAY",
    PINK= "PINK",
    BURGUNDY= "BURGUNDY",
    ORANGE= "ORANGE",
    PEACH= "PEACH",
    TAN= "TAN",
    SILVER= "SILVER",
    CREAM= "CREAM",
    L_BLUE= "L_BLUE",
    L_GREEN= "L_GREEN",
    NAVY= "NAVY",
    TEAL= "TEAL",
    BEIGE= "BEIGE",
    GOLD= "GOLD",
    LAVENDER= "LAVENDER",
    OLIVE= "OLIVE",
}

export default GarmentColor;