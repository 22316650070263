import { FC, useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";

import { Colors } from "../../../../configs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { sortArray } from "../../../../util/array-functions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { CustomButton } from "../../../atoms";
import { cloneDeep } from "lodash";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { IInstruction } from "../../../../typings/interfaces/pos/cart-item";
import CustomModal from "../../custom-modal/custom-modal";
import InstructionPromptModal from "../instruction-prompt-modal/instruction-prompt-modal";

// const instructions: {
//   id: number;
//   label: string;
// }[] = [
//   {
//     id: 1,
//     label: "Dry Clean Only",
//     checked: false,
//   },
//   {
//     id: 2,
//     label: "Press Only",
//     checked: false,
//   },
//   {
//     id: 3,
//     label: "Alteration Only",
//     checked: false,
//   },
//   {
//     id: 4,
//     label: "Do Not Clean",
//     checked: false,
//   },
//   {
//     id: 5,
//     label: "Clean at Customers Risk",
//     checked: false,
//   },
//   {
//     id: 6,
//     label: "Soak",
//     checked: false,
//   },
//   {
//     id: 7,
//     label: "See Sabrina",
//     checked: false,
//   },
//   {
//     id: 8,
//     label: "See Buddy",
//     checked: false,
//   },
//   {
//     id: 9,
//     label: "See Jannete",
//     checked: false,
//   },
//   {
//     id: 10,
//     label: "Inspect Before Bagging",
//     checked: false,
//   },
//   {
//     id: 11,
//     label: "Soft Press",
//     checked: false,
//   },
//   {
//     id: 12,
//     label: "Hand Press",
//     checked: false,
//   },
//   {
//     id: 13,
//     label: "Steam Only",
//     checked: false,
//   },
//   {
//     id: 14,
//     label: "Military crease",
//     checked: false,
//   },
//   {
//     id: 15,
//     label: "Fold on Hanger",
//     checked: false,
//   },
//   {
//     id: 16,
//     label: "Fold in a Household Bag",
//     checked: false,
//   },
//   {
//     id: 17,
//     label: "Fold in a Paper bag",
//     checked: false,
//   },
//   {
//     id: 18,
//     label: "Cape Hanger",
//     checked: false,
//   },
//   {
//     id: 19,
//     label: "Aware of Shrinkage",
//     checked: false,
//   },
//   {
//     id: 20,
//     label: "Aware of color bleed/loss",
//     checked: false,
//   },
//   {
//     id: 21,
//     label: "Call customer for price",
//     checked: false,
//   },
//   {
//     id: 22,
//     label: "Remove & Sew buttons",
//     checked: false,
//   },
//   {
//     id: 23,
//     label: "Check Buttons",
//     checked: false,
//   },
//   {
//     id: 24,
//     label: "Scrub Collar & Cuffs",
//     checked: false,
//   },
//   {
//     id: 25,
//     label: "Replace Buttons",
//     checked: false,
//   },
//   {
//     id: 26,
//     label: "Center Crease",
//     checked: false,
//   },
//   {
//     id: 27,
//     label: "No Crease",
//     checked: false,
//   },
//   {
//     id: 28,
//     label: "Crease Sleeves",
//     checked: false,
//   },
//   {
//     id: 29,
//     label: "Do Not Fold - Hang",
//     checked: false,
//   },
//   {
//     id: 30,
//     label: "Wet Clean",
//     checked: false,
//   },
//   {
//     id: 31,
//     label: "Ticket will have add charges",
//     checked: false,
//   },
//   {
//     id: 32,
//     label: "Customer measurements",
//     checked: false,
//   },
//   {
//     id: 33,
//     label: "Has been cleaned before",
//     checked: false,
//   },
//   {
//     id: 34,
//     label: "Put clips on collars",
//     checked: false,
//   },
//   {
//     id: 35,
//     label: "Hang to dry",
//     checked: false,
//   },
//   {
//     id: 36,
//     label: "Roll lapels-2nd button",
//     checked: false,
//   },
//   {
//     id: 100000,
//     label: "Custom",
//     checked: false,
//   },
// ];
const instructions: IInstruction[] = [
  {
    id: 1,
    label: "Dry Clean Only",
  },
  {
    id: 2,
    label: "Press Only",
  },
  {
    id: 3,
    label: "Alteration Only",
  },
  {
    id: 4,
    label: "Do Not Clean",
  },
  {
    id: 5,
    label: "Clean at Customers Risk",
  },
  {
    id: 6,
    label: "Soak",
  },
  {
    id: 7,
    label: "See Sabrina",
  },
  {
    id: 8,
    label: "See Buddy",
  },
  {
    id: 9,
    label: "See Jannete",
  },
  {
    id: 10,
    label: "Inspect Before Bagging",
  },
  {
    id: 11,
    label: "Soft Press",
  },
  {
    id: 12,
    label: "Hand Press",
  },
  {
    id: 13,
    label: "Steam Only",
  },
  {
    id: 14,
    label: "Military crease",
  },
  {
    id: 15,
    label: "Fold on Hanger",
  },
  {
    id: 16,
    label: "Fold in a Household Bag",
  },
  {
    id: 17,
    label: "Fold in a Paper bag",
  },
  {
    id: 18,
    label: "Cape Hanger",
  },
  {
    id: 19,
    label: "Aware of Shrinkage",
  },
  {
    id: 20,
    label: "Aware of color bleed/loss",
  },
  {
    id: 21,
    label: "Call customer for price",
  },
  {
    id: 22,
    label: "Remove & Sew buttons",
  },
  {
    id: 23,
    label: "Check Buttons",
  },
  {
    id: 24,
    label: "Scrub Collar & Cuffs",
  },
  {
    id: 25,
    label: "Replace Buttons",
  },
  {
    id: 26,
    label: "Center Crease",
  },
  {
    id: 27,
    label: "No Crease",
  },
  {
    id: 28,
    label: "Crease Sleeves",
  },
  {
    id: 29,
    label: "Do Not Fold - Hang",
  },
  {
    id: 30,
    label: "Wet Clean",
  },
  {
    id: 31,
    label: "Ticket will have add charges",
  },
  {
    id: 32,
    label: "Customer measurements",
  },
  {
    id: 33,
    label: "Has been cleaned before",
  },
  {
    id: 34,
    label: "Put clips on collars",
  },
  {
    id: 35,
    label: "Hang to dry",
  },
  {
    id: 36,
    label: "Roll lapels-2nd button",
  },
  {
    id: 100000,
    label: "Custom",
  },
];

const instructionsSorted = sortArray({
  array: instructions,
  sortByKey: "label",
  fixedFirstItem: "Custom",
});

export interface IInstructionSectionProps {}

const InstructionSection: FC<IInstructionSectionProps> = () => {
  const dispatch = useAppDispatch();
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const [openPrompt, setOpenPrompt] = useState<boolean>(false);

  const handleOnClosePrompt = () => {
    setOpenPrompt(false);
  };

  const handleUpdateCartInstructions = (instructions: IInstruction[]) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      cartItem.instructions = instructions;
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };

  const isAddedInstruction = (instruction: IInstruction) => {
    return selectedCartItem?.instructions?.find(
      (item) => item.id === instruction.id
    )?.id
      ? true
      : false;
  };

  const onSelectItem = (instruction: IInstruction) => {
    if (instruction.id === 100000) {
      setOpenPrompt(true);
    } else {
      if (isAddedInstruction(instruction)) {
        handleUpdateCartInstructions(
          selectedCartItem?.instructions.filter(
            (item) => instruction.id !== item.id
          ) ?? []
        );
      } else {
        handleUpdateCartInstructions([
          ...(selectedCartItem?.instructions || []),
          instruction,
        ]);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.71428571rem",
        background: Colors.BACKGROUND_GRAY,
        // background: "red",
        borderRadius: "0.71rem",
        padding: "0.928571429rem",
      }}
    >
      <InstructionPromptModal
        promptOpen={openPrompt}
        onClose={handleOnClosePrompt}
        onSubmit={handleUpdateCartInstructions}
      />
      <Typography
        sx={{ fontSize: "1.71428571rem", color: Colors.TEXT_GRAY_DARK }}
      >
        Instructions
      </Typography>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "1.07142857rem",
          rowGap: "0.857142857rem",
          justifyContent: "flex-start",
          alignContent: "flex-start",
          overflowY: "auto",
          height: "46.7142857rem",
        }}
      >
        {instructions.map((instruction, i) => {
          return (
            <CustomButton
              sx={{
                width: "17.0064286rem",
                background:
                  instruction.checked ||
                  selectedCartItem?.instruction.includes(instruction.label)
                    ? Colors.SECONDARY
                    : Colors.MAIN_GRAY,
                borderRadius: "0.71rem",
                textTransform: "capitalize",
                color: Colors.WHITE,
                fontSize: "1.14rem",
                lineHeight: "1.33928571rem",
                padding: "1.07142857rem 0.857142857rem",
                "&&:hover": {
                  background: Colors.TEXT_GRAY_DARK,
                },
                height: "4.28571429rem",
              }}
              key={i}
              onClick={() => onSelectItem(instruction)}
            >
              {instruction.label}
            </CustomButton>
          );
        })}
      </Box> */}
      <Swiper
        style={{ width: "72rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(instructionsSorted.length / 36))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  width: "72rem",
                  // height: "10.6428571rem",
                  // background: "red",
                  gap: "1.07142857rem",
                  rowGap: "0.857142857rem",
                  alignContent: "flex-start",
                  height: "46.7142857rem",
                }}
              >
                {instructionsSorted
                  .slice(swipePageIndex * 36, (swipePageIndex + 1) * 36)
                  .map((instruction, i) => (
                    <CustomButton
                      sx={{
                        width: "17.0064286rem",
                        background: isAddedInstruction(
                          instruction as IInstruction
                        )
                          ? Colors.SECONDARY
                          : Colors.MAIN_GRAY,
                        borderRadius: "0.71rem",
                        textTransform: "capitalize",
                        color: Colors.WHITE,
                        fontSize: "1.14rem",
                        lineHeight: "1.33928571rem",
                        padding: "1.07142857rem 0.857142857rem",
                        "&&:hover": {
                          background: Colors.TEXT_GRAY_DARK,
                        },
                        height: "4.28571429rem",
                      }}
                      key={i}
                      onClick={() => onSelectItem(instruction as any)}
                    >
                      {(instruction as any).label}
                    </CustomButton>
                  ))}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default InstructionSection;
