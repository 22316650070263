import { FC } from "react";
import { Box, Typography } from "@mui/material";

import ServiceTabMenu from "../../../common/pos/service-tab-menu/service-tab-menu";
import { useAppSelector } from "../../../../hooks";
import DryClean from "./dry-clean/dry-clean";
import Alteration from "./alteration/alteration";
import Laundry from "./laundry/laundry";
import Costume from "./costume/costume";
import Household from "./household/household";
import Leather from "./leather/leather";

export interface IServicesProps {}

const ServiceComponent: FC<IServicesProps> = () => {
  const { selectedServiceType } = useAppSelector((state) => state.cart);

  return (
    <>
      <Box
        sx={{
          flex: 5,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography sx={{ fontSize: "2.57rem", color: "#4D4D4D" }}>
          Add Items
        </Typography>

        <ServiceTabMenu currentTab="Dry Clean" />

        {/* Dry Clean Tab */}
        {selectedServiceType?.categoryName === "Dry Clean" && <DryClean />}

        {/* Launtry Tab */}
        {selectedServiceType?.categoryName === "Laundry" && <Laundry />}

        {/* Costume */}
        {selectedServiceType?.categoryName === "Costumes" && <Costume />}

        {/* Household */}
        {selectedServiceType?.categoryName === "Household" && <Household />}

        {/* Leather */}
        {selectedServiceType?.categoryName === "Leather" && <Leather />}

        {/* Alteration Tab */}
        {selectedServiceType?.categoryName === "Alteration" && <Alteration />}
      </Box>
    </>
  );
};

export default ServiceComponent;
