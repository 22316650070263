export const CashIcon = ({ color = "#515151" }) => {
  return (
    <svg
      width="43"
      height="30"
      viewBox="0 0 43 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.7336 1.50402C39.1275 0.414509 36.5193 8.05418e-08 33.9125 8.05418e-08C25.6371 -0.000669562 17.3624 4.17455 9.08706 4.17455C7.01167 4.17455 4.93762 3.91205 2.86223 3.2558C2.6291 3.18214 2.39529 3.14732 2.16685 3.14732C1.01058 3.14732 9.15527e-05 4.03929 9.15527e-05 5.27745V26.5225C9.15527e-05 27.3683 0.48585 28.1699 1.26588 28.4953C3.87205 29.5855 6.48023 30 9.08706 30C17.3624 30 25.6378 25.8248 33.9131 25.8248C35.9885 25.8248 38.0626 26.0873 40.1379 26.7435C40.3711 26.8172 40.6049 26.852 40.8333 26.852C41.9896 26.852 43.0001 25.96 43.0001 24.7219V3.47746C42.9994 2.63103 42.5137 1.83013 41.7336 1.50402ZM3.22504 6.71116C4.57683 7.04866 5.98775 7.21808 7.43898 7.30915C7.04392 9.27723 5.30782 10.7605 3.22504 10.7605V6.71116ZM3.22504 25.796V22.5964C5.53424 22.5964 7.40269 24.4225 7.50549 26.708C5.99178 26.5868 4.57683 26.2855 3.22504 25.796ZM21.4998 21.4286C18.5308 21.4286 16.1248 18.5498 16.1248 15C16.1248 11.4496 18.5315 8.57143 21.4998 8.57143C24.4681 8.57143 26.8747 11.4496 26.8747 15C26.8747 18.5511 24.4674 21.4286 21.4998 21.4286ZM39.7745 23.2888C38.5974 22.9949 37.3752 22.8301 36.1249 22.7237C36.5193 20.9772 37.9725 19.6513 39.7745 19.4237V23.2888ZM39.7745 7.4779C37.6991 7.21607 36.0886 5.48906 36.0248 3.35625C37.3376 3.50156 38.5839 3.77344 39.7745 4.20402V7.4779Z"
        fill={color}
      />
    </svg>
  );
};
