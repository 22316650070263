const enum GarmentTexture {
    STRIPED="STRIPED",
    PLAID="PLAID",
    PLAIN="PLAIN",
    DOTS="DOTS",
    PRINT="PRINT",
    PAISLEY="PAISLEY",
    MIXED="MIXED",
    CHECK="CHECK",
    FLOWERS="FLOWERS",
    GLITTER="GLITTER",
    CAMO="CAMO",
    TWEED="TWEED"
}

export default GarmentTexture;
