import React, { FC } from "react";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { CartItem } from "../../../typings/interfaces/pos/cart-item";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomButton } from "../../atoms";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { Colors } from "../../../configs";
import { formatCurrency } from "../../../util/common-functions";
import { differenceInMinutes } from "date-fns";
import { ICartSession } from "../../../typings/interfaces/pos/cart-session";
import { Link } from "react-router-dom";
import { CartSliceAction } from "../../../redux/slice/cartSlice";
import { CustomerData } from "../../../interfaces";

interface IDraftDrawerProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  forCustomer?: number;
}
interface IDraftCardProps {
  createdAt: Date;
  sessionCount: number;
  totalPieces: number;
  totalAmount: number;
  customer: CustomerData;
}

const DraftCard: FC<IDraftCardProps> = ({
  createdAt,
  sessionCount,
  totalPieces,
  totalAmount,
  customer,
}) => {
  const dispatch = useAppDispatch();
  const handleOnItemClick = () => {
    localStorage.removeItem(customer.id.toString());
    dispatch(CartSliceAction.setSelectedCustomer(customer));
  };
  return (
    <Link
      to={`/pos/${customer.id}`}
      onClick={handleOnItemClick}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          background: Colors.WHITE,
          borderRadius: "0.6rem",
          padding: "1rem",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {customer.firstName} {customer.lastName}
            </Typography>
            <Typography sx={{ color: Colors.SECONDARY }}>
              {differenceInMinutes(new Date(), createdAt)}mins ago
            </Typography>
          </Box>
          <Typography>{customer.mobilePhone}</Typography>
          <Typography>{sessionCount} Sessions</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Pieces: {totalPieces}</Typography>
          <Typography
            variant="h6"
            sx={{
              display: "none",
            }}
          >
            Total: {formatCurrency({ amount: totalAmount, prefix: "$" })}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

const DraftDrawer: FC<IDraftDrawerProps> = ({
  isOpen,
  toggleDrawer,
  forCustomer,
}) => {
  const { drafts } = useAppSelector((state) => state.cart);

  const filteredDrafts = forCustomer
    ? drafts.filter((draft) => draft.customer.id === forCustomer)
    : drafts;

  return (
    <CustomDrawer
      isOpen={isOpen}
      toggleDrawer={toggleDrawer}
      sx={{
        background: Colors.BACKGROUND_GRAY,
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <IconButton sx={{ alignSelf: "flex-end" }} onClick={toggleDrawer}>
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          {filteredDrafts.map((draft, i) => (
            <DraftCard
              key={i}
              customer={draft.customer}
              createdAt={draft.createdAt}
              sessionCount={draft.draftSessions.length}
              totalPieces={draft.totalPieces}
              totalAmount={draft.totalAmount}
            />
          ))}
          {filteredDrafts?.length === 0 && (
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              No drafts found
            </Typography>
          )}
        </Box>
      </Box>
    </CustomDrawer>
  );
};

export default DraftDrawer;
