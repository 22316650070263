const CrossIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <rect y="0.083252" width="14.32" height="14.9167" rx="3" fill="#930000" />
      <path
        d="M10.7765 9.413L8.60669 7.24319L10.7765 5.07338C11.0428 4.80713 11.0428 4.37528 10.7765 4.10902L10.2943 3.62684C10.0281 3.36059 9.59622 3.36059 9.32996 3.62684L7.16016 5.79665L4.99035 3.62684C4.7241 3.36059 4.29224 3.36059 4.02599 3.62684L3.54381 4.10902C3.27756 4.37528 3.27756 4.80713 3.54381 5.07338L5.71362 7.24319L3.54381 9.413C3.27756 9.67925 3.27756 10.1111 3.54381 10.3774L4.02599 10.8595C4.29224 11.1258 4.7241 11.1258 4.99035 10.8595L7.16016 8.68973L9.32996 10.8595C9.59622 11.1258 10.0281 11.1258 10.2943 10.8595L10.7765 10.3774C11.0428 10.1111 11.0428 9.67925 10.7765 9.413Z"
        fill="white"
      />
    </svg>
  );
};

export default CrossIcon;
