import { FC } from "react";
import { Box, Button } from "@mui/material";

import { Colors } from "../../../../configs";
import { CustomButton } from "../../../atoms";
import {
  CustomizationTypes,
  customizationTypesLabels,
} from "../../../../typings/enum/customizationTypes";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";

export interface ICustomizationsTabMenuProps {}

const CustomizationsTabMenu: FC<ICustomizationsTabMenuProps> = () => {
  const dispatch = useAppDispatch();
  const { selectedCustomizationType } = useAppSelector((state) => state.cart);

  const changeCustomizationType = (type: CustomizationTypes) => {
    dispatch(CartSliceAction.setSelectedCustomizationType(type));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "0.57rem" }}>
      {Object.values(CustomizationTypes).map((type) => (
        <CustomButton
          sx={{
            background:
              selectedCustomizationType === type
                ? Colors.SECONDARY
                : Colors.PRIMARY,
            color: Colors.WHITE,
            borderRadius: "0.71rem",
            textTransform: "capitalize",
            fontSize: "1.14rem",
            lineHeight: "18.75px",
            padding: "15px",
            width: "9.85rem",
            "&&:hover": {
              backgroundColor: Colors.SECONDARY,
            },
          }}
          key={type}
          onClick={() => changeCustomizationType(type)}
        >
          {customizationTypesLabels[type]}
        </CustomButton>
      ))}
    </Box>
  );
};

export default CustomizationsTabMenu;
