import { Box } from "@mui/material";
import { BoxProps } from "@mui/material/Box";
import { FC } from "react";

export interface IPanelProps extends BoxProps {
    children?: React.ReactNode
}

export const Panel: FC<IPanelProps> = (props) => {
    const { children } = props;
    return <Box
        {...props}
        sx={{
            backgroundColor: "custom.main",
            width: "100%",
            height: "100%",
            borderRadius: "0.71rem",
            ...props.sx
        }}>
        {children}
    </Box>
}