import { Box } from "@mui/material";
import { FC } from "react";
import Marquee from "react-fast-marquee";
import { Colors } from "../../../../configs";

export interface IMarqueeProps {
  text: string;
  maxWidth?: string;
}

const CustomMarquee: FC<IMarqueeProps> = ({ text, maxWidth = "57.79rem" }) => {
  return (
    <Box
      sx={{
        height: "2rem",
        width: "100%",
        background: Colors.ACCENT_GRAY,
        borderRadius: "0.71rem",
        overflow: "hidden",
        whiteSpace: "nowrap",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <span
        style={{
          animation: `marquee ${duration}s linear infinite`,
          position: "absolute",
        }}
      >
        {text}
      </span> */}
      <Marquee
        gradient={true}
        gradientColor={Colors.ACCENT_GRAY}
        style={{
          width: maxWidth,
          maxWidth: maxWidth,
          minWidth: maxWidth,
          // background: "red !important",
        }}
      >
        {text}
      </Marquee>
    </Box>
  );
};

export default CustomMarquee;
