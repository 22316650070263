export enum NoteOccasion {
  ON_CHECKIN = "ON_CHECKIN",
  ONVIEW_EDIT = "ONVIEW_EDIT",
  ON_CHECKOUT = "ON_CHECKOUT",
  ON_ADD_STORE_CREDIT = "ON_ADD_STORE_CREDIT",
}

export const NoteOccasionLabels = {
  [NoteOccasion.ON_CHECKIN]: "On Checkin",
  [NoteOccasion.ONVIEW_EDIT]: "On View Profile",
  [NoteOccasion.ON_CHECKOUT]: "On Checkout",
  [NoteOccasion.ON_ADD_STORE_CREDIT]: "On Add Store Credit",
};
