import { Grid } from "@mui/material"
import { FC } from "react"
import { HeaderTitle } from "../../atoms/header-title/header-title";
import TopRightButton from "../../common/top-right-button/top-right-button";

export interface IPosInnerPageLayoutProps {
    children: React.ReactNode;
    onClickBack?():void;
    title: string;
}

export const PosInnerPageLayout:FC<IPosInnerPageLayoutProps>=({children, onClickBack, title})=>{
    return <Grid sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    }}>
        {onClickBack && <TopRightButton variation="Back" onClick={onClickBack}/>}
        <HeaderTitle variation="page_header" title={title}/>
        {children}
    </Grid>
}