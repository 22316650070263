import { FC, useState } from "react";
import { Grid, Box, Typography, Checkbox } from "@mui/material";

import { Colors } from "../../../../configs";
import { CustomerBillsResponse } from "../../../../typings";
import { format } from "date-fns";
import { formatCurrency } from "../../../../util/common-functions";

export interface ICustomerHistoryTableRowProps {
  data: CustomerBillsResponse;
  onClick(data: CustomerBillsResponse): void;
  firstRow: boolean;
  lastRow: boolean;
  isSelected: boolean;
}

const CustomerHistoryTableRow: FC<ICustomerHistoryTableRowProps> = ({
  data,
  firstRow,
  lastRow,
  onClick,
  isSelected,
}) => {
  const [selectedRow, setSelectedRow] = useState(false);
  let styles = {
    tableBodyCell: {
      background: isSelected
        ? Colors.BROWN
        : selectedRow
        ? Colors.LIGHT_YELLOW
        : Colors.ACCENT_GRAY,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "1.14rem 0.28rem",
      paddingBottom: "0rem",
      boxSizing: "border-box",
      gap: "1.07rem",
      color: isSelected ? "#ffffff" : "#000000",
    },
    descriptionCell: {
      alignItems: "center",
    },
    firstRowCell: {
      borderTopLeftRadius: "0.71rem",
      borderTopRightRadius: "0.71rem",
    },
    lastRowCell: {
      borderBottomRightRadius: "0.71rem",
      borderBottomLeftRadius: "0.71rem",
      //paddingBottom: "1.07rem",
    },
    tableRow: {},
  };

  const handleOnClickRow = () => {
    onClick(data);
  };

  return (
    <Box
      sx={{ display: "flex", gap: "0.35rem", flexWrap: "nowrap" }}
      onClick={handleOnClickRow}
    >
      <Box
        sx={{
          ...styles.tableBodyCell,
          ...(firstRow ? styles.firstRowCell : {}),
          ...(lastRow ? styles.lastRowCell : {}),
          flex: 1,
          minWidth: "3.246rem",
          maxWidth: "3.246rem !important",
          width: "3.246rem !important",
          boxSizing: "border-box !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: lastRow
              ? "none"
              : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            //background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Checkbox
            onChange={(e) => setSelectedRow(e.target.checked)}
            value={selectedRow}
          />
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          ...styles.descriptionCell,
          ...(firstRow ? styles.firstRowCell : {}),
          ...(lastRow ? styles.lastRowCell : {}),
          flex: 4,
          minWidth: "8.156rem",
          maxWidth: "8.156rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: lastRow
              ? "none"
              : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            //background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>{data.billId}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          ...(firstRow ? styles.firstRowCell : {}),
          ...(lastRow ? styles.lastRowCell : {}),
          flex: 4,
          minWidth: "8.136rem",
          maxWidth: "8.136rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: lastRow
              ? "none"
              : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            //background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>
            {data?.createdTime &&
              format(new Date(data.createdTime), "MM/dd/yyyy")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          ...(firstRow ? styles.firstRowCell : {}),
          ...(lastRow ? styles.lastRowCell : {}),
          flex: 4,
          minWidth: "8.606rem",
          maxWidth: "8.606rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: lastRow
              ? "none"
              : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            //background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>
            {data?.createdTime &&
              format(new Date(data.createdTime), "MM/dd/yyyy")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          ...(firstRow ? styles.firstRowCell : {}),
          ...(lastRow ? styles.lastRowCell : {}),
          flex: 2.5,
          minWidth: "6.066rem",
          maxWidth: "6.066rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: lastRow
              ? "none"
              : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            //background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>545</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          ...(firstRow ? styles.firstRowCell : {}),
          ...(lastRow ? styles.lastRowCell : {}),
          flex: 2.5,
          minWidth: "5.606rem",
          maxWidth: "5.606rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: lastRow
              ? "none"
              : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            //background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>-</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          ...(firstRow ? styles.firstRowCell : {}),
          ...(lastRow ? styles.lastRowCell : {}),
          flex: 2.5,
          minWidth: "7.826rem",
          maxWidth: "7.826rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: lastRow
              ? "none"
              : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            //background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>
            {formatCurrency({
              amount: 6367,
              prefix: "$",
            })}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          ...(firstRow ? styles.firstRowCell : {}),
          ...(lastRow ? styles.lastRowCell : {}),
          flex: 2.5,
          minWidth: "8.156rem",
          maxWidth: "8.156rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: lastRow
              ? "none"
              : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            //background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>-</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          ...(firstRow ? styles.firstRowCell : {}),
          ...(lastRow ? styles.lastRowCell : {}),
          flex: 2.5,
          minWidth: "9.916rem",
          maxWidth: "9.916rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: lastRow
              ? "none"
              : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            //background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>-</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerHistoryTableRow;

/*
  <Grid
      container
      sx={{ gap: "0.35rem", flexWrap: "nowrap" }}
      onClick={handleOnClickRow}
    >
      <Grid
        item
        xs={1}
        sx={{
          ...styles.tableBodyCell,
          ...(firstRow ? styles.firstRowCell : {}),
          ...(lastRow ? styles.lastRowCell : {}),
          minWidth: "3.246rem",
          maxWidth: "3.246rem !important",
          width: "3.246rem !important",
          boxSizing: "border-box !important",
          padding: "0.5rem 0.71rem",
          // paddingBottom: "0.07rem",
          // marginBottom: "-0.065rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: lastRow
              ? "none"
              : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
          }}
        >
          <Checkbox
            onChange={(e) => setSelectedRow(e.target.checked)}
            value={selectedRow}
          />
        </Box>
        {/* <Box
          sx={{
            height: "0.07rem",
            width: "100%",
            background: Colors.ACCENT_GRAY_DISABLED,
            display: lastRow ? "none" : "block",
          }}
        ></Box> }
      </Grid>
      <Grid
          item
          xs={4}
          sx={{
            ...styles.tableBodyCell,
            ...styles.descriptionCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
            minWidth: "8.156rem",
            maxWidth: "8.156rem !important",
            // paddingBottom: "0.07rem",
            // marginBottom: "-0.065rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "0.5rem",
              textAlign: "center",
              borderBottom: lastRow
                ? "none"
                : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            }}
          >
            <Typography>{data.billId}</Typography>
          </Box>
          {/* <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
            }}
          ></Box> }
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            ...styles.tableBodyCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
            minWidth: "8.136rem",
            maxWidth: "8.136rem !important",
            paddingBottom: "0.07rem",
            marginBottom: "-0.065rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "0.5rem",
              textAlign: "center",
              borderBottom: lastRow
                ? "none"
                : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            }}
          >
            <Typography>
              {data?.createdTime &&
                format(new Date(data.createdTime), "MM/dd/yyyy")}
            </Typography>
          </Box>
          {/* <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
            }}
          ></Box> }
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            ...styles.tableBodyCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
            minWidth: "8.606rem",
            maxWidth: "8.606rem !important",
            // paddingBottom: "0.07rem",
            // marginBottom: "-0.065rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "0.5rem",
              textAlign: "center",
              borderBottom: lastRow
                ? "none"
                : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            }}
          >
            <Typography>
              {data?.createdTime &&
                format(new Date(data.createdTime), "MM/dd/yyyy")}
            </Typography>
          </Box>
          {/* <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
            }}
          ></Box> }
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            ...styles.tableBodyCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
            minWidth: "6.066rem",
            maxWidth: "6.066rem !important",
            // paddingBottom: "0.07rem",
            // marginBottom: "-0.065rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "0.5rem",
              textAlign: "center",
              borderBottom: lastRow
                ? "none"
                : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            }}
          >
            <Typography>545</Typography>
          </Box>
          {/* <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
            }}
          ></Box> }
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            ...styles.tableBodyCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
            minWidth: "5.606rem",
            maxWidth: "5.606rem !important",
            // paddingBottom: "0.07rem",
            // marginBottom: "-0.065rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "0.5rem",
              textAlign: "center",
              borderBottom: lastRow
                ? "none"
                : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            }}
          >
            <Typography>-</Typography>
          </Box>
          {/* <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
            }}
          ></Box> }
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            ...styles.tableBodyCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
            minWidth: "7.826rem",
            maxWidth: "7.826rem !important",
            // paddingBottom: "0.07rem",
            // marginBottom: "-0.065rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "0.5rem",
              textAlign: "center",
              borderBottom: lastRow
                ? "none"
                : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            }}
          >
            <Typography>$ 6367</Typography>
          </Box>
          {/* <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
            }}
          ></Box> }
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            ...styles.tableBodyCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
            minWidth: "8.156rem",
            maxWidth: "8.156rem !important",
            // paddingBottom: "0.07rem",
            // marginBottom: "-0.065rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "0.5rem",
              textAlign: "center",
              borderBottom: lastRow
                ? "none"
                : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            }}
          >
            <Typography>-</Typography>
          </Box>
          {/* <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
            }}
          ></Box> }
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            ...styles.tableBodyCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
            minWidth: "9.916rem",
            maxWidth: "9.916rem !important",
            // paddingBottom: "1rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "0.5rem",
              textAlign: "center",
              borderBottom: lastRow
                ? "none"
                : `0.142857143rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            }}
          >
            <Typography>-</Typography>
          </Box>
          {/* <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
            }}
          ></Box> }
        </Grid>
      </Grid>
*/
