import { Navigate, createBrowserRouter } from "react-router-dom";
import { AdminDashboardPage, ChangePassword, LoginPage } from "../pages";
import { LobbyPage } from "../pages/pos/lobby/Lobby";
import PrivateRoute from "../components/common/PrivateRoute";
import PublicRoute from "../components/common/PublicRoute";
import CreateCustomerPage from "../pages/pos/customer/create-customer";
import Alteration from "../pages/pos/alteration/Alteration";
import Pickup from "../pages/pos/pickup/pickup";
import CustomerHistoryPage from "../pages/pos/customer/customer-history";
import PosDashboardPage from "../pages/pos/PosDashboardPage";
import CustomerAnalysisScreen from "../pages/pos/customer/customer-analysis";
import BagRackRegisterPage from "../pages/pos/bag-rack-register/BagRackRegister";
import RackConfig from "../pages/admin/config/rack-config/rackConfig";
import UploadPrice from "../pages/admin/config/upload-price/uploadPrice";
import { ConfigPage } from "../pages/pos/configs/ConfigPage";
import DiscountsPage from "../pages/admin/discounts/discounts";
import MerchantsPage from "../pages/admin/config/merchants/merchants";
import MerchantCreatePage from "../pages/admin/config/merchants/create";
import StoresPage from "../pages/admin/config/stores/stores";
import CashiersPage from "../pages/admin/config/cashierPoints/points";

const routers = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/auth/login" replace />,
  },
  {
    path: "/auth/login",
    element: (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: "/auth/password/change",
    element: (
      <PublicRoute>
        <ChangePassword />
      </PublicRoute>
    ),
  },
  {
    path: "/pos/lobby",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <LobbyPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/:id",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <PosDashboardPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/config/printer",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <ConfigPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/:id/check-in/alteration",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <Alteration />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/:id/pickup",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <Pickup />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/customer/create",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <CreateCustomerPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/customer/:id/update",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <CreateCustomerPage mode="edit" />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/customer/:id/history",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <CustomerHistoryPage pickupMode={false} />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/customer/pickup/:id",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <CustomerHistoryPage pickupMode={true} />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/customer/:id/analysis",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <CustomerAnalysisScreen />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/bag-rack-register",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <BagRackRegisterPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/dashboard",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <AdminDashboardPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/rack-config",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <RackConfig />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/discounts",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <DiscountsPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/upload-price",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <UploadPrice />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/merchants",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <MerchantsPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/merchants/create",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <MerchantCreatePage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/merchants/:merchantWhiteLabel/stores",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <StoresPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/merchants/:merchantWhiteLabel/stores/:storeID/points",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <CashiersPage />
      </PrivateRoute>
    ),
  },
]);

export { routers };
