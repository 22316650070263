import { useState, useEffect } from "react";

const usb = (navigator as any).usb;

const useThermalPrinter = () => {
  const [ports, setPorts] = useState<any>(null);
  const [outNumber, setOutNumber] = useState(2);
  const isConfigured = ports && ports.length > 0;

  const configure = async () => {
    if (ports <= 0) {
      await usb.requestDevice({ filters: [] });
      const usbDevices = await usb.getDevices();
      JSON.stringify(usbDevices);
      await usbDevices.map((device: any) => {
        return {
          vendorId: device.vendorId,
          productId: device.productId,
        };
      });
      initialize();
    }
  };

  const print = async (data: any) => {
    if (ports.length > 0) {
      await ports[0].transferOut(outNumber, data);
    }
  };

  const initialize = async () => {
    const tempPorts = await usb.getDevices();
    setPorts(tempPorts);
  };

  const initializeDevice = async () => {
    try {
      if (ports && ports.length > 0) {
        const interfaceNumber =
          ports[0].configuration.interfaces[0].interfaceNumber;
        const configurationValue = ports[0].configuration.configurationValue;
        const alternateSettingNumber =
          ports[0].configuration.interfaces[0].alternate.alternateSettingNumber;
        const otNum =
          ports[0].configuration.interfaces[0].alternate.endpoints.find(
            (endpoint: any) => endpoint.direction === "out"
          );

        await ports[0].open();
        if (ports[0].configuration === null)
          await ports[0].selectConfiguration(configurationValue);
        await ports[0].claimInterface(interfaceNumber);
        await ports[0].selectAlternateInterface(
          interfaceNumber,
          alternateSettingNumber
        );

        setOutNumber(otNum.endpointNumber);
      }
    } catch (e) {
      console.log("Error Check:", e);
    }
  };

  useEffect(() => {
    setTimeout(initializeDevice, 5000);
  }, [ports]);

  return { initialize, print, configure, isConfigured };
};

export default useThermalPrinter;
