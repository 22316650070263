import {
  AlertColor,
  Box,
  CircularProgress,
  FormControl,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { Colors } from "../../../configs/colors";
import { PaymentType } from "../payment-modal/payment";
import { PaymentTypeEnum } from "../../../typings/enum/paymentTypes";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import {
  addStoreCreditAction,
  getCustomerByIdAction,
} from "../../../redux/actions/customerAction";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { CustomButton } from "../../atoms";
import ConfirmDialog from "../../atoms/confirm-box/confirm-box";
import { CashSection } from "../payment-modal/sections/cash";
import { CardSection } from "../payment-modal/sections/card";
import { CheckSection } from "../payment-modal/sections/check";
import { StoreCreditSection } from "../payment-modal/sections/storeCredit";
import { formatCurrency } from "../../../util/common-functions";

export interface IManageStoreCreditContentProps {
  onSuccessfulAddStoreCredit(tab: number): void;
  isCustomerEdited: boolean;
  resetDirtyState(isDirty: Boolean): void;
}

// interface toastInterface {
//   open: boolean;
//   message: string;
//   type: AlertColor;
// }

const ManageStoreCreditContent: FC<IManageStoreCreditContentProps> = ({
  onSuccessfulAddStoreCredit,
  isCustomerEdited,
  resetDirtyState,
}) => {
  const { showSnackbar } = useSnackbarContext();
  const { addStoreCreditLoading } = useAppSelector((state) => state.customer);
  const { customer } = useAppSelector((state) => state.customer);
  const { user } = useAppSelector((state) => state.auth);
  const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(
    PaymentTypeEnum.CASH
  );
  const [amount, setAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);

  const PayOptionSections: { [key: string]: JSX.Element } = {
    CARD: (
      <CardSection
        billValue={amount}
        paidAmount={amount}
        returnPaidAmount={setAmount}
        returnBalance={() => {}}
      />
    ),
    CASH: (
      <CashSection
        billValue={amount}
        returnBalance={setBalance}
        returnPaidAmount={(amount: number) => {}}
        type="storeCredit"
        balance={balance}
      />
    ),
    CHEQUE: (
      <CheckSection
        billValue={amount}
        paidAmount={amount}
        returnPaidAmount={setAmount}
        returnBalance={() => {}}
      />
    ),
    STORE_CREDIT: (
      <StoreCreditSection
        billValue={amount}
        paidAmount={amount}
        returnPaidAmount={setAmount}
        paymentType="deposit"
      />
    ),
  };

  const dispatch = useAppDispatch();

  const handlePaymentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentType((event.target as HTMLInputElement).value as PaymentTypeEnum);
  };

  const handleAddStoreCredit = () => {
    if (balance >= 0 && amount > 0) {
      if (user && customer) {
        dispatch(
          addStoreCreditAction({
            storeUserId: user.id,
            customerId: customer.id,
            paymentType: paymentType,
            amount: amount,
          })
        ).then((res: any) => {
          if (res.payload.status === "SUCCESS") {
            dispatch(getCustomerByIdAction(String(customer.id)));
            showSnackbar("Store Credit added successfuly", "success");
            onSuccessfulAddStoreCredit(1);
          } else {
            showSnackbar(res.payload.message, "error");
          }
        });
      } else {
        showSnackbar("Customer not found", "warning");
      }
    } else {
      showSnackbar("Please check the values again!", "warning");
    }
    resetDirtyState(false);
  };
  const handleOnClickAddStoreCredit = () => {
    if (isCustomerEdited) {
      setDiscardDialogOpen(true);
    } else {
      handleAddStoreCredit();
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // gap: "1.3571429rem",
        justifyContent: "space-between",
        height: "50.8571429rem",
        // background: "green",
        paddingTop: "1rem",
      }}
    >
      <ConfirmDialog
        open={discardDialogOpen}
        title="Discard Confirmation"
        message="If you proceed, the changes you've made for the user will be discarded."
        onCancel={() => setDiscardDialogOpen(false)}
        onConfirm={() => handleAddStoreCredit()}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1.57142857rem",
          justifyContent: "space-between",
          //   background: "red",
        }}
      >
        <Box
          sx={{
            background: Colors.BACKGROUND_GRAY,
            borderRadius: "0.39428571428rem",
            p: "1.2rem 2.14285714rem",
            width: "59.8571429rem",
            // width: "40.5714286rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "0.75rem",
            height: "14.0714286rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.71428571rem",
              fontWeight: "bold",
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            Payment Type
          </Typography>
          <FormControl>
            <RadioGroup
              value={paymentType}
              onChange={handlePaymentTypeChange}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.75rem",
                justifyContent: "space-between",
                //   width: "49.5714286rem",
                width: "47.2142857rem",
              }}
            >
              <PaymentType
                value={PaymentTypeEnum.CARD}
                label="Card"
                icon="CARD"
              />
              <PaymentType
                value={PaymentTypeEnum.CASH}
                label="Cash"
                icon="CASH"
              />
              <PaymentType
                value={PaymentTypeEnum.CHECK}
                label="Check"
                icon="CHECK"
              />
              <PaymentType
                value={PaymentTypeEnum.STORE_CREDIT}
                label="Store Credit"
                icon="STORE"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            //   width: "29.6428571rem",
            width: "31.9107143rem",
            height: "14.0714286rem",
            background: Colors.BACKGROUND_GRAY,
            borderRadius: "0.357142857rem",
            p: "1.5rem 2.21428571rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.857142857rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.71428571rem",
              fontWeight: "bold",
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            Store credits
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "1.42857143rem", fontWeight: 500 }}>
              Balance
            </Typography>
            <Box
              sx={{
                width: "16.8rem",
                minHeight: "2.78571429rem !important",
                height: "2.78571429rem !important",
                maxHeight: "2.78571429rem !important",
                borderRadius: "0.357142857rem",
                background: Colors.NOTIFIC_GREEN,
                border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                boxShadow: "none !important",
                padding: "0 !important",
                paddingInline: "0.928571429rem !important",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                margin: "0 !important",
                textAlign: "right",
                paddingRight: "0.928571429rem",
                boxSizing: "border-box",
              }}
            >
              <Typography
                sx={{
                  color: Colors.TEXT_GRAY_DARK,
                  opacity: 0.75,
                  fontSize: "1.42857143rem",
                  fontWeight: 600,
                }}
              >
                {formatCurrency({
                  amount: customer?.storeCreditBalance || 0,
                })}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 500,
              lineHeight: "1.2rem",
            }}
          >
            Stored credits can be used to pay upcoming bills. Customers are
            allowed to use these credits in any bill they prefer.
          </Typography>
        </Box>
      </Box>
      <Box>{PayOptionSections[paymentType]}</Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "1.37678571rem",
          //   background: "blue",
        }}
      >
        <CustomButton
          sx={{
            background: Colors.PRIMARY,
            color: Colors.WHITE,
            textTransform: "capitalize",
            height: "5.85714286rem",
            fontSize: "2.57142857rem",
            width: "32.9285714rem",
            fontWeight: "bold",
            borderRadius: "0.714285714rem",
            "&:hover": {
              background: Colors.PRIMARY,
            },
          }}
          onClick={handleOnClickAddStoreCredit}
          disabled={
            addStoreCreditLoading ||
            amount <= 0 ||
            (balance < 0 && paymentType === PaymentTypeEnum.CASH)
          }
        >
          {addStoreCreditLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Continue"
          )}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default ManageStoreCreditContent;
