export interface ISubSpot {
  id: 1 | 2 | 3 | 4 | 100000;
  comment: string;
  price: number | null;
}
export interface ISpot {
  id: number;
  label: string;
  subSpots: ISubSpot[];
}
export interface ISelectedSpot {
  id: number;
  label: string;
  subSpot: ISubSpot;
}

export const spotPrices: ISpot[] = [
  {
    id: 1,
    label: "Blood",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 2,
    label: "Ink",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 3,
    label: "Urine",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 4,
    label: "Coffee",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 5,
    label: "Alcohol",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 6,
    label: "Food",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 7,
    label: "Grease",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 8,
    label: "Mud",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 9,
    label: "Mildew",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 10,
    label: "Rust",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 11,
    label: "Perfume",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 12,
    label: "Sweat",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 13,
    label: "Wine",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 14,
    label: "Bleach",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 15,
    label: "Make-Up",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 16,
    label: "Paint",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 17,
    label: "Feces",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 19,
    label: "Mold",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 20,
    label: "Dye",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 21,
    label: "Gasoline",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 22,
    label: "Pet Waste",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 23,
    label: "Bed Bugs",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 24,
    label: "Vomit",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
  {
    id: 100000,
    label: "Custom",
    subSpots: [
      {
        id: 2,
        comment: "Minor",
        price: null,
      },
      {
        id: 3,
        comment: "Medium",
        price: null,
      },
      {
        id: 4,
        comment: "Major",
        price: null,
      },
    ],
  },
];
