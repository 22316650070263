import { FC } from "react";

export interface PickUpLayoutProps {
    children?: React.ReactNode
}

const PickUpLayout:FC<PickUpLayoutProps>=({children})=>{
    return <>
        {children}
    </>
}

export default PickUpLayout;