/*

    Working Days: monday, tuesday, wednesday, thursday, friday, saturday
    Checkout Types: special, regular
    Checkout Ready Time: 3.00 PM
    Checkin Time Ranges: before 10.00 AM, after 10.00 AM


    Day                       CheckIn Range                   Regular                         Special
    --------------------------------------------------------------------------------------------------
    Monday to Thursday        Before 10.00 AM                 Day After Next Day              Same Day
    Monday to Thursday        After 10.00 AM                  Day After Day After Next Day    Next Day
    Friday                    Before 10.00 AM                 Day After Day After Next Day    Same Day
    Friday                    After 10.00 AM                  Day After Day After Next Day    Next Day
    Saturday                  Before 10.00 AM                 Day After Day After Next Day    Same Day
    Saturday                  After 10.00 AM                  Day After Day After Next Day    Next Day
*/

import dayjs from "dayjs";
import { useAppSelector } from "../hooks";

export interface IgetCheckOutDateProps {
  isSpecialDay: boolean;
  checkInCutOffTime: Date;
  pickupTime: Date;
  workingDays: string[];
  generalServiceDays: number;
  specialServiceDays: number;
}

const getCheckOutDate = ({
  isSpecialDay,
  checkInCutOffTime,
  pickupTime,
  workingDays,
  generalServiceDays,
  specialServiceDays,
}: IgetCheckOutDateProps) => {
  const checkOutType = isSpecialDay ? "special" : "regular";
  const checkInDate = new Date();
  const checkInTimeRange: "morning" | "afternoon" =
    checkInDate.getHours() < checkInCutOffTime.getHours()
      ? "morning"
      : "afternoon";
  const checkOutDate = new Date();
  let daysToAdd = 0;

  const workingDaysPrepared = [
    workingDays?.includes("SUNDAY"),
    workingDays?.includes("MONDAY"),
    workingDays?.includes("TUESDAY"),
    workingDays?.includes("WEDNESDAY"),
    workingDays?.includes("THURSDAY"),
    workingDays?.includes("FRIDAY"),
    workingDays?.includes("SATURDAY"),
  ];

  const calculateDaysToAdd = (days: number) => {
    let toAdd = days;
    const startDate = new Date();
    let startIndex = 0;

    while (startIndex < days) {
      startDate.setDate(startDate.getDate() + 1);
      if (!workingDaysPrepared[startDate.getDay()]) {
        toAdd++;
      } else {
        startIndex++;
      }
    }

    return toAdd;
  };

  if (checkOutType === "regular") {
    if (checkInTimeRange === "morning") {
      daysToAdd = calculateDaysToAdd(generalServiceDays);
    } else {
      daysToAdd = calculateDaysToAdd(generalServiceDays + 1);
    }
  } else {
    if (checkInTimeRange === "morning") {
      daysToAdd = calculateDaysToAdd(specialServiceDays);
    } else {
      daysToAdd = calculateDaysToAdd(specialServiceDays + 1);
    }
  }

  checkOutDate.setDate(checkInDate.getDate() + daysToAdd);
  checkOutDate.setHours(pickupTime.getHours(), pickupTime.getMinutes(), 0, 0);
  const TypeConvertedCheckoutDate = dayjs(checkOutDate);
  return TypeConvertedCheckoutDate;
};

export default getCheckOutDate;
