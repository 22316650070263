import { FC } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { Colors } from "../../../configs/colors";
import { Box, Button, Typography } from "@mui/material";
import { CustomButton } from "../../atoms";

export interface IFailureProps {
  onContinue(): void;
}

const Failure: FC<IFailureProps> = ({ onContinue }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
        height: "calc(100% - 4.75rem)",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "3.5rem",
          alignItems: "center",
          justifyContent: "center",
          //   background: "red",
        }}
      >
        <ErrorIcon
          sx={{ fontSize: "19rem", color: Colors.BROWN, mt: "-3.5rem" }}
        />
        <Typography
          sx={{
            fontSize: "3.5rem",
            color: Colors.TEXT_GRAY_DARK,
            fontWeight: "bold",
          }}
        >
          Checkout Failed
        </Typography>
      </Box>
      <CustomButton
        onClick={onContinue}
        sx={{
          width: "33.165255rem",
          height: "3.786925rem",
          borderRadius: "0.71428571428rem",
          background: Colors.PRIMARY,
          color: Colors.WHITE,
          fontSize: "2.28571429rem",
          fontWeight: "bold",
          textTransform: "capitalize",
          "&:hover": {
            background: Colors.PRIMARY,
          },
        }}
      >
        Continue
      </CustomButton>
    </Box>
  );
};

export default Failure;
