// MultiTenantLaundryPOS.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';

const BaseSiteHomePage: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Typography variant="h4" sx={{ color: 'darkslategray' }}>
        Multi-Tenant Laundry POS
      </Typography>
      <Typography variant="body2" sx={{ color: 'gray' }}>
        Version 0.0.1
      </Typography>
    </Box>
  );
};

export default BaseSiteHomePage;
