import React, { FC, useEffect, useState } from "react";
import CustomModal from "../../common/custom-modal/custom-modal";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { Box, Typography } from "@mui/material";
import { Colors } from "../../../configs";
import { CustomButton } from "../../atoms";

export interface INotePopupProps {
  showPopup: boolean;
  popupContent: React.ReactNode;
  onClose?: () => void;
}

const NotePopup: FC<INotePopupProps> = ({ showPopup, popupContent, onClose }) => {
  const handleOnClose = () => {
    onClose && onClose();
  };

  return (
    <CustomModal
      onClose={handleOnClose}
      openModal={showPopup}
      title=""
      mode="popup"
      height="46rem"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "3.5rem",
          //   height: "30rem",
          //   background: "red",
        }}
      >
        <AnnouncementIcon
          sx={{
            color: Colors.SECONDARY,
            fontSize: "11rem",
          }}
        />
        <Typography
          sx={{
            fontSize: "1.6rem",
            color: Colors.TEXT_GRAY_DARK,
            fontWeight: "bold",
            opacity: 0.7,
            textAlign: "center",
            height: "13.5rem",
            overflowY: "auto",
            paddingRight: "1.3rem",
            marginRight: "-2rem",
            // styled scrollbar
            "&::-webkit-scrollbar": {
              width: "0.7rem",
            },
            "&::-webkit-scrollbar-track": {
              background: Colors.ACCENT_GRAY,
              borderRadius: "0.2rem",
            },
            "&::-webkit-scrollbar-thumb": {
              background: Colors.SECONDARY,
              borderRadius: "0.2rem",
            },
          }}
        >
          {popupContent}
        </Typography>
        <CustomButton
          sx={{
            background: Colors.PRIMARY,
            color: Colors.WHITE,
            fontSize: "1.6rem",
            fontWeight: "bold",
            padding: "0.7rem 2.5rem",
            marginTop: "1.5rem",
            "&:hover": {
              background: Colors.PRIMARY,
            },
          }}
          onClick={handleOnClose}
        >
          OK
        </CustomButton>
      </Box>
    </CustomModal>
  );
};

export default NotePopup;
