import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "./reduxHooks";
import {
  authDetailsInitializeAction,
  getLoginUserList,
} from "../redux/actions/authAction";
import {
  getAlterationPrices,
  getAlterationSubCategories,
  getCategoryAction,
  getCategoryMaterialRelationship,
  getCategoryRelationship,
  getPricing,
  getSecondSubCategoryAction,
  getSubCategoryAction,
  getSubCategoryMaterialRelationship,
} from "../redux/actions/pricingAction";
import { CartSliceAction } from "../redux/slice/cartSlice";
import { BaseResponse, CategoryResponse } from "../typings";
import getTenantId from "./useTenantId";
import { getDiscountsByStoreId } from "../redux/actions/discountAction";
import { getAllMerchants } from "../redux/actions/merchantAction";
import { getStoreConfig } from "../redux/actions/storeActions";
const useAppInitializer = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const tenantId = getTenantId();
  useEffect(() => {
    if (user === null) {
      dispatch(authDetailsInitializeAction());
    }
    if (user) {
      dispatch(getCategoryAction()).then((data) => {
        const dataPayload = data.payload as {
          response: BaseResponse<CategoryResponse>;
        };
        if (dataPayload?.response?.body?.length > 0)
          dispatch(
            CartSliceAction.setSelectedServiceType(dataPayload.response.body[0])
          );
      });
      dispatch(getCategoryRelationship());
      dispatch(getCategoryMaterialRelationship());
      dispatch(getSubCategoryMaterialRelationship());
      dispatch(getSecondSubCategoryAction());
      // dispatch(getDryCleanPriceAction());
      dispatch(getAlterationPrices());
      dispatch(getAlterationSubCategories());
      dispatch(getDiscountsByStoreId(user.storeId));
      dispatch(getStoreConfig({ storeId: user.storeId, merchantId: 1 }));
      if (tenantId) {
        if (user.role.name.toLowerCase() === "admin") {
          dispatch(getAllMerchants());
        }
        dispatch(getLoginUserList(tenantId));
      }
    }
  }, [user]);
};

export { useAppInitializer };
