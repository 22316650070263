import { FC, useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";

import CustomerHistoryTableRow from "./customer-history-table-row";
import { Colors } from "../../../../configs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getCustomerBillHistoryAction } from "../../../../redux/actions/customerAction";
import { useParams } from "react-router-dom";
import { CustomerBillsResponse } from "../../../../typings";
import { getBillByIdAction } from "../../../../redux/actions/cartAction";

export interface ICustomerHistoryTableProps {
  contentHeight?: string;
}

const CustomerHistoryTable: FC<ICustomerHistoryTableProps> = ({
  contentHeight = "39.07rem",
}) => {
  const params = useParams();
  const customerId = params.id;
  const queryParams = new URLSearchParams(window.location.search);
  const billId = queryParams.get("billId");
  const dispatch = useAppDispatch();
  const { bills, billsLoading } = useAppSelector((state) => state.customer);
  const [clickedRow, setClickedRow] = useState<CustomerBillsResponse | null>(
    null
  );

  useEffect(() => {
    if (billId)
      setClickedRow({
        billId: billId,
        id: Number(billId),
      });
  }, [billId]);

  useEffect(() => {
    if (clickedRow?.id)
      dispatch(getBillByIdAction({ billId: String(clickedRow?.id) }));
  }, [clickedRow]);

  useEffect(() => {
    getCustommerBillList();
  }, [customerId]);

  const getCustommerBillList = () => {
    if (customerId) dispatch(getCustomerBillHistoryAction(customerId));
  };
  const styles = {
    tableHeadingCell: {
      borderRadius: "0.71rem",
      background: Colors.SECONDARY,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem 0.71rem",
      boxSizing: "border-box",
      color: "white",
    },
  };

  const handleOnClickRow = (data: CustomerBillsResponse) => {
    setClickedRow(data);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        padding: "0.71rem",
        gap: "1.14rem",
        height: "28.42rem",
      }}
    >
      <Box>
        <Grid container sx={{ gap: "1.21rem", height: "100%" }}>
          <Grid item xs={12}>
            <Box style={{ position: "sticky", top: 0 }}>
              <Grid container sx={{ gap: "0.35rem", flexWrap: "nowrap" }}>
                <Grid
                  item
                  xs={1}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "3.246rem",
                    maxWidth: "3.246rem !important",
                  }}
                >
                  &nbsp;
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "8.156rem",
                    maxWidth: "8.156rem !important",
                  }}
                >
                  Invoice
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "8.136rem",
                    maxWidth: "8.136rem !important",
                  }}
                >
                  Date In
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "8.606rem",
                    maxWidth: "8.606rem !important",
                  }}
                >
                  Due date
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "6.066rem",
                    maxWidth: "6.066rem !important",
                  }}
                >
                  Pieces
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                  }}
                >
                  Rack
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                  }}
                >
                  Balance
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "8.156rem",
                    maxWidth: "8.156rem !important",
                  }}
                >
                  Pay Date
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "9.916rem",
                    maxWidth: "9.916rem !important",
                  }}
                >
                  Pickup Date
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ height: contentHeight }}>
            <Box
              sx={{
                height: contentHeight,
                overflowY: "auto",
                overflowX: "hidden",
                "&::-webkit-scrollbar": { width: "0.5rem" },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ADADAD",
                  borderRadius: "0rem",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "0rem",
                },
              }}
            >
              {bills?.map((item) => (
                <CustomerHistoryTableRow
                  isSelected={clickedRow?.id === item.id}
                  data={item}
                  firstRow={bills?.indexOf(item) === 0}
                  lastRow={bills?.indexOf(item) === bills.length - 1}
                  onClick={handleOnClickRow}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomerHistoryTable;
