import { FC, useEffect, useState } from "react";
import CustomModal from "../../common/custom-modal/custom-modal";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import {
  getBillNoteByIdAction,
  addBillNoteAction,
} from "../../../redux/actions/cartAction";
import { CustomButton, CustomTextBox } from "../../atoms";
import { Colors } from "../../../configs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useParams } from "react-router-dom";
import { NoteOccasion } from "../../../typings/enum/noteOccasions";
import { CartSliceAction } from "../../../redux/slice/cartSlice";
import BillNotesCard from "./billNotesCard";
import { v4 as uuidv4 } from "uuid";

export interface INotesModalProps {
  open: boolean;
  onClose(): void;
}

const BillNotesModal: FC<INotesModalProps> = ({ open, onClose }) => {
  const [newNote, setNewNote] = useState("");
  // const [selectedOccations, setSelectedOccasions] = useState<string[]>([]);
  const [mode, setMode] = useState<"temp" | "perm">("temp");
  const dispatch = useAppDispatch();
  const params = useParams();
  const customerId = params.id;
  const { bill, billNotes, addBillNoteLoading, temporaryBillNotes } =
    useAppSelector((state) => state.cart);
  const { user } = useAppSelector((state) => state.auth);

  // const billId: number = Number(bill?.id ?? "1"); // change later

  // bill != null ? alert(JSON.stringify(bill)) : console.log("");

  useEffect(() => {
    // alert("detected bill " + bill?.id);
    if (bill != null && Number(bill.id) >= 0) {
      setMode("perm");
      if (temporaryBillNotes.length > 0) {
        // alert("temp bill detected");
        temporaryBillNotes.forEach(async (note) => {
          await dispatch(
            addBillNoteAction({
              note: note.note,
              occasions: note.occasions,
              merchantUserId: note.merchantUserId,
              customerId: note.customerId,
              billId: Number(bill.id),
            })
          );
        });
        dispatch(CartSliceAction.setTemporaryBillNotes([]));
      }

      dispatch(getBillNoteByIdAction({ billId: String(bill.id) }));
    } else {
      setMode("temp");
    }
  }, [bill, customerId]);

  const handleNewNoteTextChange = (note: string) => {
    setNewNote(note);
  };

  const handleOnAddNote = async () => {
    if (newNote.length > 0) {
      if (customerId && user) {
        const tempId = uuidv4();
        bill !== null && Number(bill.id) > 0
          ? await dispatch(
              addBillNoteAction({
                note: newNote,
                occasions: [] as NoteOccasion[],
                // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
                merchantUserId: 1,
                customerId: Number(customerId),
                billId: Number(bill.id),
              })
            ).then((response) => {
              // if (response) {
              //   console.log(JSON.stringify(response));
              // } else {
              //   alert("Response is null");
              // }
              dispatch(getBillNoteByIdAction({ billId: String(bill.id) }));
            })
          : dispatch(
              CartSliceAction.setTemporaryBillNotes([
                {
                  tempId: tempId,
                  note: newNote,
                  occasions: [] as NoteOccasion[],
                  // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
                  merchantUserId: 1,
                  customerId: Number(customerId),
                  merchantFirstName: user.firstName,
                  merchantLastName: user.lastName,
                  date: new Date().toISOString(),
                },
                ...temporaryBillNotes,
              ])
            );
        setNewNote("");
      }
    } else {
      alert("Cannot add empty notes");
    }
  };

  return (
    <CustomModal
      title={"Bill Notes"}
      openModal={open}
      width="55.4464286rem"
      height="71.8571429rem"
      onClose={onClose}
    >
      <Box
        sx={{
          height: "34rem",
          overflowY: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          touchAction: "pan-y",
        }}
      >
        {/* <NotesCard
          description="lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          noteBy="John Doe"
          date={new Date()}
        />
        <NotesCard
          description="lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          noteBy="John Doe"
          date={new Date()}
        /> */}
        {addBillNoteLoading ? (
          <Box
            sx={{
              textAlign: "center",
              py: "14rem",
              fontSize: "1.8rem",
              opacity: 0.5,
            }}
          >
            <CircularProgress />
          </Box>
        ) : mode === "perm" ? (
          billNotes == null || billNotes.length === 0 ? (
            <Box
              sx={{
                textAlign: "center",
                py: "14rem",
                fontSize: "1.8rem",
                opacity: 0.5,
              }}
            >
              No notes for this bill
            </Box>
          ) : (
            billNotes.map((note) => (
              <BillNotesCard
                key={note.id}
                description={note.note}
                noteBy={`${note.reportedUserFirstName} ${note.reportedUserLastName}`}
                date={note.createdTime ? new Date(note.createdTime) : null}
                noteId={note.id}
                noteMode={"perm"}
              />
            ))
          )
        ) : temporaryBillNotes == null || temporaryBillNotes.length === 0 ? (
          <Box
            sx={{
              textAlign: "center",
              py: "14rem",
              fontSize: "1.8rem",
              opacity: 0.5,
            }}
          >
            No notes for this bill
          </Box>
        ) : (
          temporaryBillNotes.map((note, index) => (
            <BillNotesCard
              key={note.tempId}
              description={note.note}
              noteBy={`${note.merchantFirstName} ${note.merchantLastName}`}
              date={note.date ? new Date(note.date) : null}
              noteId={note.tempId}
              noteMode={"temp"}
            />
          ))
        )}
      </Box>
      <Divider sx={{ marginTop: "1.42857143rem" }} />
      <Typography
        sx={{
          fontSize: "1.71428571rem",
          fontWeight: 600,
          color: Colors.TEXT_GRAY_DARK,
          marginTop: "1rem",
          marginBottom: "0.5rem",
        }}
      >
        Add new note
      </Typography>
      <CustomTextBox
        multiline
        sx={{
          width: "100%",
          "& .MuiInputBase-input": {
            height: "6.14285714rem",
            minHeight: "6.14285714rem",
            maxHeight: "6.14285714rem",
          },
        }}
        value={newNote}
        onChange={(event) => handleNewNoteTextChange(event.target.value)}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2rem",
        }}
      >
        <CustomButton
          sx={{
            background: Colors.PRIMARY,
            color: Colors.WHITE,
            textTransform: "capitalize",
            fontSize: "1.42857143rem",
            height: "3.85714286rem",
            width: "14.2857143rem",
          }}
          disabled={newNote.length === 0 || addBillNoteLoading}
          onClick={handleOnAddNote}
        >
          Add New Note
        </CustomButton>
      </Box>
    </CustomModal>
  );
};

export default BillNotesModal;
