import { Box, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { Colors } from "../../../../configs";
import { CustomButton } from "../../../atoms";
import { Swiper, SwiperSlide } from "swiper/react";
import { sortArray } from "../../../../util/array-functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import {
  ISelectedUpcharge,
  ISubUpcharge,
  IUpcharge,
  upchargePrices,
} from "../../../../typings/interfaces/pos/upCharge";
import UpchargesModal from "../../../pos/upcharges-modal/upchargesModal";
import { cloneDeep } from "lodash";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";

export interface IUpchargesSectionProps {}

// const upcharges: IUpcharge[] = [
//   { id: 1, label: "Beads" },
//   { id: 2, label: "Leather Trim" },
//   { id: 3, label: "Fur Trim" },
//   { id: 4, label: "Faux Trim" },
//   { id: 5, label: "Ruffles" },
//   { id: 6, label: "Roll Hair" },
//   { id: 7, label: "Roll Lint" },
//   { id: 8, label: "Starch" },
//   { id: 9, label: "Sizing" },
//   { id: 10, label: "Box" },
//   { id: 11, label: "Shave" },
//   { id: 12, label: "Water Repellent" },
//   { id: 13, label: "Bulky Garment" },
//   { id: 14, label: "Creases" },
//   { id: 15, label: "Tie Fringe" },
//   { id: 16, label: "Blocking" },
//   { id: 17, label: "Buttons" },
//   { id: 18, label: "Ozone" },
// ];
const upcharges: IUpcharge[] = upchargePrices;

const upchargesSorted = sortArray({
  array: upcharges || [],
  sortByKey: "label",
  fixedFirstItem: "Custom",
});

const UpchargesSection: FC<IUpchargesSectionProps> = () => {
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const [upchargeModalOpen, setUpchargeModalOpen] = useState<{
    open: boolean;
    upcharge: IUpcharge | null;
  }>({
    open: false,
    upcharge: null,
  });

  const handleOnClickUpcharge = (upcharge: IUpcharge) => {
    setUpchargeModalOpen({
      open: true,
      upcharge: upcharge,
    });
  };
  const handleCloseUpchargeModal = () => {
    setUpchargeModalOpen({
      open: false,
      upcharge: null,
    });
  };
  const handleAddUpcharge = (upcharge: ISelectedUpcharge) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      if (
        cartItem.upcharges.find(
          (upchargeItem) => upchargeItem.id === upcharge.id
        ) === undefined
      ) {
        // Add new upcharge
        cartItem.upcharges.push(upcharge);
      } else {
        // Update existing upcharge
        cartItem.upcharges = cartItem.upcharges.map((upchargeItem) =>
          upchargeItem.id === upcharge.id ? upcharge : upchargeItem
        );
      }
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };
  const handleRemoveUpcharge = (upcharge: ISelectedUpcharge) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      cartItem.upcharges = cartItem.upcharges.filter(
        (upchargeItem) => upchargeItem.id !== upcharge.id
      );
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.71428571rem",
        background: Colors.BACKGROUND_GRAY,
        // background: "red",
        borderRadius: "0.71rem",
        padding: "0.928571429rem",
      }}
    >
      {upchargeModalOpen.upcharge && (
        <UpchargesModal
          open={upchargeModalOpen.open}
          onClose={handleCloseUpchargeModal}
          upcharge={upchargeModalOpen.upcharge}
          onAdd={handleAddUpcharge}
          onRemove={handleRemoveUpcharge}
        />
      )}

      <Typography
        sx={{ fontSize: "1.71428571rem", color: Colors.TEXT_GRAY_DARK }}
      >
        Upcharges
      </Typography>
      <Swiper
        style={{ width: "72rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(upchargesSorted.length / 36))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  width: "72rem",
                  // height: "10.6428571rem",
                  // background: "red",
                  gap: "1.07142857rem",
                  rowGap: "0.857142857rem",
                  alignContent: "flex-start",
                  height: "46.7142857rem",
                }}
              >
                {upchargesSorted
                  .slice(swipePageIndex * 36, (swipePageIndex + 1) * 36)
                  .map((upcharge, i) => (
                    <CustomButton
                      sx={{
                        width: "17.0064286rem",
                        // background:
                        //   (instruction as any).checked ||
                        //   selectedCartItem?.instruction.includes(
                        //     (instruction as any).label
                        //   )
                        //     ? Colors.SECONDARY
                        //     : Colors.MAIN_GRAY,
                        background:
                          (selectedCartItem?.upcharges.filter(
                            (cartUpcharge) =>
                              cartUpcharge.id === (upcharge as IUpcharge).id
                          ).length ?? 0) > 0
                            ? Colors.SECONDARY
                            : Colors.MAIN_GRAY,
                        borderRadius: "0.71rem",
                        textTransform: "capitalize",
                        color: Colors.WHITE,
                        fontSize: "1.14rem",
                        lineHeight: "1.33928571rem",
                        padding: "1.07142857rem 0.857142857rem",
                        "&&:hover": {
                          background: Colors.TEXT_GRAY_DARK,
                        },
                        height: "4.28571429rem",
                      }}
                      key={i}
                      onClick={() =>
                        handleOnClickUpcharge(upcharge as IUpcharge)
                      }
                    >
                      {(upcharge as IUpcharge).label}
                    </CustomButton>
                  ))}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default UpchargesSection;
