import axios from "axios";
import { BaseResponseSingle } from "../../typings";
import { IStoreConfigResponse } from "../../typings/interfaces/responses/store-config-response";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ADD_STORE_CONFIG, GET_STORE_CONFIG } from "../../configs";

export const getStoreConfig = createAsyncThunk(
  "store-config/getById",
  async (
    { storeId, merchantId }: { storeId: number; merchantId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get<
        BaseResponseSingle<IStoreConfigResponse>
      >(GET_STORE_CONFIG(storeId, merchantId));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addStoreConfig = createAsyncThunk(
  "store-config/add",
  async (
    {
      storeId,
      merchantId,
      cutOffTime,
      pickupTime,
      workingDays,
      daysToDeliver,
      specialDaysToDeliver,
    }: IStoreConfigResponse,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<
        BaseResponseSingle<IStoreConfigResponse>
      >(ADD_STORE_CONFIG, {
        storeId,
        merchantId,
        cutOffTime,
        pickupTime,
        workingDays,
        daysToDeliver,
        specialDaysToDeliver,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
