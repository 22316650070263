import React, { FC, useEffect } from "react";
import { useAppInitializer } from "../../hooks";
import useUpdateTitle from "../../hooks/useUpdateTitle";
import { getTenantId } from "../../util/common-functions";

export interface WithStarterContainerProps {}

const withStarterContainer = <P extends WithStarterContainerProps>(
  WrappedComponent: React.ComponentType<P>
): FC<P> => {
  const WithStarterContainer: FC<P> = (props) => {
    // Put any logic that you want to apply to the wrapped component here
    useAppInitializer();
    useUpdateTitle(getTenantId());

    // You can pass additional props to the WrappedComponent if needed
    return <WrappedComponent {...props} />;
  };

  return WithStarterContainer;
};

export default withStarterContainer;