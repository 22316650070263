// TranslationProvider.tsx
import React, { createContext, ReactNode, useContext } from 'react';
import { getTranslationsForTenant, Translation } from '../configs/translation';

interface TranslationProviderProps {
    tenantId: string;
    children: ReactNode;
}

const TranslationContext = createContext<Translation  | undefined>(undefined);

const TranslationProvider: React.FC<TranslationProviderProps> = ({ tenantId, children }) => {
    const translations = getTranslationsForTenant(tenantId);

    return <TranslationContext.Provider value={translations}>{children}</TranslationContext.Provider>;
};

export default TranslationProvider;

export function useTranslation() {
    const context = useContext(TranslationContext);

    if (context === undefined) {
        throw new Error('useTranslation must be used within a TranslationProvider');
    }

    return context;
}
