import { FC } from "react";
import { PosLayout } from "../../../components/layouts/pos-layout/PosLayout";
import { Box, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
} from "chart.js";
import { Radar, Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

import { Colors } from "../../../configs";
import CustomerDetailSection from "../../../components/common/pos/customer-detail-section/customer-detail-section";
import { formatCurrency, formatUSDate } from "../../../util/common-functions";
import TopRightButton from "../../../components/common/top-right-button/top-right-button";
import { useNavigate, useParams } from "react-router-dom";

export interface ICustomerAnalysisProps {}

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Filler,
  Tooltip,
  Legend,
  Title
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const lineChartData = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.number.int({ min: -1000, max: 1000 })),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.number.int({ min: -1000, max: 1000 })),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const radarChartData = {
  labels: ["January", "February", "March", "April", "may", "June", "July"],
  datasets: [
    {
      label: "Skip first dataset",
      data: [0, 40, 5, 50, 5, 90, 10],
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 1,
    },
    {
      label: "Skip mid dataset",
      data: [70, 20, 20, 0, 30, 20, 25],
      backgroundColor: "rgba(128, 168, 255, 0.2)",
      borderColor: "rgba(128, 168, 255, 1)",
      borderWidth: 1,
    },
    {
      label: "Skip last dataset",
      data: [45, 40, 90, 5, 10, 90, 0],
      backgroundColor: "rgba(128, 255, 168, 0.2)",
      borderColor: "rgba(128, 255, 168, 1)",
      borderWidth: 1,
    },
  ],
};

const CustomerAnalysisScreen: FC<ICustomerAnalysisProps> = ({}) => {
  const navigate = useNavigate();
  const params = useParams();
  const customerId = params.id;
  return (
    <PosLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          gap: "0.57rem",
        }}
      >
        <Box
          sx={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
            gap: "1.78rem",
            height: "100%",
            maxHeight: "100% !important",
          }}
        >
          <CustomerDetailSection
            showAnalysisButton={true}
            showEditButton={true}
            showHistoryButton={true}
          />
          <Box
            sx={{
              width: "100%",
              height: "49.85rem",
              borderRadius: "0.71rem",
              background: Colors.BACKGROUND_GRAY,
              padding: "2.78rem 2.14rem",
              display: "flex",
              flexDirection: "column",
              gap: "2.28rem",
            }}
          >
            <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
              Customer Insights
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                rowGap: "1.16rem",
                gap: "0.89rem",
              }}
            >
              <Box
                sx={{
                  background: Colors.SECONDARY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "53.64rem",
                  color: Colors.WHITE,
                  display: "flex",
                  flexDirection: "column",
                  gap: "2.28rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                    fontWeight: "bold",
                  }}
                >
                  Last Visit
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1.14rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.71rem",
                        lineHeight: "100%",
                      }}
                    >
                      Date
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.71rem", lineHeight: "100%" }}
                    >
                      {formatUSDate(new Date())}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1.14rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.71rem",
                        lineHeight: "100%",
                      }}
                    >
                      Payment
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.71rem", lineHeight: "100%" }}
                    >
                      {formatCurrency({ amount: 200 })}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1.14rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.71rem",
                        lineHeight: "100%",
                      }}
                    >
                      Number of Jobs
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.71rem", lineHeight: "100%" }}
                    >
                      4
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  background: Colors.PRIMARY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.WHITE,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Since
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  2001 Oct
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Total Spend
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {formatCurrency({ amount: 5300 })}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Total Jobs
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  69
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Weekly Avg
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {formatCurrency({ amount: 10 })}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Monthly Avg
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {formatCurrency({ amount: 43.4 })}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Yearly Avg
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {formatCurrency({ amount: 2560 })}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "0.14rem", background: Colors.ACCENT_GRAY }}></Box>
        <Box
          sx={{
            flex: 5,
            display: "flex",
            flexDirection: "column",
            gap: "3.71rem",
          }}
        >
          <TopRightButton
            variation="Back"
            onClick={() => navigate(`/pos/${customerId}`)}
          />
          <Typography
            sx={{ fontWeight: "bold", fontSize: "2.57rem", lineHeight: "100%" }}
          >
            Customer Analysis Reports
          </Typography>
          <Box
            sx={{
              background: Colors.BACKGROUND_GRAY,
              borderRadius: "1.07rem",
              padding: "2rem 1.07rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "0.71rem",
              rowGap: "1.21rem",
            }}
          >
            <Box
              sx={{
                background: Colors.WHITE,
                borderRadius: "0.71rem",
                width: "45.71rem",
                maxHeight: "23.78rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Line options={options} data={lineChartData} />
            </Box>
            <Box
              sx={{
                background: Colors.WHITE,
                borderRadius: "0.71rem",
                width: "24.64rem",
                maxHeight: "23.78rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Radar data={radarChartData} />
            </Box>
            <Box
              sx={{
                background: Colors.WHITE,
                borderRadius: "0.71rem",
                width: "71.14rem",
                maxHeight: "34.35rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Line options={options} data={lineChartData} />
            </Box>
          </Box>
        </Box>
      </Box>
    </PosLayout>
  );
};

export default CustomerAnalysisScreen;
