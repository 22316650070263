import { FC } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";
import { Colors } from "../../../configs";

export interface ICustomRadioButtonProps {
  radioItems: {
    value: string | number;
    label: string;
  }[];
  defaultValue?: string | number;
  orientation?: "row" | "column";
  justify?: "flex-start" | "center" | "flex-end" | "space-between";
  onChange?: (e: any) => void;
}

const CustomRadioButton: FC<ICustomRadioButtonProps> = ({
  radioItems,
  defaultValue,
  orientation = "row",
  justify = "space-between",
  onChange = () => {},
}) => {
  return (
    <FormControl>
      <RadioGroup
        row
        sx={{
          display: "flex",
          flexDirection: orientation,
          justifyContent: justify,
          width: "28.85rem",
        }}
        {...(defaultValue ? { defaultValue: defaultValue } : {})}
        onChange={onChange}
      >
        {radioItems.map((radioItem, i) => {
          return (
            <FormControlLabel
              value={radioItem.value}
              control={<Radio />}
              label={
                <Typography
                  sx={{
                    fontSize: "1.14285714rem",
                    fontWeight: 500,
                    color: Colors.TEXT_GRAY_DARK,
                  }}
                >
                  {radioItem.label}
                </Typography>
              }
              key={i}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadioButton;
