import React, { FC, useEffect, useState } from "react";
import { AdminLayout } from "../../../components/layouts/admin-layout/admin-layout";
import { Box, IconButton, Typography } from "@mui/material";
import { CustomButton } from "../../../components";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { EditOutlined, PanoramaFishEyeOutlined } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteDiscount,
  getDiscountsByStoreId,
} from "../../../redux/actions/discountAction";
import CreateDiscountDrawer from "../../../components/admin/create-discount/CreateDiscountDrawer";
import { getCategoryAction } from "../../../redux/actions/pricingAction";
import { Colors } from "../../../configs";

export interface IDiscountsPageProps {}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DiscountsPage: FC<IDiscountsPageProps> = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { adminDashboardSelectedStore } = useAppSelector(
    (state) => state.master
  );
  const { getDiscountByStoreResponse } = useAppSelector(
    (state) => state.discount
  );
  const dispatch = useAppDispatch();

  const getAllDiscounts = () => {
    if (adminDashboardSelectedStore?.storeId)
      dispatch(getDiscountsByStoreId(adminDashboardSelectedStore?.storeId));
  };

  const getAllCategories = () => {
    dispatch(getCategoryAction());
  };

  const handleOnDrawerClose = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleOnClickDelete = (id: string) => {
    dispatch(deleteDiscount(id)).then(() => {
      getAllDiscounts();
    });
  };

  useEffect(() => {
    getAllDiscounts();
    getAllCategories();
  }, [adminDashboardSelectedStore?.storeId]);
  return (
    <AdminLayout>
      <Box
        sx={{
          height: "57.5rem",
          marginTop: "4.35714286rem",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <CreateDiscountDrawer
          open={openDrawer}
          toggleDrawer={handleOnDrawerClose}
        />
        <Box
          sx={{
            maxHeight: "57.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            paddingInline: "5rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" sx={{ color: Colors.TEXT_GRAY_DARK }}>
              Discounts
            </Typography>
            <CustomButton
              sx={{
                width: "11.0714286rem",
                height: "3.07142857rem",
              }}
              onClick={() => setOpenDrawer(true)}
            >
              Add New
            </CustomButton>
          </Box>
          <Box
            sx={{
              maxHeight: "51.42857143rem",
              width: "100%",
              overflowY: "auto",
              // make scrollbar style looks better
              "&::-webkit-scrollbar": {
                width: "0.5rem",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <Table sx={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Discount Name</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Mode</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell align="right">Minimum Bill</StyledTableCell>
                  <StyledTableCell align="right">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getDiscountByStoreResponse?.body.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.discountName}</StyledTableCell>
                    <StyledTableCell>{row.discountAmount}</StyledTableCell>
                    <StyledTableCell>{row.discountMode}</StyledTableCell>
                    <StyledTableCell>{row.description}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.minimumBillValue.toFixed(2)}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      <IconButton>
                        <EditOutlined
                          sx={{ fontSize: "2rem" }}
                          color="primary"
                        />
                      </IconButton>
                      <IconButton>
                        <PanoramaFishEyeOutlined
                          sx={{ fontSize: "2rem" }}
                          color="info"
                        />
                      </IconButton>
                      <IconButton onClick={() => handleOnClickDelete(row.id)}>
                        <DeleteOutlineOutlinedIcon
                          sx={{ fontSize: "2rem" }}
                          color="error"
                        />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default DiscountsPage;
