import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BaseResponse,
  CategoryRelationshipResponse,
  CategoryResponse,
  SecondSubCategoryResponse,
  SubcategoryResponse,
} from "../../typings/interfaces";
import {
  GET_ALL_ALTERATION_PRICE,
  GET_ALL_CATEGORIES,
  GET_ALL_PRICINGS,
  GET_ALL_SECOND_SUB_CATEGORIES,
  GET_ALL_SUB_CATEGORIES,
  GET_ALTERATION_SUB_CATEGORIES,
  GET_CATEGORY_RELATIONSHIP,
  GET_FABRIC_TYPES,
  GET_CATEGORY_MATERIAL_RELATIONSHIP,
  GET_SUB_CATEGORY_MATERIAL_RELATIONSHIP,
  UPLOAD_PRICE_LIST,
} from "../../configs";
import { PricingResponse } from "../../typings/interfaces/responses/pricing-response";
import { AlterationSubCategoryResponse } from "../../typings/interfaces/responses/alteration-sub-category-response";
import { AlterationPriceResponse } from "../../typings/interfaces/responses/alteration-price-response";
import { FabricTypeResponse } from "../../typings/interfaces/responses/fabricTypeResponse";
import { CategoryMaterialRelationshipResponse } from "../../typings/interfaces/responses/category-material-relationship";
import { SubCategoryMaterialRelationshipResponse } from "../../typings/interfaces/responses/sub-category-material-relationship";

export const getCategoryAction = createAsyncThunk(
  "pricing/category",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<CategoryResponse>>(
        GET_ALL_CATEGORIES
      );

      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const getSubCategoryAction = createAsyncThunk(
  "pricing/sub-category",
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<SubcategoryResponse>>(
        GET_ALL_SUB_CATEGORIES(id)
      );

      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const getSecondSubCategoryAction = createAsyncThunk(
  "pricing/second-sub-category",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<SecondSubCategoryResponse>>(
        GET_ALL_SECOND_SUB_CATEGORIES
      );

      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const getPricing = createAsyncThunk(
  "pricing/all",
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<PricingResponse>>(
        GET_ALL_PRICINGS(id)
      );

      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const getCategoryRelationship = createAsyncThunk(
  "pricing/category-relationship",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponse<CategoryRelationshipResponse>
      >(GET_CATEGORY_RELATIONSHIP);

      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const getAlterationSubCategories = createAsyncThunk(
  "pricing/alteration-sub-categories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponse<AlterationSubCategoryResponse>
      >(GET_ALTERATION_SUB_CATEGORIES);

      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const getFabricTypes = createAsyncThunk(
  "pricing/fabric-types",
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<FabricTypeResponse>>(
        GET_FABRIC_TYPES(id)
      );

      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const getAlterationPrices = createAsyncThunk(
  "pricing/alteration-prices",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<AlterationPriceResponse>>(
        GET_ALL_ALTERATION_PRICE
      );

      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const getCategoryMaterialRelationship = createAsyncThunk(
  "pricing/category-material-relationship",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponse<CategoryMaterialRelationshipResponse>
      >(GET_CATEGORY_MATERIAL_RELATIONSHIP);

      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const getSubCategoryMaterialRelationship = createAsyncThunk(
  "pricing/sub-category-material-relationship",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponse<SubCategoryMaterialRelationshipResponse>
      >(GET_SUB_CATEGORY_MATERIAL_RELATIONSHIP);

      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

// UPLOAD_PRICE_LIST is my endpoint. request body(form-data) includes only file(type is file)
export const uploadPriceList = createAsyncThunk(
  "pricing/upload-price-list",
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const response = await axios.post<BaseResponse<any>>(
        UPLOAD_PRICE_LIST,
        formData
      );

      return { response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);
