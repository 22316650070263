import {
  createTheme,
  Palette,
  PaletteOptions,
  responsiveFontSizes,
} from "@mui/material";
import { Colors } from "./colors";

declare module "@mui/material/styles" {
  interface Palette {
    custom: Palette["primary"];
    textHeader: Palette["primary"];
    adminMenuItem: Palette["primary"];
  }
  interface PaletteOptions {
    custom: PaletteOptions["primary"];
    textHeader: PaletteOptions["primary"];
    adminMenuItem: PaletteOptions["primary"];
  }
}

declare module "@mui/material/styles/createTheme" {
  interface Theme {
    custom: Palette["primary"];
    textHeader: Palette["primary"];
    adminMenuItem: Palette["primary"];
  }
  interface ThemeOptions {
    custom?: PaletteOptions["primary"];
    textHeader?: PaletteOptions["primary"];
  }
}

interface ThemeConfig {
  primary: string;
  secondary: string;
  panelGray: string;
  textHeader: string;
  adminMenuBG: string;
}

const themes: Record<string, ThemeConfig> = {
  presto: {
    primary: "#018752",
    secondary: "#0079C2",
    panelGray: "#F8F8F8",
    textHeader: "#4D4D4D",
    adminMenuBG: "#f7fffc",
  },
  cleanx: {
    primary: "#00008B",
    secondary: "#ff9800",
    panelGray: "#F8F8F8",
    textHeader: "#4D4D4D",
    adminMenuBG: "#f7f7ff",
  },
  admin: {
    primary: "#5EC46C",
    secondary: "#FFC04A",
    panelGray: "#F8F8F8",
    textHeader: "#4D4D4D",
    adminMenuBG: "#f7f7ff",
  },
  // Add more tenant themes here
};

export function getThemeForTenant(tenantId: string) {
  const themeConfig = themes[tenantId];

  let theme = createTheme({
    palette: {
      primary: {
        main: themeConfig?.primary || Colors.PRIMARY,
      },
      secondary: {
        main: themeConfig?.secondary || Colors.SECONDARY,
      },
      custom: {
        main: themeConfig?.panelGray || Colors.BACKGROUND_GRAY,
      },
      textHeader: {
        main: themeConfig?.textHeader || Colors.TEXT_GRAY_DARK,
      },
      adminMenuItem: {
        main: themeConfig?.adminMenuBG || Colors.TEXT_GRAY_DARK,
      },
    },
    typography: {
      h4: {
        fontSize: "3rem",
      },
      body2: {
        fontSize: "1.2rem",
      },
    },
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            fontFamily: "Arial",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeConfig?.secondary || Colors.SECONDARY,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeConfig?.secondary || Colors.SECONDARY,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: "2.857rem",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            width: "32px", // Adjust the width as needed
            height: "32px", // Adjust the height as needed
          },
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);

  return theme;
}
