import { createSlice } from "@reduxjs/toolkit";
import {AllRackBillsResponse, SingleRackResponse} from "../../typings/interfaces/responses/rack-response";
import { addRackAction, getAllRackBillsAction, getAllRacksAction, registerRackBillAction } from "../actions/rackAction";
// import { IResponseType } from "../../pages/admin/config/rack-config/rackConfig";
import { BaseResponse, BaseResponseSingle } from "../../typings/interfaces/responses";

export interface RackState{
    addRacks: BaseResponseSingle<SingleRackResponse> | null;
    addRacksLoading: boolean;
    racksError: string | null;

    getRacks: BaseResponse<SingleRackResponse> | null;
    getRacksLoading: boolean;
    getRacksError: string | null;

    registerRackBill: BaseResponseSingle<SingleRackResponse> | null;
    registerRackBillLoading: boolean;
    registerRackBillError: string | null;

    getAllRackBills: BaseResponse<AllRackBillsResponse> | null;
    getAllRackBillsLoading: boolean;
    getAllRackBillsError: string | null;
}

const initialState: RackState = {
    addRacks: null,
    addRacksLoading: false,
    racksError: null,

    getRacks: null,
    getRacksLoading: false,
    getRacksError: null,

    registerRackBill: null,
    registerRackBillLoading: false,
    registerRackBillError: null,

    getAllRackBills: null,
    getAllRackBillsLoading: false,
    getAllRackBillsError: null,
}


const rackSlice = createSlice({
    name: "rack",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(addRackAction.pending, (state) => {
                state.addRacks = null;
                state.addRacksLoading = true;
                state.racksError = null;
            })
            .addCase(addRackAction.fulfilled, (state, action) => {
                state.racksError = null;
                state.addRacksLoading = false;
                state.addRacks = (action.payload as any).body;
            })
            .addCase(addRackAction.rejected, (state, action) => {
                state.addRacks = null;
                state.addRacksLoading = false;
                state.racksError = (action.payload as any).message;
            })

            .addCase(getAllRacksAction.pending, (state) => {
                state.getRacks = null;
                state.getRacksLoading = true;
                state.getRacksError = null;
            })
            .addCase(getAllRacksAction.fulfilled, (state, action) => {
                state.getRacksError = null;
                state.getRacksLoading = false;
                state.getRacks = (action.payload as any).body;
            })
            .addCase(getAllRacksAction.rejected, (state, action) => {
                state.getRacks = null;
                state.getRacksLoading = false;
                state.getRacksError = (action.payload as any).message;
            })

            .addCase(registerRackBillAction.pending, (state) => {
                state.registerRackBill = null;
                state.registerRackBillLoading = true;
                state.registerRackBillError = null;
            })
            .addCase(registerRackBillAction.fulfilled, (state, action) => {
                state.registerRackBillError = null;
                state.registerRackBillLoading = false;
                state.registerRackBill = (action.payload as any).body;
            })
            .addCase(registerRackBillAction.rejected, (state, action) => {
                state.registerRackBill = null;
                state.registerRackBillLoading = false;
                state.registerRackBillError = (action.payload as any).message;
            })

            .addCase(getAllRackBillsAction.pending, (state) => {
                state.getAllRackBills = null;
                state.getAllRackBillsLoading = true;
                state.getAllRackBillsError = null;
            })
            .addCase(getAllRackBillsAction.fulfilled, (state, action) => {
                state.getAllRackBillsError = null;
                state.getAllRackBillsLoading = false;
                state.getAllRackBills = (action.payload as any).body;
            })
            .addCase(getAllRackBillsAction.rejected, (state, action) => {
                state.getAllRackBills = null;
                state.getAllRackBillsLoading = false;
                state.getAllRackBillsError = (action.payload as any).message;
            })
    }
});

export default rackSlice.reducer;
export const rackSliceActions = rackSlice.actions;