import { Box, TextField, Typography } from "@mui/material";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { Colors } from "../../../../configs";
import { FC, useEffect, useState } from "react";
import { CustomTextBox } from "../../../atoms";

interface IMonthlySectionProps {
  billValue: number;
  returnPaidAmount: (amount: number) => void;
}

const MonthlySection: FC<IMonthlySectionProps> = ({
  billValue,
  returnPaidAmount,
}) => {
  const { customerCartInfo } = useAppSelector((state) => state.customer);
  const [customerOutstanding, setCustomerOutstanding] = useState<number>(0);

  useEffect(() => {
    returnPaidAmount(billValue);
  }, [billValue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.48rem",
        height: "23.8571429rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "2.85714286rem",
          fontWeight: "bold",
          color: Colors.TEXT_GRAY_DARK,
        }}
      >
        Monthly Payment
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.714285714rem",
          width: "84.9285714rem",
          height: "18.0714286rem",
          background: Colors.BACKGROUND_GRAY,
          borderRadius: "0.357142857rem",
          px: "2.95392857rem",
          py: "1.7rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.71428571rem",
            color: Colors.TEXT_GRAY_DARK,
            fontWeight: "bold",
            marginBottom: "0.45rem",
          }}
        >
          Payment Information
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: Colors.MAIN_GRAY,
              fontSize: "1.42857143rem",
              fontWeight: 500,
            }}
          >
            Bill Value
          </Typography>
          <CustomTextBox
            size="small"
            styles={{
              /*   width: "17.2142857rem !important",*/
              width: "62.5714286rem",
              minHeight: "3rem",
              height: "3rem",
              maxHeight: "3rem",
              borderRadius: "0.357142857rem",
              background: Colors.ACCENT_GRAY,
              color: Colors.ACCENT_GRAY_DISABLED,
              border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              boxShadow: "none !important",
              padding: "0",
              paddingRight: "0.987857143rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "right",
              fontSize: "1.57142857rem",
              margin: "0 !important",
              fontWeight: 500,
              boxSizing: "border-box",
            }}
            value={billValue}
            type="currency"
            disabled
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: Colors.MAIN_GRAY,
              fontSize: "1.42857143rem",
              fontWeight: 500,
            }}
          >
            Current Outstanding
          </Typography>
          <CustomTextBox
            size="small"
            styles={{
              /*   width: "17.2142857rem !important",*/
              width: "62.5714286rem",
              minHeight: "3rem",
              height: "3rem",
              maxHeight: "3rem",
              borderRadius: "0.357142857rem",
              background: Colors.ACCENT_GRAY,
              color: Colors.ACCENT_GRAY_DISABLED,
              border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              boxShadow: "none !important",
              padding: "0",
              paddingRight: "0.987857143rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "right",
              fontSize: "1.57142857rem",
              margin: "0 !important",
              fontWeight: 500,
              boxSizing: "border-box",
            }}
            value={customerOutstanding}
            type="currency"
            disabled
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: Colors.MAIN_GRAY,
              fontSize: "1.42857143rem",
              fontWeight: 500,
            }}
          >
            New Outstanding
          </Typography>
          <CustomTextBox
            size="small"
            styles={{
              /*   width: "17.2142857rem !important",*/
              width: "62.5714286rem",
              minHeight: "3rem",
              height: "3rem",
              maxHeight: "3rem",
              borderRadius: "0.357142857rem",
              background: Colors.ACCENT_GRAY,
              color: Colors.ACCENT_GRAY_DISABLED,
              border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
              boxShadow: "none !important",
              padding: "0",
              paddingRight: "0.987857143rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "right",
              fontSize: "1.57142857rem",
              margin: "0 !important",
              fontWeight: 500,
              boxSizing: "border-box",
            }}
            value={customerOutstanding + billValue}
            type="currency"
            disabled
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MonthlySection;
