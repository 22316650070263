import { Badge, IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { FC } from "react";

export interface INotificationButtonProps {
  onClick(): void;
}

const NotificationButton: FC<INotificationButtonProps> = ({onClick}) => {
  return (
    <IconButton color="inherit" onClick={onClick}>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        variant="dot"
        color="error"
      >
        <NotificationsIcon sx={{color: "textHeader.main"}}/>
      </Badge>
    </IconButton>
  );
};

export default NotificationButton;
