import { createSlice } from "@reduxjs/toolkit";
import {
  IMerchantResponse,
  IStoreResponse,
} from "../../typings/interfaces/responses/all-merchants-response";

export interface MasterState {
  adminDashboardSelectedStore: IStoreResponse | null;
  adminDashboardSelectedMerchant: IMerchantResponse | null;
  storeSelectorDrawerOpen: boolean;
}

const initialState: MasterState = {
  adminDashboardSelectedStore: null,
  adminDashboardSelectedMerchant: null,
  storeSelectorDrawerOpen: false,
};

const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    setAdminDashboardSelectedStore: (state, action) => {
      state.adminDashboardSelectedStore = action.payload;
    },
    setAdminDashboardSelectedMerchant: (state, action) => {
      state.adminDashboardSelectedMerchant = action.payload;
    },
    setStoreSelectorDrawerOpen: (state, action) => {
      state.storeSelectorDrawerOpen = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export default masterSlice.reducer;
export const MasterSliceActions = masterSlice.actions;
