import { FC } from "react"

export interface IInsightProps{

}

const Insight:FC<IInsightProps>=({})=>{
    return <></>
}

export default Insight;