import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BaseResponse,
  BaseResponseSingle,
  IMerchantDetailsResponse,
  IStoreDetailsResponse,
} from "../../typings";
import {
  GET_ALL_MERCHANTS,
  GET_MERCHANT_DETAILS,
  GET_MERCHANT_STORES,
} from "../../configs";
import { IMerchantResponse } from "../../typings/interfaces/responses/all-merchants-response";

export const getMerchantDetails = createAsyncThunk(
  "merchant/getById",
  async (whiteLabel: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponseSingle<IMerchantDetailsResponse>
      >(GET_MERCHANT_DETAILS(whiteLabel));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMerchantStores = createAsyncThunk(
  "merchant/getStoresList",
  async (whiteLabel: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<IStoreDetailsResponse>>(
        GET_MERCHANT_STORES(whiteLabel)
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllMerchants = createAsyncThunk(
  "merchant/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponseSingle<Array<IMerchantResponse>>
      >(GET_ALL_MERCHANTS);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
