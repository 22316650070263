import {
  Printer,
  Text,
  Image,
  Br,
  Row,
  Line,
  Barcode,
  Cut,
} from "react-thermal-printer";
import {
  formatDateNow,
  formatUS12HourTime,
  formatUSDate,
  padText,
} from "../util/common-functions";
import { Translation } from "./translation";
import { ICustomerResponse } from "../typings";
import { IPaymentModalData } from "../components/pos/payment-modal/checkinSummary";
import { ICartSession } from "../typings/interfaces/pos/cart-session";
import { CartItem } from "../typings/interfaces/pos/cart-item";
import { tempConfig } from "./tempConfig";
import { IHalfPayment } from "../typings/interfaces/payment/halfPayment";

export interface IBillCustomerCopy {
  customer: ICustomerResponse | null;
  translation: Translation;
  paymentData: IHalfPayment;
  session: ICartSession | null;
  discount?: number;
  dropOffDate: Date;
  pickUpDate: Date;
  service?: string;
  dropOffTime: Date;
  pickUpTime: Date;
  special: boolean;
}

const getPrice = (item: CartItem, specialPickup: boolean) => {
  let total =
    item.unitPrice * item.qty +
    item.alterationItems.reduce(
      (acc, alterationItem) => acc + (alterationItem.unitPrice || 0),
      0
    ) +
    item.upcharges.reduce(
      (acc, upcharge) => acc + (upcharge.subUpcharge.price || 0),
      0
    ) +
    item.spots.reduce((acc, spot) => acc + (spot.subSpot.price || 0), 0);
  specialPickup && (total += total * tempConfig.specialPickupRate);
  return total;
};
export const getCustomerBillTemplate = ({
  translation,
  customer,
  paymentData,
  session,
  dropOffDate,
  pickUpDate,
  discount = 0.0,
  service = "Undefined",
  dropOffTime,
  pickUpTime,
  special,
}: IBillCustomerCopy) => {
  const subTotal =
    session?.cartItems.reduce(
      (acc, item) => acc + getPrice(item, special),
      0
    ) || 0;
  const total = subTotal - discount;
  return (
    <Printer type="epson" width={48}>
      <Image align="center" src={"/images/presto-login-logo.png"} />
      <Text bold={true} align="center" size={{ width: 2, height: 2 }}>
        {translation.companyName}
      </Text>
      <Text bold={true} align="center">
        Certified Environmental Dry Clearner
      </Text>
      <Br />
      <Text align="center">{translation.companyAddress}</Text>
      <Text align="center">T.P: {translation.companyPhone}</Text>
      <Text align="center">{translation.companyEmail}</Text>
      <Br />
      <Br />

      {/* Invoice Details */}
      <Row
        left={
          <Text bold={true} size={{ width: 1, height: 2 }}>
            {service}
          </Text>
        }
        right={
          <Text bold={true} size={{ width: 1, height: 2 }}>
            {paymentData?.billId}
          </Text>
        }
      />
      <Br />
      <Text>
        {padText("Drop Off", 10)}: {formatUSDate(dropOffDate)}{" "}
        {formatUS12HourTime(dropOffTime)}
      </Text>
      <Text>
        {padText("Pick Up", 10)}: {formatUSDate(pickUpDate)}{" "}
        {formatUS12HourTime(pickUpTime)}
      </Text>
      <Text>
        {padText("Customer", 10)}: {customer?.firstName}{" "}
        {customer?.middleName?.charAt(0)} {customer?.lastName}
      </Text>
      <Text>
        {padText("CustId", 10)}: {customer?.id}
      </Text>
      {/* --------------- */}
      <Br />
      <Row
        gap={1}
        right={<Text bold={true}>Price</Text>}
        left={<Text bold={true}>{padText("Qty", 5)}Description</Text>}
      />
      <Line character="-" />
      {session?.cartItems.map((item) => (
        <>
          <Row
            gap={1}
            left={
              <Text>
                {padText(item.qty, 5)}
                {item.garmentType?.name}-{item.materialType?.name}-
                {item.garmentStyle?.name}
              </Text>
            }
            // right={<Text>{(item.unitPrice * item.qty).toFixed(2)}</Text>}
            right={<Text>{getPrice(item, special).toFixed(2)}</Text>}
          />
          {item.alterationItems.length > 0 && (
            <>
              {item.alterationItems.map((alterationItem, index) => (
                <>
                  {index == 0 && (
                    <Row
                      gap={1}
                      left={
                        <Text bold={true}>
                          {padText("", 5)}
                          Alterations:
                        </Text>
                      }
                      right=""
                    />
                  )}
                  <Row
                    gap={1}
                    left={
                      <Text>
                        {padText("", 5)}
                        {alterationItem.alterationSubCategory?.name}
                      </Text>
                    }
                    right=""
                  />
                  {index + 1 === item.alterationItems.length && (
                    <Row left="" right="" />
                  )}
                </>
              ))}
            </>
          )}
          {item.upcharges.length > 0 && (
            <>
              {item.upcharges.map((upcharge, index) => (
                <>
                  {index == 0 && (
                    <Row
                      gap={1}
                      left={
                        <Text bold={true}>
                          {padText("", 5)}
                          Upcharges:
                        </Text>
                      }
                      right=""
                    />
                  )}
                  <Row
                    gap={1}
                    left={
                      <Text>
                        {padText("", 5)}
                        {upcharge.label} ({upcharge.subUpcharge.label})
                      </Text>
                    }
                    right=""
                  />
                  {index + 1 === item.upcharges.length && (
                    <Row left="" right="" />
                  )}
                </>
              ))}
            </>
          )}
          {item.spots.length > 0 && (
            <>
              {item.spots.map((spot, index) => (
                <>
                  {index == 0 && (
                    <Row
                      gap={1}
                      left={
                        <Text bold={true}>
                          {padText("", 5)}
                          Spots:
                        </Text>
                      }
                      right=""
                    />
                  )}
                  <Row
                    gap={1}
                    left={
                      <Text>
                        {padText("", 5)}
                        {spot.label}{" "}
                        {(spot.subSpot.comment?.length || 0) > 0
                          ? `(${spot.subSpot.comment})`
                          : ""}
                      </Text>
                    }
                    right=""
                  />
                  {index + 1 === item.spots.length && <Row left="" right="" />}
                </>
              ))}
            </>
          )}
          {item.instructions.length > 0 && (
            <>
              <Row
                gap={1}
                left={<Text bold={true}>{padText("", 5)}Instructions: </Text>}
                right={<Text>{padText("", 5)}</Text>}
              />
              <Row
                gap={1}
                left={
                  <Text>
                    {padText("", 5)}
                    {item.instructions
                      .map((instruction) => instruction.label)
                      .join(",")}
                  </Text>
                }
                right={<Text>{padText("", 5)}</Text>}
              />
            </>
          )}
          {item.damages.length > 0 && (
            <>
              <Row
                gap={1}
                left={<Text bold={true}>{padText("", 5)}damages: </Text>}
                right={<Text>{padText("", 5)}</Text>}
              />
              <Row
                gap={1}
                left={
                  <Text>
                    {padText("", 5)}
                    {item.damages.map((damage) => damage.label).join(",")}
                  </Text>
                }
                right={<Text>{padText("", 5)}</Text>}
              />
            </>
          )}
        </>
      ))}
      <Line character="-" />
      <Row
        left={<Text bold={true}>{padText("Sub Total", 16)}:</Text>}
        right={`${subTotal}`}
      />
      <Row
        left={<Text bold={true}>{padText("Discount", 16)}:</Text>}
        right={`${discount}`}
      />
      <Row
        left={<Text bold={true}>{padText("Total", 16)}:</Text>}
        right={`${total}`}
      />
      {
        paymentData.payments.map((payment) => (
          <Row
            left={<Text bold={true}>{padText(payment.paymentType, 16)}:</Text>}
            right={<Text>{(payment.amount - 0).toFixed(2)}</Text>}
          />
        ))
      }
      
      {/* {data.paymentMode.includes("CASH") && (
        <Row
          left={<Text bold={true}>{padText("Balance", 16)}:</Text>}
          right={(data.paidAmount - Number(total)).toFixed(2)}
        />
      )} */}

      <Line character="-" />
      <Text align={"center"}>Your threads have been transformed</Text>
      <Text align={"center"}>with a dash of magic!</Text>
      <Br />
      <Barcode
        align="center"
        type="CODE39"
        content={"11000" + paymentData?.billId}
        height={80}
      />
      <Text size={{ height: 1, width: 1 }}> </Text>
      <Text align="center" bold={true} size={{ width: 2, height: 1 }}>
        {paymentData?.billId}
      </Text>
      <Br />
      <Text align={"center"}>Any discrepancy of the items or</Text>
      <Text align={"center"}>cleaning must be reported within 72 hours.</Text>
      <Br />
      <Text align={"center"}>www.drycleanx.com</Text>
      <Cut />
    </Printer>
  );
};

export const getStoreBillTemplate = ({
  translation,
  customer,
  paymentData,
  session,
  discount = 0.0,
  dropOffDate,
  pickUpDate,
  service = "Undefined",
  dropOffTime,
  pickUpTime,
  special,
}: IBillCustomerCopy) => {
  const subTotal =
    session?.cartItems.reduce(
      (acc, item) => acc + getPrice(item, special),
      0
    ) || 0;
  const total = subTotal - discount;
  return (
    <Printer type="epson" width={48}>
      <Text bold={true} align="center" size={{ width: 2, height: 2 }}>
        {translation.companyName}
      </Text>
      <Text bold={true} align="center">
        Certified Environmental Dry Clearner
      </Text>
      <Br />
      <Text align="center" size={{ width: 4, height: 4 }} bold={true}>
        {paymentData?.billId}
      </Text>
      <Text bold={true} align="center" size={{ width: 1, height: 2 }}>
        {service}
      </Text>
      <Br />
      <Br />
      <Text>
        {padText("Drop Off", 10)}: {formatUSDate(dropOffDate)}{" "}
        {formatUS12HourTime(dropOffTime)}
      </Text>
      <Text>
        {padText("Pick Up", 10)}: {formatUSDate(pickUpDate)}{" "}
        {formatUS12HourTime(pickUpTime)}
      </Text>
      <Text>
        {padText("Customer", 10)}: {customer?.firstName}{" "}
        {customer?.middleName?.charAt(0)} {customer?.lastName}
      </Text>
      <Text>
        {padText("CustId", 10)}: {customer?.id}
      </Text>
      {/* --------------- */}
      <Br />
      <Row
        gap={1}
        right={<Text bold={true}>Price</Text>}
        left={<Text bold={true}>{padText("Qty", 5)}Description</Text>}
      />
      <Line character="-" />
      {session?.cartItems.map((item) => (
        <>
          <Row
            gap={1}
            left={
              <Text>
                {padText(item.qty, 5)}
                {item.garmentType?.name}-{item.materialType?.name}-
                {item.garmentStyle?.name}
              </Text>
            }
            // right={<Text>{(item.unitPrice * item.qty).toFixed(2)}</Text>}
            right={<Text>{getPrice(item, special).toFixed(2)}</Text>}
          />
          {item.alterationItems.length > 0 && (
            <>
              {item.alterationItems.map((alterationItem, index) => (
                <>
                  {index == 0 && (
                    <Row
                      gap={1}
                      left={
                        <Text bold={true}>
                          {padText("", 5)}
                          Alterations:
                        </Text>
                      }
                      right=""
                    />
                  )}
                  <Row
                    gap={1}
                    left={
                      <Text>
                        {padText("", 5)}
                        {alterationItem.alterationSubCategory?.name}
                      </Text>
                    }
                    right=""
                  />
                  {index + 1 === item.alterationItems.length && (
                    <Row left="" right="" />
                  )}
                </>
              ))}
            </>
          )}
          {item.upcharges.length > 0 && (
            <>
              {item.upcharges.map((upcharge, index) => (
                <>
                  {index == 0 && (
                    <Row
                      gap={1}
                      left={
                        <Text bold={true}>
                          {padText("", 5)}
                          Upcharges:
                        </Text>
                      }
                      right=""
                    />
                  )}
                  <Row
                    gap={1}
                    left={
                      <Text>
                        {padText("", 5)}
                        {upcharge.label} ({upcharge.subUpcharge.label})
                      </Text>
                    }
                    right=""
                  />
                  {index + 1 === item.upcharges.length && (
                    <Row left="" right="" />
                  )}
                </>
              ))}
            </>
          )}
          {item.spots.length > 0 && (
            <>
              {item.spots.map((spot, index) => (
                <>
                  {index == 0 && (
                    <Row
                      gap={1}
                      left={
                        <Text bold={true}>
                          {padText("", 5)}
                          Spots:
                        </Text>
                      }
                      right=""
                    />
                  )}
                  <Row
                    gap={1}
                    left={
                      <Text>
                        {padText("", 5)}
                        {spot.label}{" "}
                        {(spot.subSpot.comment?.length || 0) > 0
                          ? `(${spot.subSpot.comment})`
                          : ""}
                      </Text>
                    }
                    right=""
                  />
                  {index + 1 === item.spots.length && <Row left="" right="" />}
                </>
              ))}
            </>
          )}
          {item.instructions.length > 0 && (
            <>
              <Row
                gap={1}
                left={<Text bold={true}>{padText("", 5)}Instructions: </Text>}
                right={<Text>{padText("", 5)}</Text>}
              />
              <Row
                gap={1}
                left={
                  <Text>
                    {padText("", 5)}
                    {item.instructions
                      .map((instruction) => instruction.label)
                      .join(",")}
                  </Text>
                }
                right={<Text>{padText("", 5)}</Text>}
              />
            </>
          )}
          {item.damages.length > 0 && (
            <>
              <Row
                gap={1}
                left={<Text bold={true}>{padText("", 5)}damages: </Text>}
                right={<Text>{padText("", 5)}</Text>}
              />
              <Row
                gap={1}
                left={
                  <Text>
                    {padText("", 5)}
                    {item.damages.map((damage) => damage.label).join(",")}
                  </Text>
                }
                right={<Text>{padText("", 5)}</Text>}
              />
            </>
          )}
        </>
      ))}
      <Line character="-" />
      <Row
        left={<Text bold={true}>{padText("Sub Total", 16)}:</Text>}
        right={`${subTotal}`}
      />
      <Row
        left={<Text bold={true}>{padText("Discount", 16)}:</Text>}
        right={`${discount}`}
      />
      <Row
        left={<Text bold={true}>{padText("Total", 16)}:</Text>}
        right={`${total}`}
      />
      {
        paymentData.payments.map((payment) => (
          <Row
            left={<Text bold={true}>{padText(payment.paymentType, 16)}:</Text>}
            right={<Text>{(payment.amount - 0).toFixed(2)}</Text>}
          />
        ))
      }
      {/* {data.paymentMode.includes("CASH") && (
        <Row
          left={<Text bold={true}>{padText("Balance", 16)}:</Text>}
          right={(data.paidAmount - Number(total)).toFixed(2)}
        />
      )} */}

      <Line character="-" />
      <Br />
      <Barcode
        align="center"
        type="CODE39"
        content={"11000" + paymentData?.billId}
        height={80}
      />
      <Text size={{ height: 1, width: 1 }}> </Text>
      <Text align="center" bold={true} size={{ width: 2, height: 1 }}>
        {paymentData?.billId}
      </Text>
      <Br />
      <Text align={"center"}>www.drycleanx.com</Text>
      <Text align={"center"}>T.P: {translation.companyPhone}</Text>
      <Cut />
    </Printer>
  );
};
