import { FC, useEffect } from "react";
import CustomModal from "../../common/custom-modal/custom-modal";
import { Panel } from "../../common/panel/panel";
import { CircularProgress, Modal, Typography } from "@mui/material";
import { useAppDispatch } from "../../../hooks";
import { getBillByIdAction } from "../../../redux/actions/cartAction";
import { useNavigate } from "react-router-dom";

export interface ILoaderModalProps {
  open: boolean;
  billId?: string;
  onClose?(): void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 4,
  py: 2,
};

const LoaderModal: FC<ILoaderModalProps> = ({ open, billId, onClose }) => {
  // Remove this after did the demo
  const dispatch = useAppDispatch();
  const navigator = useNavigate();

  useEffect(() => {
    if (billId) {
      dispatch(getBillByIdAction({ billId: billId })).then((res) => {
        const payload: any = res.payload;
        console.log("Loaded: ", payload)
        onClose && onClose();
        navigator(`/pos/customer/${payload.customerId}/history?billId=${billId}`);
      });
    }
  }, [billId]);

  return (
    <Modal open={open}>
      <Panel
        sx={{
          width: "120px",
          height: "120px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...style,
        }}
      >
        <CircularProgress />
      </Panel>
    </Modal>
  );
};

export default LoaderModal;
