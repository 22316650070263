import { Permission } from "../typings";

const MainFeatures = [
  {
    title: "Drop Off",
    link: "/",
    Permission: [Permission.DROP_OFF],
  },
  {
    title: "Pickup",
    link: "/pickup",
    Permission: [Permission.PICK_UP],
  },
  {
    title: "Edit Invoice",
    link: "/",
    Permission: [Permission.EDIT_INVOICE],
  },
  {
    title: "Reprint Invoice",
    link: "/",
    Permission: [Permission.REPRINT_INVOICE],
  },
  {
    title: "Reprint Tag",
    link: "/",
    Permission: [Permission.REPRINT_TAG],
  },
  {
    title: "Void Invoice",
    link: "/",
    Permission: [Permission.VOID_INVOICE],
  },
  {
    title: "Conveyor Input",
    link: "/",
    Permission: [Permission.CONVEYOR_INPUT],
  },
  {
    title: "Cash Register",
    link: "/",
    Permission: [Permission.CASH_REGISTER],
  },
  {
    title: "Reports",
    link: "/",
    Permission: [Permission.REPORTS],
  },
  {
    title: "Search",
    link: "/",
    Permission: [Permission.SEARCH],
  },
  {
    title: "Retail Sale",
    link: "/",
    Permission: [Permission.RETAIL_SALE],
  },
  {
    title: "Price List",
    link: "/",
    Permission: [Permission.PRICE_LIST],
  },
  {
    title: "Pickup & Delivery",
    link: "/",
    Permission: [Permission.PICKUP_AND_DELIVERY],
  },
  {
    title: "Daily Snapshot",
    link: "/",
    Permission: [Permission.DAILY_SNAPSHOT],
  },
  {
    title: "Settings",
    link: "/",
    Permission: [Permission.SETTINGS],
  },
];

export default MainFeatures;
