interface IPreferenceShortCodes {
  [key: string]: string;
  NONE: string;
  LIGHT: string;
  MEDIUM: string;
  HEAVY: string;
  HANGER: string;
  BOXED: string;
  REGULAR: string;
  SPECIAL: string;
  CREASE: string;
  NO_CREASE: string;
}

const PreferenceShortCodes: IPreferenceShortCodes = {
  NONE: "N/S",
  LIGHT: "L/S",
  MEDIUM: "M/S",
  HEAVY: "H/S",

  HANGER: "HG",
  BOXED: "BO",

  REGULAR: "RE",
  SPECIAL: "SP",

  CREASE: "CC",
  NO_CREASE: "NC",
};

export default PreferenceShortCodes;
