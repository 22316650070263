import { createSlice } from "@reduxjs/toolkit";
import {
  BaseResponse,
  BaseResponseSingle,
  IMerchantDetailsResponse,
  IStoreDetailsResponse,
} from "../../typings";
// import { createDiscount } from "../actions/discountAction";
import {
  getAllMerchants,
  getMerchantDetails,
  getMerchantStores,
} from "../actions/merchantAction";
import { IMerchantResponse } from "../../typings/interfaces/responses/all-merchants-response";

export interface MerchantState {
  merchantDetailsResponse: BaseResponseSingle<IMerchantDetailsResponse> | null;
  merchantDetailsLoading: boolean;
  merchantDetailsError: string | null;

  merchantStoresResponse: BaseResponse<IStoreDetailsResponse> | null;
  merchantStoresLoading: boolean;
  merchantStoresError: string | null;

  merchantListResponse: BaseResponseSingle<Array<IMerchantResponse>> | null;
  merchantListLoading: boolean;
  merchantListError: string | null;
}

const initialState: MerchantState = {
  merchantDetailsResponse: null,
  merchantDetailsLoading: false,
  merchantDetailsError: null,

  merchantStoresResponse: null,
  merchantStoresLoading: false,
  merchantStoresError: null,

  merchantListResponse: null,
  merchantListLoading: false,
  merchantListError: null,
};

const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    clearMerchantDetails: (state: MerchantState) => {
      state.merchantDetailsResponse = null;
      state.merchantDetailsError = null;
      state.merchantDetailsLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMerchantDetails.rejected, (state: MerchantState) => {
      state.merchantDetailsResponse = null;
      state.merchantDetailsError = "Error creating discount";
      state.merchantDetailsLoading = false;
    });
    builder.addCase(getMerchantDetails.pending, (state: MerchantState) => {
      state.merchantDetailsResponse = null;
      state.merchantDetailsError = null;
      state.merchantDetailsLoading = true;
    });
    builder.addCase(
      getMerchantDetails.fulfilled,
      (state: MerchantState, action) => {
        state.merchantDetailsResponse = action.payload;
        state.merchantDetailsError = null;
        state.merchantDetailsLoading = false;
      }
    );

    builder.addCase(getMerchantStores.rejected, (state: MerchantState) => {
      state.merchantStoresResponse = null;
      state.merchantStoresError = "Error creating discount";
      state.merchantStoresLoading = false;
    });
    builder.addCase(getMerchantStores.pending, (state: MerchantState) => {
      state.merchantStoresResponse = null;
      state.merchantStoresError = null;
      state.merchantStoresLoading = true;
    });
    builder.addCase(
      getMerchantStores.fulfilled,
      (state: MerchantState, action) => {
        state.merchantStoresResponse = action.payload;
        state.merchantStoresError = null;
        state.merchantStoresLoading = false;
      }
    );

    builder.addCase(getAllMerchants.rejected, (state: MerchantState) => {
      state.merchantListResponse = null;
      state.merchantListError = "Error creating discount";
      state.merchantListLoading = false;
    });
    builder.addCase(getAllMerchants.pending, (state: MerchantState) => {
      state.merchantListResponse = null;
      state.merchantListError = null;
      state.merchantListLoading = true;
    });
    builder.addCase(
      getAllMerchants.fulfilled,
      (state: MerchantState, action) => {
        state.merchantListResponse = action.payload;
        state.merchantListError = null;
        state.merchantListLoading = false;
      }
    );
  },
});

export default merchantSlice.reducer;
export const MerchantSliceAction = merchantSlice.actions;
