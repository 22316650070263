import { Accordion, Box, Grid, ListItem } from "@mui/material";
import { FC } from "react";
import { HeaderTitle } from "../../atoms/header-title/header-title";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Collapsible from "../../atoms/collapsible/Collapsible";
import {
  IMerchantResponse,
  IStoreResponse,
} from "../../../typings/interfaces/responses/all-merchants-response";
import { MasterSliceActions } from "../../../redux/slice/masterSlice";
import { PanoramaFishEyeOutlined } from "@mui/icons-material";

export const StoreSelectorDrawerContent: FC = () => {
  const { merchantListResponse } = useAppSelector((state) => state.merchant);
  const dispatch = useAppDispatch();

  const setSelectedStoreAndMerchant = (
    store: IStoreResponse,
    merchant: IMerchantResponse
  ) => {
    dispatch(MasterSliceActions.setAdminDashboardSelectedStore(store));
    dispatch(MasterSliceActions.setAdminDashboardSelectedMerchant(merchant));
    dispatch(MasterSliceActions.setStoreSelectorDrawerOpen(false));
  };

  return (
    <Grid sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <HeaderTitle variation="panel_header" title="Stores" />
      </Box>
      <Box>
        {merchantListResponse?.body.map((merchant) => {
          return (
            <Collapsible title={merchant.companyName} defaultState={true}>
              {merchant.stores.map((store) => {
                return (
                  <ListItem
                    key={store.storeId}
                    onClick={() => setSelectedStoreAndMerchant(store, merchant)}
                    sx={{ cursor: "pointer" }}
                  >
                    <PanoramaFishEyeOutlined
                      sx={{ fontSize: "2rem", mr: "8px" }}
                      color="info"
                    />
                    <HeaderTitle
                      variation="field_label"
                      title={store.storeName}
                    />
                  </ListItem>
                );
              })}
            </Collapsible>

            // <Box key={merchant.merchantId}>
            //   <HeaderTitle variation="field_label" title={merchant.companyName} />
            //   {
            //     merchant.stores.map((store) => {
            //       return (
            //         <Box key={store.storeId}>
            //           <HeaderTitle variation="field_label" title={store.storeName} />
            //         </Box>
            //       );
            //     })
            //   }
            // </Box>
          );
        })}
      </Box>
    </Grid>
  );
};
