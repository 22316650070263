import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseResponse, AllRacksResponse, addRackBody, RackBillRegisterBody, AllRackBillsResponse } from "../../typings/interfaces";
import axios from "axios";
import { ADD_RACK, GET_ALL_RACKS, GET_ALL_RACK_BILL, REGISTER_RACK_BILL } from "../../configs/apiConfig";

export const getAllRacksAction = createAsyncThunk(
    "rack/all",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get<BaseResponse<AllRacksResponse>>(
            GET_ALL_RACKS
            );
    
            return { response: response.data };
        }
        catch (err) {
            if (!err) {
                throw err;
            }
            return rejectWithValue(err);
        }
    }
);

export const addRackAction = createAsyncThunk(
    "rack/add",
    async (rackLocation: string, { rejectWithValue }) => {
        try {
            const response = await axios.post<BaseResponse<addRackBody>>(
                ADD_RACK,
                { rackLocation }
            );
            return { response: response.data };
        }
        catch (err) {
            if (!err) {
                throw err;
            }
            return rejectWithValue(err);
        }
    }
);

export const registerRackBillAction = createAsyncThunk(
    "rack/register-bill",
    async ({rackId, billId}: {rackId: number, billId: number}, { rejectWithValue }) => {
        try {
            const response = await axios.post<BaseResponse<RackBillRegisterBody>>(
                REGISTER_RACK_BILL,
                { rackId, billId }
            );
            return { response: response.data };
        }
        catch (err) {
            if (!err) {
                throw err;
            }
            return rejectWithValue(err);
        }
    }
);

export const getAllRackBillsAction = createAsyncThunk(
    "rack/all-bills",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get<BaseResponse<AllRackBillsResponse>>(
                GET_ALL_RACK_BILL
            );
            return { response: response.data };
        }
        catch (err) {
            if (!err) {
                throw err;
            }
            return rejectWithValue(err);
        }
    }
);