import {
  Box,
  Button,
  CircularProgress,
  Typography,
  styled,
  Alert,
  AlertColor,
} from "@mui/material";
import { FC, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs";
import { uploadPriceList } from "../../../../redux/actions/pricingAction";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

interface IUploadPriceProps {}
interface toastInterface {
  open: boolean;
  message: string;
  type: AlertColor;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadPrice: FC<IUploadPriceProps> = () => {
  const snack = useSnackbarContext();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dispatch = useAppDispatch();
  const { uploadPriceListResponseLoading } = useAppSelector(
    (state) => state.pricing
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file && file.name.endsWith(".csv")) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Please select a valid CSV file.");
    }
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      dispatch(uploadPriceList(formData)).then((res: any) => {
        if (res?.payload?.response?.status === "SUCCESS") {
          // alert("Price list uploaded successfully.");

          snack.showSnackbar("Price list uploaded successfully.", "success");
          setSelectedFile(null);
        } else {
          // alert("Failed to upload price list.");
          snack.showSnackbar("Failed to upload price list.", "error");
        }
      });
    }
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form
          onSubmit={handleOnSubmit}
          style={{
            background: Colors.BACKGROUND_GRAY,
            padding: "3rem 4rem",
            borderRadius: "0.8rem",
            border: `1px solid ${Colors.ACCENT_GRAY}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "-6rem",
            //   gap: "1rem",
          }}
        >
          <Typography
            variant="h4"
            sx={{ color: Colors.TEXT_GRAY_DARK, margin: 0 }}
          >
            Upload Price List
          </Typography>
          <Typography
            sx={{
              color: Colors.TEXT_GRAY_DARK,
              opacity: 0.8,
              marginBottom: "2.5rem",
              marginTop: "0.8rem",
              fontSize: "1.1rem",
            }}
          >
            Please select the file to upload your price list.
          </Typography>
          {selectedFile && (
            <Box
              sx={{
                opacity: 0.6,
                mb: "0.4rem",
                display: "flex",
                alignItems: "flex-end",
                gap: "0.1rem",
              }}
            >
              <Typography
                sx={{
                  "&::before": {
                    content: "'File: '",
                    fontWeight: 600,
                    fontSize: "1rem",
                  },
                  maxWidth: "15rem",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontWeight: 400,
                  fontSize: "1rem",
                }}
              >
                {selectedFile.name.split(".").map((part, i) => {
                  if (i === selectedFile.name.split(".").length - 1) {
                    return null;
                  }
                  return (i === 0 ? "" : ".") + part;
                })}
              </Typography>
              <Typography
                sx={{
                  "&::before": {
                    content: "'.'",
                    fontSize: "1rem",
                  },
                  fontWeight: 500,
                  fontSize: "1rem",
                }}
              >
                {
                  selectedFile.name.split(".")[
                    selectedFile.name.split(".").length - 1
                  ]
                }
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <CustomButton
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              sx={{
                textTransform: "capitalize",
                "&[disabled]": {
                  background: Colors.ACCENT_GRAY,
                  color: Colors.ACCENT_GRAY_DISABLED,
                },
              }}
              disabled={uploadPriceListResponseLoading}
            >
              Select File
              <VisuallyHiddenInput
                type="file"
                onChange={handleFileChange}
                accept=".csv"
              />
            </CustomButton>
            <CustomButton
              sx={{
                background: Colors.SECONDARY,
                color: Colors.WHITE,
                textTransform: "capitalize",
                padding: "0.5rem 2rem",
                "&[disabled]": {
                  background: Colors.ACCENT_GRAY,
                  color: Colors.ACCENT_GRAY_DISABLED,
                },
              }}
              type="submit"
              disabled={!selectedFile || uploadPriceListResponseLoading}
            >
              {uploadPriceListResponseLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Upload Prices"
              )}
            </CustomButton>
          </Box>
        </form>
      </Box>
    </AdminLayout>
  );
};

export default UploadPrice;
