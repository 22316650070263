export enum FabricType {
    REGULAR = "REGULAR",
    WOOL = "WOOL",
    POLYESTER = "POLYESTER",
    KNIT = "KNIT",
    RAYON = "RAYON",
    VISCOSE = "VISCOSE",
    CREPE = "CREPE",
    SATIN = "SATIN",
    SPANDEX = "SPANDEX",
    ELASTANE = "ELASTANE",
    SILK = "SILK",
    LACE = "LACE",
    COTTON = "COTTON",
    LINEN = "LINEN",
    METALLIC = "METALLIC",
    VELVET = "VELVET",
    CHENILLE = "CHENILLE",
    ST_JOHNS = "ST_JOHNS",
    CASHMERE = "CASHMERE",
    CAMEL = "CAMEL",
    ANGORA = "ANGORA",
    MOHAIR = "MOHAIR",
    ALPACA = "ALPACA",
    M_SUEDE = "M_SUEDE",
    BORGANO = "BORGANO",
    ACRYLIC ="ACRYLIC",
    RUFFLES = "RUFFLES"
}