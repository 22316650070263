export interface ISortArrayProps {
  array: object[];
  sortByKey: string;
  sortDirection?: "asc" | "desc";
  fixedFirstItem?: string;
  fixedLastItem?: string;
}

export const sortArray = ({
  array,
  sortDirection = "asc",
  sortByKey,
  fixedFirstItem,
  fixedLastItem,
}: ISortArrayProps) => {
  let sortedArr: object[];
  if (fixedFirstItem && fixedLastItem) {
    sortedArr = array.slice().sort((a, b) => {
      if ((a as any)[sortByKey] === fixedFirstItem) return -1;
      if ((b as any)[sortByKey] === fixedFirstItem) return 1;
      if ((a as any)[sortByKey] === fixedLastItem) return 1;
      if ((b as any)[sortByKey] === fixedLastItem) return -1;
      return (
        (sortDirection === "asc" ? 1 : -1) *
        ((a as any)[sortByKey] as string).localeCompare(
          (b as any)[sortByKey] as string
        )
      );
    });
  } else if (fixedFirstItem) {
    sortedArr = array.slice().sort((a, b) => {
      if ((a as any)[sortByKey] === fixedFirstItem) return -1;
      if ((b as any)[sortByKey] === fixedFirstItem) return 1;
      return (
        (sortDirection === "asc" ? 1 : -1) *
        ((a as any)[sortByKey] as string).localeCompare(
          (b as any)[sortByKey] as string
        )
      );
    });
  } else if (fixedLastItem) {
    sortedArr = array.slice().sort((a, b) => {
      if ((a as any)[sortByKey] === fixedLastItem) return 1;
      if ((b as any)[sortByKey] === fixedLastItem) return -1;
      return (
        (sortDirection === "asc" ? 1 : -1) *
        ((a as any)[sortByKey] as string).localeCompare(
          (b as any)[sortByKey] as string
        )
      );
    });
  } else {
    sortedArr = array.slice().sort((a, b) => {
      return (
        (sortDirection === "asc" ? 1 : -1) *
        ((a as any)[sortByKey] as string).localeCompare(
          (b as any)[sortByKey] as string
        )
      );
    });
  }

  return sortedArr;
};
