import { Box, Drawer } from "@mui/material";
import { FC } from "react";

interface ICustomDrawerProps {
  isOpen: boolean;
  toggleDrawer(): void;
  children: React.ReactNode;
  sx?: any;
}

export const CustomDrawer: FC<ICustomDrawerProps> = ({
  isOpen,
  toggleDrawer,
  children,
  sx = {},
}) => {
  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <Box
        sx={{
          width: 300,
          padding: 2,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Drawer>
  );
};
