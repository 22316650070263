export enum Permission {
    DROP_OFF = "DROP_OFF",
    PICK_UP = "PICK_UP",
    EDIT_INVOICE = "EDIT_INVOICE",
    REPRINT_INVOICE = "REPRINT_INVOICE",
    REPRINT_TAG = "REPRINT_TAG",
    VOID_INVOICE = "VOID_INVOICE",
    CONVEYOR_INPUT = "CONVEYOR_INPUT",
    CASH_REGISTER = "CASH_REGISTER",
    REPORTS = "REPORTS",
    SEARCH = "SEARCH",
    RETAIL_SALE = "RETAIL_SALE",
    PRICE_LIST = "PRICE_LIST",
    PICKUP_AND_DELIVERY = "PICKUP_AND_DELIVERY",
    DAILY_SNAPSHOT = "DAILY_SNAPSHOT",
    SETTINGS = "SETTINGS",

}