import { Box, TextField, TextFieldProps, Typography } from "@mui/material";
import { FC, forwardRef, useEffect, useState } from "react";
import { Colors } from "../../../../configs";
import { CustomTextBox } from "../../../atoms";

interface ICheckSectionProps {
  billValue: number;
  paidAmount: number;
  returnPaidAmount: (value: number) => void;
  returnBalance: (value: number) => void;
}
type ICheckSectionPropsWithRef = ICheckSectionProps & TextFieldProps;

export const CheckSection: FC<ICheckSectionPropsWithRef> = forwardRef(
  (props, ref) => {
    const {
      billValue,
      paidAmount,
      returnPaidAmount,
      returnBalance,
      ...otherProps
    } = props;

    // const [checkAmount, setCheckAmount] = useState(amount ?? 0);
    const [insideAmount, setInsideAmount] = useState(billValue ?? 0);

    useEffect(() => {
      setInsideAmount(billValue ?? 0);
    }, [billValue]);
    useEffect(() => {
      returnBalance && returnBalance(insideAmount - billValue);
      returnPaidAmount && returnPaidAmount(insideAmount);
    }, [insideAmount]);

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.48rem",
          height: "23.8571429rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "2.85714286rem",
            fontWeight: "bold",
            color: Colors.TEXT_GRAY_DARK,
          }}
        >
          Check Information
        </Typography>
        <Box sx={{ display: "flex", gap: "1.64285714rem" }}>
          <Box
            sx={{
              background: Colors.BACKGROUND_GRAY,
              borderRadius: "0.357142857rem",
              py: "1.78571429rem",
              px: "2.20392857rem",
              width: "28.2143rem",
              height: "18.0714286rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.714285714rem",
              // 1.28571428571-0.714285714=0.57142857141
            }}
          >
            <Typography
              sx={{
                fontSize: "1.71428571rem",
                color: Colors.TEXT_GRAY_DARK,
                fontWeight: "bold",
                marginBottom: "0.57142857141rem",
              }}
            >
              Payment Amount
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.42857142857rem",
                  color: Colors.TEXT_GRAY_DARK,
                  fontWeight: 500,
                }}
              >
                Paid
              </Typography>
              <CustomTextBox
                type="currency"
                styles={{
                  width: "15.0000143rem",
                }}
                value={insideAmount}
                onChange={(e) => setInsideAmount(Number(e.target.value ?? 0))}
                autoFocus
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.42857142857rem",
                  color: Colors.TEXT_GRAY_DARK,
                  fontWeight: 500,
                }}
              >
                Amount
              </Typography>
              <CustomTextBox
                type="currency"
                value={billValue}
                // onChange={(e) => handlePaidAmountChange(+e.target.value)}
                styles={{
                  width: "15.0000143rem",
                }}
                disabled
                // onKeyUp={(e) => {
                //   // if current value zero replace it with the new value
                //   if (paidAmount === 0) {
                //     setPaidAmountInputType("keyboard");
                //     setPaidAmount(+e.key);
                //   }
                // }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.42857142857rem",
                  color: Colors.TEXT_GRAY_DARK,
                  fontWeight: 500,
                }}
              >
                Balance
              </Typography>
              <CustomTextBox
                size="small"
                styles={{
                  /*   width: "17.2142857rem !important",*/
                  width: "15.0000143rem",
                  minHeight: "3rem",
                  height: "3rem",
                  maxHeight: "3rem",
                  borderRadius: "0.357142857rem",
                  background: Colors.ACCENT_GRAY,
                  border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                  boxShadow: "none !important",
                  padding: "0 !important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: Colors.ACCENT_GRAY_DISABLED,
                  paddingRight: "0.987857143rem",
                  fontSize: "1.57142857rem",
                  margin: "0 !important",
                  fontWeight: 500,
                  boxSizing: "border-box",
                }}
                type="currency"
                value={paidAmount - billValue < 0 ? 0 : paidAmount - billValue}
                disabled
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.714285714rem",
              width: "55.0714143rem",
              height: "18.0714286rem",
              background: Colors.BACKGROUND_GRAY,
              borderRadius: "0.357142857rem",
              px: "2.95392857rem",
              py: "1.7rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: Colors.MAIN_GRAY,
                  fontSize: "1.42857143rem",
                  fontWeight: 500,
                }}
              >
                Check Number
              </Typography>
              <TextField
                size="small"
                sx={{
                  /*   width: "17.2142857rem !important",*/
                  width: "32.7142715rem", // 1.2143
                  minHeight: "3rem !important",
                  height: "3rem !important",
                  maxHeight: "3rem !important",
                  borderRadius: "0.357142857rem",
                  background: Colors.WHITE,
                  border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                  boxShadow: "none !important",
                  padding: "0 !important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& input": {
                    padding: "0",
                    margin: "0 !important",
                    textAlign: "right",
                    fontSize: "1.57142857rem",
                    fontWeight: 500,
                    paddingRight: "0.987857143rem",
                    width: "23.92857146rem",
                    boxSizing: "border-box",
                  },
                  "& fieldset": {
                    border: "none !important",
                    height: "3rem !important",
                  },
                }}
                type="number"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: Colors.MAIN_GRAY,
                  fontSize: "1.42857143rem",
                  fontWeight: 500,
                }}
              >
                Identification (opt)
              </Typography>
              <TextField
                size="small"
                sx={{
                  /*   width: "17.2142857rem !important",*/
                  width: "32.7142715rem",
                  minHeight: "3rem !important",
                  height: "3rem !important",
                  maxHeight: "3rem !important",
                  borderRadius: "0.357142857rem",
                  background: Colors.WHITE,
                  border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                  boxShadow: "none !important",
                  padding: "0 !important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& input": {
                    padding: "0",
                    margin: "0 !important",
                    textAlign: "right",
                    fontSize: "1.57142857rem",
                    fontWeight: 500,
                    paddingRight: "0.987857143rem",
                    width: "23.92857146rem",
                    boxSizing: "border-box",
                  },
                  "& fieldset": {
                    border: "none !important",
                    height: "3rem !important",
                  },
                }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                color: Colors.MAIN_GRAY,
                lineHeight: "120%",
                mt: "3rem",
                textAlign: "center",
              }}
            >
              To expedite processing, kindly input the check number accurately
              in the designated field. Identification is optional. For any
              inquiries or concerns, our support team is at your disposal.
              Additionally, please consider managing checks with surplus funds
              through the Store Credits Manager for efficient financial
              tracking.
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
);
