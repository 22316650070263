import { createSlice } from "@reduxjs/toolkit";
import { ILoginResponse, IUserResponse } from "../../interfaces";
import {
  authDetailsInitializeAction,
  changePasswordAction,
  getLoginUserList,
  lockProfileAction,
  loginAction,
  logoutAction,
  unlockProfileAction,
} from "../actions/authAction";
import { LoginUser } from "../../typings/interfaces/responses/LoginUser";

export interface AuthState {
  user: IUserResponse | null;
  token: string | null;
  loading: boolean;
  error: string | null;
  authDetailsInitialized: boolean;
  locked: boolean;
  loginResponse: ILoginResponse | null;
  loginUsers: LoginUser[];
  loginUsersLoading: boolean;
  loginUsersError: string | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  loading: false,
  error: null,
  loginResponse: null,
  authDetailsInitialized: false,
  locked: false,
  loginUsers: [],
  loginUsersLoading: false,
  loginUsersError: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    clearAuthData: (state) => {
      state.user = null;
      state.token = null;
      state.loginResponse = null;
    },
    setLocked: (state, action) => {
      state.locked = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutAction.fulfilled, (state, action) => {
        state.user = null;
        state.token = null;
      })
      .addCase(loginAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.loginResponse = null;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = action.payload.user;
        state.token = action.payload.token?.token || null;
        state.loginResponse = action.payload.response;
        state.locked = false;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Something went wrong";
        state.loginResponse = null;
      })
      .addCase(authDetailsInitializeAction.pending, (state) => {
        // If want can show initializing global loader
      })
      .addCase(authDetailsInitializeAction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = action.payload.user;
        state.token = action.payload.storedToken;
        state.loginResponse = null;
        state.authDetailsInitialized = action.payload.userAvailable;
        state.locked = action.payload.profileLock;
      })
      .addCase(authDetailsInitializeAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Something went wrong";
        state.loginResponse = null;
        state.authDetailsInitialized = true;
      })
      .addCase(changePasswordAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.loginResponse = null;
      })
      .addCase(changePasswordAction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = action.payload.user;
        state.token = action.payload.token?.token || null;
        state.loginResponse = action.payload.response;
      })
      .addCase(changePasswordAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Something went wrong";
        state.loginResponse = null;
      })
      .addCase(lockProfileAction.fulfilled, (state) => {
        state.locked = true;
      })
      .addCase(unlockProfileAction.fulfilled, (state) => {
        state.locked = false;
      })
      .addCase(getLoginUserList.pending, (state) => {
        state.loginUsersLoading = true;
        state.loginUsersError = null;
        state.loginUsers = [];
      })
      .addCase(getLoginUserList.fulfilled, (state, action) => {
        state.loginUsersLoading = false;
        state.loginUsersError = null;
        state.loginUsers = action.payload || [];
      })
      .addCase(getLoginUserList.rejected, (state, action) => {
        state.loginUsersLoading = false;
        state.loginUsersError = action.error.message ?? "Something went wrong";
        state.loginUsers = [];
      })
  },
});

export default authSlice.reducer;
export const AuthSliceAction = authSlice.actions;
