import React from 'react';
import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';
import ReplyIcon from '@mui/icons-material/Reply';

interface Props {
  variation: 'Lock' | 'Back';
  onClick?: () => void;
}

const TopRightButton: React.FC<Props> = ({ variation, onClick }) => {
  const icon = variation === 'Lock' ? <LockIcon sx={{fontSize: "40px"}}/> : <ReplyIcon sx={{fontSize: "50px"}} />;
  // const buttonText = variation === 'Lock' ? 'Lock' : 'Back';

  return (
    <Button
      variant='contained'
      sx={{
        position: 'fixed',
        top: '50px',
        right: variation === 'Lock' ? '0' : '0',
        width: variation === 'Lock' ? '70px' : '70px',
        height: variation === 'Lock' ? '70px' : '57px',
        marginRight: variation === 'Lock' ? '20px' : '0',
        backgroundColor: '#930000',
        "&:hover": {
          backgroundColor: "#930000"
        },
        color: 'white',
        borderRadius: variation === 'Lock'?"15px":"15px 0 0 15px",
        zIndex: "999"
      }}
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};

export default TopRightButton;
