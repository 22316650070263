/*

interface IState {
  id: string; // UPPER_SNAKE_CASE
  name: string;
}

const states: IState[] = [
  { id: "ALABAMA", name: "Alabama" },
  { id: "ALASKA", name: "Alaska" },
  { id: "ARIZONA", name: "Arizona" },
  { id: "ARKANSAS", name: "Arkansas" },
  { id: "CALIFORNIA", name: "California" },
  { id: "COLORADO", name: "Colorado" },
  { id: "CONNECTICUT", name: "Connecticut" },
  { id: "DELAWARE", name: "Delaware" },
  { id: "FLORIDA", name: "Florida" },
  { id: "GEORGIA", name: "Georgia" },
  { id: "HAWAII", name: "Hawaii" },
  { id: "IDAHO", name: "Idaho" },
  { id: "ILLINOIS", name: "Illinois" },
  { id: "INDIANA", name: "Indiana" },
  { id: "IOWA", name: "Iowa" },
  { id: "KANSAS", name: "Kansas" },
  { id: "KENTUCKY", name: "Kentucky" },
  { id: "LOUISIANA", name: "Louisiana" },
  { id: "MAINE", name: "Maine" },
  { id: "MARYLAND", name: "Maryland" },
  { id: "MASSACHUSETTS", name: "Massachusetts" },
  { id: "MICHIGAN", name: "Michigan" },
  { id: "MINNESOTA", name: "Minnesota" },
  { id: "MISSISSIPPI", name: "Mississippi" },
  { id: "MISSOURI", name: "Missouri" },
  { id: "MONTANA", name: "Montana" },
  { id: "NEBRASKA", name: "Nebraska" },
  { id: "NEVADA", name: "Nevada" },
  { id: "NEW_HAMPSHIRE", name: "New Hampshire" },
  { id: "NEW_JERSEY", name: "New Jersey" },
  { id: "NEW_MEXICO", name: "New Mexico" },
  { id: "NEW_YORK", name: "New York" },
  { id: "NORTH_CAROLINA", name: "North Carolina" },
  { id: "NORTH_DAKOTA", name: "North Dakota" },
  { id: "OHIO", name: "Ohio" },
  { id: "OKLAHOMA", name: "Oklahoma" },
  { id: "OREGON", name: "Oregon" },
  { id: "PENNSYLVANIA", name: "Pennsylvania" },
  { id: "RHODE_ISLAND", name: "Rhode Island" },
  { id: "SOUTH_CAROLINA", name: "South Carolina" },
  { id: "SOUTH_DAKOTA", name: "South Dakota" },
  { id: "TENNESSEE", name: "Tennessee" },
  { id: "TEXAS", name: "Texas" },
  { id: "UTAH", name: "Utah" },
  { id: "VERMONT", name: "Vermont" },
  { id: "VIRGINIA", name: "Virginia" },
  { id: "WASHINGTON", name: "Washington" },
  { id: "WEST_VIRGINIA", name: "West Virginia" },
  { id: "WISCONSIN", name: "Wisconsin" },
  { id: "WYOMING", name: "Wyoming" },
];

*/

export enum states {
  ALABAMA = "Alabama",
  ALASKA = "Alaska",
  ARIZONA = "Arizona",
  ARKANSAS = "Arkansas",
  CALIFORNIA = "California",
  COLORADO = "Colorado",
  CONNECTICUT = "Connecticut",
  DELAWARE = "Delaware",
  FLORIDA = "Florida",
  GEORGIA = "Georgia",
  HAWAII = "Hawaii",
  IDAHO = "Idaho",
  ILLINOIS = "Illinois",
  INDIANA = "Indiana",
  IOWA = "Iowa",
  KANSAS = "Kansas",
  KENTUCKY = "Kentucky",
  LOUISIANA = "Louisiana",
  MAINE = "Maine",
  MARYLAND = "Maryland",
  MASSACHUSETTS = "Massachusetts",
  MICHIGAN = "Michigan",
  MINNESOTA = "Minnesota",
  MISSISSIPPI = "Mississippi",
  MISSOURI = "Missouri",
  MONTANA = "Montana",
  NEBRASKA = "Nebraska",
  NEVADA = "Nevada",
  NEW_HAMPSHIRE = "New Hampshire",
  NEW_JERSEY = "New Jersey",
  NEW_MEXICO = "New Mexico",
  NEW_YORK = "New York",
  NORTH_CAROLINA = "North Carolina",
  NORTH_DAKOTA = "North Dakota",
  OHIO = "Ohio",
  OKLAHOMA = "Oklahoma",
  OREGON = "Oregon",
  PENNSYLVANIA = "Pennsylvania",
  RHODE_ISLAND = "Rhode Island",
  SOUTH_CAROLINA = "South Carolina",
  SOUTH_DAKOTA = "South Dakota",
  TENNESSEE = "Tennessee",
  TEXAS = "Texas",
  UTAH = "Utah",
  VERMONT = "Vermont",
  VIRGINIA = "Virginia",
  WASHINGTON = "Washington",
  WEST_VIRGINIA = "West Virginia",
  WISCONSIN = "Wisconsin",
  WYOMING = "Wyoming",
}
