// TenantThemeProvider.tsx
import React, { ReactNode } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { getThemeForTenant } from "../configs";
import BaseSiteHomePage from "../pages/base-site/home";

interface TenantThemeProviderProps {
  tenantId: string;
  children: ReactNode;
}

const TenantThemeProvider: React.FC<TenantThemeProviderProps> = ({
  tenantId,
  children,
}) => {
  const theme = getThemeForTenant(tenantId);
  if (!theme) {
    return (
      <MuiThemeProvider theme={theme}>
        <BaseSiteHomePage />
      </MuiThemeProvider>
    );
  }

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default TenantThemeProvider;
