import {
  Select,
  SelectProps,
  InputLabel,
  FormControl,
  FormControlProps,
  SelectChangeEvent,
} from "@mui/material";
import React, { FC, ReactNode, forwardRef } from "react";
import { Colors } from "../../../configs";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export interface ISelectBoxProps {
  label?: string;
  formControlProps?: FormControlProps;
  onChange?:
    | ((event: SelectChangeEvent<unknown>, child: ReactNode) => void)
    | undefined;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  width?: string;
}

type CustomSelectBoxProps = ISelectBoxProps & SelectProps;

export const CustomSelectBox: FC<CustomSelectBoxProps> = forwardRef(
  (props, ref) => {
    const {
      label,
      formControlProps,
      onChange,
      onKeyDown,
      value,
      disabled,
      inputProps,
      width = "100%",
      ...otherProps
    } = props;

    return (
      <FormControl variant="outlined" {...formControlProps} disabled={disabled}>
        {label && <InputLabel>{label}</InputLabel>}
        <Select
          IconComponent={ArrowDropDownIcon}
          defaultValue={props.defaultValue || ""}
          sx={{
            "&>div": {
              paddingLeft: "0.7rem !important",
              height: "100% !important",
              display: "flex",
              alignItems: "center",
              width: width,
            },
            "& .MuiSelect-icon": {
              backgroundColor: "secondary.main",
              borderRadius: "0.57rem",
              color: Colors.WHITE,
              // padding: "4px",
              // height: "23px",
            },
            "& .MuiSelect-select": {
              paddingRight: "2.285rem",
              fontSize: "1.14rem",
              padding: "0px 0.57rem",
              margin: "0px",
              backgroundColor: Colors.WHITE,
              lineHeight: "2.14rem",
            },
            ...props.sx,
          }}
          onChange={onChange}
          inputRef={ref}
          onKeyDown={onKeyDown}
          value={value}
          disabled={disabled}
          inputProps={inputProps}
          {...otherProps}
        >
          {props.children}
        </Select>
      </FormControl>
    );
  }
);
