import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDiscountRequest } from "../../typings/interfaces/requests";
import axios from "axios";
import { BaseResponseSingle } from "../../typings";
import {
  CREATE_DISCOUNT,
  DELETE_DISCOUNT,
  GET_ALL_STORE_DISCOUNTS,
  UPDATE_DISCOUNT,
} from "../../configs";

export const createDiscount = createAsyncThunk(
  "discount/create",
  async (discountBody: IDiscountRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<BaseResponseSingle<any>>(
        CREATE_DISCOUNT,
        discountBody
      );
      return response.data.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateDiscount = createAsyncThunk(
  "discount/update",
  async (discountBody: IDiscountRequest, { rejectWithValue }) => {
    try {
      const response = await axios.put<BaseResponseSingle<any>>(
        UPDATE_DISCOUNT,
        discountBody
      );
      return response.data.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteDiscount = createAsyncThunk(
  "discount/delete",
  async (discountID: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete<BaseResponseSingle<any>>(
        DELETE_DISCOUNT(discountID)
      );
      return response.data.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDiscountsByStoreId = createAsyncThunk(
  "discount/getByStoreId",
  async (storeId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponseSingle<any>>(
        GET_ALL_STORE_DISCOUNTS(storeId)
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
