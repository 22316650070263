import { Box, TextField, TextFieldProps, Typography } from "@mui/material";
import { FC, forwardRef, useEffect, useState } from "react";
import { Colors } from "../../../../configs";
import { CustomTextBox } from "../../../atoms";
import { useAppSelector } from "../../../../hooks";

interface IStoreCreditSectionProps {
  paidAmount: number;
  returnPaidAmount: (value: number) => void;
  billValue: number;
  readonly?: boolean;
  paymentType: "deposit" | "withdraw";
  // balance?: number;
  // returnBalance: (value: number) => void;
}

type IStoreCreditSectionPropsWithRef = IStoreCreditSectionProps &
  TextFieldProps;

export const StoreCreditSection: FC<IStoreCreditSectionPropsWithRef> =
  forwardRef((props, ref) => {
    const {
      returnPaidAmount,
      billValue,
      paidAmount,
      readonly = false,
      paymentType,
    } = props;
    const { customer } = useAppSelector((state) => state.customer);

    useEffect(() => {
      returnPaidAmount(billValue ?? 0);
    }, [billValue]);

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.48rem",
          height: "23.8571429rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "2.85714286rem",
            fontWeight: "bold",
            color: Colors.TEXT_GRAY_DARK,
          }}
        >
          Store Credit
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.714285714rem",
            width: "84.9285714rem",
            height: "18.0714286rem",
            background: Colors.BACKGROUND_GRAY,
            borderRadius: "0.357142857rem",
            px: "2.95392857rem",
            py: "1.7rem",
          }}
        >
          {/* <Typography
            sx={{
              fontSize: "1.71428571rem",
              color: Colors.TEXT_GRAY_DARK,
              fontWeight: "bold",
              marginBottom: "0.57142857141rem",
            }}
          >
            Payment Amount
          </Typography> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: Colors.MAIN_GRAY,
                fontSize: "1.42857143rem",
                fontWeight: 500,
              }}
            >
              Amount
            </Typography>
            <CustomTextBox
              type="currency"
              value={paidAmount ?? 0}
              styles={{
                width: "62.5714286rem",
              }}
              inputRef={ref}
              autoFocus
              onChange={(e) => returnPaidAmount(Number(e.target.value ?? 0))}
              disabled={readonly}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: Colors.MAIN_GRAY,
                fontSize: "1.42857143rem",
                fontWeight: 500,
              }}
            >
              Current Balance
            </Typography>
            <CustomTextBox
              type="currency"
              value={customer?.storeCreditBalance ?? 0}
              styles={{
                width: "62.5714286rem",
                minHeight: "3rem",
                height: "3rem",
                maxHeight: "3rem",
                borderRadius: "0.357142857rem",
                background: Colors.ACCENT_GRAY,
                color: Colors.ACCENT_GRAY_DISABLED,
                border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                boxShadow: "none !important",
                padding: "0",
                paddingRight: "0.987857143rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "right",
                fontSize: "1.57142857rem",
                margin: "0 !important",
                fontWeight: 500,
                boxSizing: "border-box",
              }}
              inputRef={ref}
              autoFocus
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: Colors.MAIN_GRAY,
                fontSize: "1.42857143rem",
                fontWeight: 500,
              }}
            >
              New Balance
            </Typography>
            <CustomTextBox
              type="currency"
              value={
                paymentType === "withdraw"
                  ? (customer?.storeCreditBalance ?? 0) - paidAmount
                  : (customer?.storeCreditBalance ?? 0) + paidAmount
              }
              styles={{
                width: "62.5714286rem",
                minHeight: "3rem",
                height: "3rem",
                maxHeight: "3rem",
                borderRadius: "0.357142857rem",
                background: Colors.ACCENT_GRAY,
                color: Colors.ACCENT_GRAY_DISABLED,
                border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                boxShadow: "none !important",
                padding: "0",
                paddingRight: "0.987857143rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "right",
                fontSize: "1.57142857rem",
                margin: "0 !important",
                fontWeight: 500,
                boxSizing: "border-box",
              }}
              inputRef={ref}
              autoFocus
            />
          </Box>
        </Box>
      </Box>
    );
  });
