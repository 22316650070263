import PrestoLoginLogo from "../assets/images/presto-login-logo.png";
import CleanxLoginLogo from "../assets/images/cleanx-login-logo.png";
import TeachmeitLogo from "../assets/images/teachmeit-login-logo.png";

export interface Translation {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
    companyEmail: string;
    loginLogo: string;
}

const tenantTranslations: Record<string, Translation> = {
    presto: {
        companyName: 'Presto Valet',
        companyAddress: "1623 N Quaker Ln Alexandria VA 22302",
        companyPhone: "703 998 6464",
        companyEmail: "presto@prestovalet.net",
        loginLogo: PrestoLoginLogo
    },
    cleanx: {
        companyName: 'Clean X',
        companyAddress: "Fake Address, Location, Here",
        companyPhone: "XXX XXX XXXX",
        companyEmail: "fakeemail@fake.com",
        loginLogo: CleanxLoginLogo
    },
    admin: {
        companyName: 'MultiPOS',
        companyAddress: "No 243, Kerawalapitiya, Hendala, Wattala",
        companyPhone: "077 028 2037",
        companyEmail: "info@teachmeit.lk",
        loginLogo: TeachmeitLogo
    }
    // Add more tenant translations here
};

export function getTranslationsForTenant(tenantId: string): Translation {
    const translations = tenantTranslations[tenantId];

    if (!translations) {
        throw new Error(`No translations found for tenant: ${tenantId}`);
    }

    return translations;
}
