import { Box, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { CustomButton } from "../../../atoms";
import { Colors } from "../../../../configs";
import { sortArray } from "../../../../util/array-functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { cloneDeep } from "lodash";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { IDamage } from "../../../../typings/interfaces/pos/cart-item";
import DamagePromptModal from "../damage-prompt-modal/damage-prompt-modal";

export interface IDamageSectionProps {}

// const damages = [
//   {
//     label: "Missing Button(s)",
//     checked: false,
//   },
//   {
//     label: "Broken Button(s)",
//     checked: false,
//   },
//   {
//     label: "Worn Collar",
//     checked: false,
//   },
//   {
//     label: "Worn Cuffs",
//     checked: false,
//   },
//   {
//     label: "Tears",
//     checked: false,
//   },
//   {
//     label: "Holes",
//     checked: false,
//   },
//   {
//     label: "Color Bleed",
//     checked: false,
//   },
//   {
//     label: "Die Transfer",
//     checked: false,
//   },
//   {
//     label: "Color Faded",
//     checked: false,
//   },
//   {
//     label: "Color Loss",
//     checked: false,
//   },
//   {
//     label: "Iron Marks",
//     checked: false,
//   },
//   {
//     label: "Bubbles",
//     checked: false,
//   },
//   {
//     label: "Piling Fabric",
//     checked: false,
//   },
//   {
//     label: "Snag",
//     checked: false,
//   },
//   {
//     label: "Torn",
//     checked: false,
//   },
//   {
//     label: "Shrinkage",
//     checked: false,
//   },
//   {
//     label: "Water Damage",
//     checked: false,
//   },
//   {
//     label: "Sun Damage",
//     checked: false,
//   },
//   {
//     label: "Customer Washed at Home",
//     checked: false,
//   },
//   {
//     label: "Buttons Chipped",
//     checked: false,
//   },
//   {
//     label: "Tassels Missing",
//     checked: false,
//   },
//   {
//     label: "Thread Pulls",
//     checked: false,
//   },
//   {
//     label: "Cleaned by a Different Cleaner",
//     checked: false,
//   },
//   {
//     label: "Old Fabric",
//     checked: false,
//   },
//   {
//     label: "Fragile Fabric",
//     checked: false,
//   },
//   {
//     label: "Dry Rotted",
//     checked: false,
//   },
//   {
//     label: "Bleached",
//     checked: false,
//   },
//   {
//     label: "Puckering",
//     checked: false,
//   },
//   {
//     label: "Insect Damage",
//     checked: false,
//   },
//   {
//     label: "Zipper Pull Missing",
//     checked: false,
//   },
//   {
//     label: "Zipper Broken",
//     checked: false,
//   },
//   {
//     label: "Mold",
//     checked: false,
//   },
//   {
//     label: "Moth Damage",
//     checked: false,
//   },
//   {
//     label: "Belt Loops Worn Out",
//     checked: false,
//   },
// ];

const damages: { id: number; label: string }[] = [
  {
    id: 1,
    label: "Missing Button(s)",
  },
  {
    id: 2,
    label: "Broken Button(s)",
  },
  {
    id: 3,
    label: "Worn Collar",
  },
  {
    id: 4,
    label: "Worn Cuffs",
  },
  {
    id: 5,
    label: "Tears",
  },
  {
    id: 6,
    label: "Holes",
  },
  {
    id: 7,
    label: "Color Bleed",
  },
  {
    id: 8,
    label: "Die Transfer",
  },
  {
    id: 9,
    label: "Color Faded",
  },
  {
    id: 10,
    label: "Color Loss",
  },
  {
    id: 11,
    label: "Iron Marks",
  },
  {
    id: 12,
    label: "Bubbles",
  },
  {
    id: 13,
    label: "Piling Fabric",
  },
  {
    id: 14,
    label: "Snag",
  },
  {
    id: 15,
    label: "Torn",
  },
  {
    id: 16,
    label: "Shrinkage",
  },
  {
    id: 17,
    label: "Water Damage",
  },
  {
    id: 18,
    label: "Sun Damage",
  },
  {
    id: 19,
    label: "Customer Washed at Home",
  },
  {
    id: 20,
    label: "Buttons Chipped",
  },
  {
    id: 21,
    label: "Tassels Missing",
  },
  {
    id: 22,
    label: "Thread Pulls",
  },
  {
    id: 23,
    label: "Cleaned by a Different Cleaner",
  },
  {
    id: 24,
    label: "Old Fabric",
  },
  {
    id: 25,
    label: "Fragile Fabric",
  },
  {
    id: 26,
    label: "Dry Rotted",
  },
  {
    id: 27,
    label: "Bleached",
  },
  {
    id: 28,
    label: "Puckering",
  },
  {
    id: 29,
    label: "Insect Damage",
  },
  {
    id: 30,
    label: "Zipper Pull Missing",
  },
  {
    id: 31,
    label: "Zipper Broken",
  },
  {
    id: 32,
    label: "Mold",
  },
  {
    id: 33,
    label: "Moth Damage",
  },
  {
    id: 34,
    label: "Belt Loops Worn Out",
  },
  {
    id: 100000,
    label: "Custom",
  },
];

const damagesSorted = sortArray({
  array: damages,
  sortByKey: "label",
  fixedFirstItem: "Custom",
});

const DamageSection: FC<IDamageSectionProps> = () => {
  const dispatch = useAppDispatch();
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const [openPrompt, setOpenPrompt] = useState<boolean>(false);

  const handleOnClosePrompt = () => {
    setOpenPrompt(false);
  };

  const handleUpdateCartDamages = (damages: IDamage[]) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      cartItem.damages = damages;
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };

  const isAddedDamage = (damage: IDamage) => {
    return selectedCartItem?.damages?.find((item) => item.id === damage.id)?.id
      ? true
      : false;
  };

  const onSelectItem = (damage: IDamage) => {
    if (damage.id === 100000) {
      setOpenPrompt(true);
    } else {
      if (isAddedDamage(damage)) {
        handleUpdateCartDamages(
          selectedCartItem?.damages.filter((item) => damage.id !== item.id) ??
            []
        );
      } else {
        handleUpdateCartDamages([...(selectedCartItem?.damages || []), damage]);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.71428571rem",
        background: Colors.BACKGROUND_GRAY,
        // background: "red",
        borderRadius: "0.71rem",
        padding: "0.928571429rem",
      }}
    >
      <DamagePromptModal
        promptOpen={openPrompt}
        onClose={handleOnClosePrompt}
        onSubmit={handleUpdateCartDamages}
      />
      <Typography
        sx={{ fontSize: "1.71428571rem", color: Colors.TEXT_GRAY_DARK }}
      >
        Damages
      </Typography>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "1.07142857rem",
          rowGap: "0.857142857rem",
          justifyContent: "flex-start",
          alignContent: "flex-start",
          overflowY: "auto",
          height: "46.7142857rem",
        }}
      >
        {damages.map((damage, i) => {
          return (
            <CustomButton
              sx={{
                width: "17.0064286rem",
                background:
                  damage.checked ||
                  selectedCartItem?.damage.includes(damage.label)
                    ? Colors.SECONDARY
                    : Colors.MAIN_GRAY,
                borderRadius: "0.71rem",
                textTransform: "capitalize",
                color: Colors.WHITE,
                fontSize: "1.14rem",
                lineHeight: "1.33928571rem",
                padding: "1.07142857rem 0.857142857rem",
                "&&:hover": {
                  background: Colors.TEXT_GRAY_DARK,
                },
                height: "4.28571429rem",
              }}
              key={i}
              onClick={() => onSelectItem(damage)}
            >
              {damage.label}
            </CustomButton>
          );
        })}
      </Box> */}
      <Swiper
        style={{ width: "72rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(damagesSorted.length / 36))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  width: "72rem",
                  // height: "10.6428571rem",
                  // background: "red",
                  gap: "1.07142857rem",
                  rowGap: "0.857142857rem",
                  alignContent: "flex-start",
                  height: "46.7142857rem",
                }}
              >
                {damagesSorted
                  .slice(swipePageIndex * 36, (swipePageIndex + 1) * 36)
                  .map((damage, i) => (
                    <CustomButton
                      sx={{
                        width: "17.0064286rem",
                        background: isAddedDamage(damage as IDamage)
                          ? Colors.SECONDARY
                          : Colors.MAIN_GRAY,
                        borderRadius: "0.71rem",
                        textTransform: "capitalize",
                        color: Colors.WHITE,
                        fontSize: "1.14rem",
                        lineHeight: "1.33928571rem",
                        padding: "1.07142857rem 0.857142857rem",
                        "&&:hover": {
                          background: Colors.TEXT_GRAY_DARK,
                        },
                        height: "4.28571429rem",
                      }}
                      key={i}
                      onClick={() => onSelectItem(damage as any)}
                    >
                      {(damage as any).label}
                    </CustomButton>
                  ))}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default DamageSection;
