import { FC, useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import { padStart } from "lodash";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { Colors } from "../../../../configs/colors";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";
import { ICartSession } from "../../../../typings/interfaces/pos/cart-session";
import {
  addSession,
  removeSession,
} from "../../../../redux/actions/cartAction";
import usePricing from "../../../../hooks/usePricing";
import ConfirmDialog from "../../../atoms/confirm-box/confirm-box";
import { useParams } from "react-router-dom";
import { CustomButton } from "../../../atoms";

export interface IBillingSessionNavProps {}

const scrollAmount = 131 * 1.5 + 8;

const BillingSessionNav: FC<IBillingSessionNavProps> = ({}) => {
  const { drafts, cart, selectedSession, selectedServiceType } = useAppSelector(
    (state) => state.cart
  );
  const dispatch = useAppDispatch();
  const { categoryList } = usePricing();

  const hiddenBoxRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const params = useParams();
  const userId = Number(params.id);
  const filteredDraftedSessions = drafts.filter(
    (draft) => draft.customer.id === userId
  )[0]?.draftSessions;

  useEffect(() => {
    if (filteredDraftedSessions?.length > 0) {
      const loadSessions = async () => {
        for (let i = 0; i < filteredDraftedSessions?.length; i++) {
          // async(filteredDraftedSessions[i]) => {
          await dispatch(addSession(filteredDraftedSessions[i]));
          // await dispatch(
          //   CartSliceAction.discardDraftedSession(draftedSessions[i])
          // );
          // alert("added " + session.sessionId);
        }
        // dispatch(
        //   CartSliceAction.setSelectedSession(
        //     filteredDraftedSessions[filteredDraftedSessions.length - 1]
        //   )
        // );
        await dispatch(
          CartSliceAction.setSelectedServiceType(
            filteredDraftedSessions[filteredDraftedSessions?.length - 1]
              ?.cartItems[0]?.serviceType ?? categoryList?.body[0]
          )
        );
        await dispatch(CartSliceAction.discardCustomerDraft(userId));
      };
      loadSessions();
    } else {
      addNewSession();
    }
    return () => {
      // if there is no any drafted session, then clear the selected session and sessions
      dispatch(CartSliceAction.clearSession(null));
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (hiddenBoxRef.current) {
        const scrollLeft = hiddenBoxRef.current.scrollLeft;
        // const maxScroll =
        //   hiddenBoxRef.current.scrollWidth - hiddenBoxRef.current.clientWidth;
        setScrollPosition(scrollLeft);
      }
    };

    if (hiddenBoxRef.current) {
      hiddenBoxRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (hiddenBoxRef.current) {
        hiddenBoxRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const maxScroll = hiddenBoxRef.current
    ? hiddenBoxRef.current.scrollWidth - hiddenBoxRef.current.clientWidth
    : 1;

  const handleScrollLeft = () => {
    if (hiddenBoxRef.current) {
      const currentScrollLeft = hiddenBoxRef.current.scrollLeft;
      hiddenBoxRef.current.scrollTo({
        left: currentScrollLeft - scrollAmount,
        behavior: "smooth", // Use smooth behavior for scrolling
      });
    }
  };

  const handleScrollRight = () => {
    if (hiddenBoxRef.current) {
      const currentScrollLeft = hiddenBoxRef.current.scrollLeft;
      hiddenBoxRef.current.scrollTo({
        left: currentScrollLeft + scrollAmount,
        behavior: "smooth", // Use smooth behavior for scrolling
      });
    }
  };

  const handleOnSelectSession = (session: ICartSession) => {
    dispatch(CartSliceAction.setSelectedSession(session));

    // to prevent staying on current service type when switching sessions with other service types
    dispatch(
      CartSliceAction.setSelectedServiceType(
        session.cartItems[0]?.serviceType ||
          categoryList?.body.find((category) => category.showInMenu) // this is here to prevent, not selecting a service type when switching to previouse sessions and then again comming to new(latest) empty session
      )
    );
  };

  const handleOnClickNewSession = () => {
    let isEmptySession = true;
    cart.forEach((session) => {
      if (session.cartItems?.filter((item) => item.finalized)?.length > 0) {
        isEmptySession = false;
      } else {
        isEmptySession = true;
        return;
      }
    });

    if (isEmptySession) {
      alert(
        "You can't leave empty session and create a new one. Please add items to the empty session first."
      );
    } else {
      // This Condition is here to prevent creating new session when the selected service type is Alteration. Because Alteration tab can't directly access, it requires previous service type.
      if (selectedServiceType?.categoryName === "Alteration") {
        dispatch(
          CartSliceAction.setSelectedServiceType(
            categoryList?.body.find((category) => category.showInMenu)
          )
        );
      }
      addNewSession();
    }
  };

  const handleOnClickRemoveSession = () => {
    removeCartSession();
    setDeleteDialogOpen(false);
  };

  const addNewSession = () => {
    if (userId) {
      const uuId = uuidv4();
      const newSession: ICartSession = {
        sessionId: uuId,
        cartItems: [],
        customerId: userId,
      };
      dispatch(addSession(newSession));
    }
  };

  const removeCartSession = () => {
    dispatch(removeSession());
  };

  return (
    <Box style={{ position: "relative", height: "2.21rem" }}>
      <ConfirmDialog
        open={deleteDialogOpen}
        title="Delete Confirmation"
        message="Are you sure you want to remove this session?"
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirm={() => handleOnClickRemoveSession()}
      />
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          gap: "0.57rem",
          width: "100%",
        }}
      >
        <IconButton
          onClick={handleScrollLeft}
          style={{
            background:
              scrollPosition === 0
                ? Colors.ACCENT_GRAY_DISABLED
                : Colors.MAIN_GRAY,
            color: Colors.WHITE,
            borderRadius: "0.214285714rem",
            height: "2.21428571rem",
            aspectRatio: "24/31",
          }}
        >
          <ChevronLeftIcon style={{ fontSize: "1.78571429rem" }} />
        </IconButton>
        <Box
          ref={hiddenBoxRef}
          sx={{
            flexGrow: 1,
            overflowX: "auto",
            overflowY: "hidden",
            display: "flex",
            flexWrap: "nowrap",
            gap: "0.57rem",
            width: "100%",
            transition: "all 0.375s ease-in-out",
            "&::-webkit-scrollbar": {
              width: 0,
              height: 0,
            },
            scrollbarWidth: "none",
            "&::-webkit-scrollbar-thumb": {
              display: "none",
            },
          }}
        >
          {cart.map((session, i) => {
            return (
              <CustomButton
                sx={{
                  background:
                    selectedSession?.sessionId === session.sessionId
                      ? Colors.MAIN_GRAY
                      : Colors.ACCENT_GRAY_DISABLED,
                  color: Colors.WHITE,
                  borderRadius: "0.214285714rem",
                  height: "2.21428571rem",
                  fontSize: "1.14285714rem",
                  minWidth: "9.35714286rem",
                  maxWidth: "9.35714286rem",
                  width: "9.35714286rem",
                  padding: "0.428571429rem 0.5rem",
                  textTransform: "Capitalize",
                  "&:hover": {
                    background: `${
                      selectedSession?.sessionId === session.sessionId
                        ? Colors.MAIN_GRAY
                        : Colors.ACCENT_GRAY_DISABLED
                    } !important`,
                  },
                }}
                key={i}
                onClick={() => handleOnSelectSession(session)}
              >
                {session.cartItems[0]?.serviceType?.categoryName || "SESSION"}-
                {padStart((i + 1).toString(), 3, "0")}
              </CustomButton>
            );
          })}
        </Box>
        <IconButton
          onClick={handleScrollRight}
          style={{
            background:
              scrollPosition === maxScroll
                ? Colors.ACCENT_GRAY_DISABLED
                : Colors.MAIN_GRAY,
            color: Colors.WHITE,
            borderRadius: "0.214285714rem",
            height: "2.21428571rem",
            aspectRatio: "24/31",
          }}
        >
          <ChevronRightIcon style={{ fontSize: "1.78571429rem" }} />
        </IconButton>
        <IconButton
          style={{
            background: Colors.MAIN_GRAY,
            color: Colors.WHITE,
            borderRadius: "0.214285714rem",
            height: "2.21428571rem",
            aspectRatio: "1/1",
          }}
          onClick={() => setDeleteDialogOpen(true)}
          disabled={selectedSession === null}
        >
          <DeleteIcon style={{ fontSize: "1.53571429rem" }} />
        </IconButton>
        <IconButton
          style={{
            background: Colors.MAIN_GRAY,
            color: Colors.WHITE,
            borderRadius: "0.214285714rem",
            height: "2.21428571rem",
            aspectRatio: "1/1",
          }}
          onClick={handleOnClickNewSession}
        >
          <AddIcon style={{ fontSize: "1.78571429rem" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default BillingSessionNav;
