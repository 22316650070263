import { Box, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { Colors } from "../../../../configs";
import { Swiper, SwiperSlide } from "swiper/react";
import { CustomButton } from "../../../atoms";
import {
  ISelectedSpot,
  ISpot,
  spotPrices,
} from "../../../../typings/interfaces/pos/spot";
import SpotModal from "../../../pos/spot-modal/spotModal";
import { sortArray } from "../../../../util/array-functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { cloneDeep } from "lodash";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";

export interface ISpotSectionProps {}

const spotPricesSorted = sortArray({
  array: spotPrices,
  sortByKey: "label",
  fixedFirstItem: "Custom",
});

const SpotSection: FC<ISpotSectionProps> = () => {
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  const [spotModalOpen, setSpotModalOpen] = useState<{
    open: boolean;
    spot: ISpot | null;
  }>({
    open: false,
    spot: null,
  });

  const handleCloseSpotModal = () => {
    setSpotModalOpen({ open: false, spot: null });
  };
  const handleOnClickSpot = (spot: ISpot) => {
    setSpotModalOpen({ open: true, spot });
  };

  /*
    const handleAddUpcharge = (upcharge: ISelectedUpcharge) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      if (
        cartItem.upcharges.find(
          (upchargeItem) => upchargeItem.id === upcharge.id
        ) === undefined
      ) {
        // Add new upcharge
        cartItem.upcharges.push(upcharge);
      } else {
        // Update existing upcharge
        cartItem.upcharges = cartItem.upcharges.map((upchargeItem) =>
          upchargeItem.id === upcharge.id ? upcharge : upchargeItem
        );
      }
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };
  */

  const handleAddSpot = (spot: ISelectedSpot) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      if (
        cartItem.spots.find((spotItem) => spotItem.id === spot.id) === undefined
      ) {
        // Add new spot
        cartItem.spots.push(spot);
      } else {
        // Update existing spot
        cartItem.spots = cartItem.spots.map((spotItem) =>
          spotItem.id === spot.id ? spot : spotItem
        );
      }
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };
  const handleRemoveSpot = (spot: ISelectedSpot) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      cartItem.spots = cartItem.spots.filter(
        (spotItem) => spotItem.id !== spot.id
      );
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.71428571rem",
        background: Colors.BACKGROUND_GRAY,
        // background: "red",
        borderRadius: "0.71rem",
        padding: "0.928571429rem",
      }}
    >
      {spotModalOpen.spot && (
        <SpotModal
          open={spotModalOpen.open}
          onClose={handleCloseSpotModal}
          spot={spotModalOpen.spot}
          onAdd={handleAddSpot}
          onRemove={handleRemoveSpot}
        />
      )}

      <Typography
        sx={{ fontSize: "1.71428571rem", color: Colors.TEXT_GRAY_DARK }}
      >
        Spot
      </Typography>
      <Swiper
        style={{ width: "72rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(spotPricesSorted.length / 36))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  width: "72rem",
                  // height: "10.6428571rem",
                  // background: "red",
                  gap: "1.07142857rem",
                  rowGap: "0.857142857rem",
                  alignContent: "flex-start",
                  height: "46.7142857rem",
                }}
              >
                {spotPricesSorted
                  .slice(swipePageIndex * 36, (swipePageIndex + 1) * 36)
                  .map((spot, i) => (
                    <CustomButton
                      sx={{
                        width: "17.0064286rem",
                        // background:
                        //   (instruction as any).checked ||
                        //   selectedCartItem?.instruction.includes(
                        //     (instruction as any).label
                        //   )
                        //     ? Colors.SECONDARY
                        //     : Colors.MAIN_GRAY,
                        background:
                          (selectedCartItem?.spots.filter(
                            (cartSpot) => cartSpot.id === (spot as ISpot).id
                          ).length ?? 0) > 0
                            ? Colors.SECONDARY
                            : Colors.MAIN_GRAY,
                        borderRadius: "0.71rem",
                        textTransform: "capitalize",
                        color: Colors.WHITE,
                        fontSize: "1.14rem",
                        lineHeight: "1.33928571rem",
                        padding: "1.07142857rem 0.857142857rem",
                        "&&:hover": {
                          background: Colors.TEXT_GRAY_DARK,
                        },
                        height: "4.28571429rem",
                      }}
                      key={i}
                      onClick={() => handleOnClickSpot(spot as ISpot)}
                    >
                      {(spot as ISpot).label}
                    </CustomButton>
                  ))}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default SpotSection;
