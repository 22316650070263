import { createSlice } from "@reduxjs/toolkit";
import {
  BaseResponse,
  CustomerBillsResponse,
  ICustomerResponse,
} from "../../typings";
import {
  addStoreCreditAction,
  addUserNoteAction,
  deleteUserNoteAction,
  getCustomerBillHistoryAction,
  getCustomerByIdAction,
  getCustomerCartInfoByIdAction,
  getStoreCreditHistoryAction,
  getUserNotesByIdAction,
  updateUserNoteAction,
  deleteStoreCreditAction,
} from "../actions/customerAction";
import { CustomerCartInfoResponse } from "../../typings/interfaces/responses/customer-cart-info-response";
import { CustomerNoteResponse } from "../../typings/interfaces/responses/customer-note-response";
import { StoreCreditHistoryResponse } from "../../typings/interfaces/responses/store-credit-history-response";

export interface CustomerState {
  customer: ICustomerResponse | null;
  customerLoading: boolean;
  customerError: string | null;

  customerCartInfo: CustomerCartInfoResponse | null;
  customerCartInfoLoading: boolean;
  customerCartInfoError: string | null;

  createCustomerResponse: BaseResponse<ICustomerResponse> | null;
  createCustomerLoading: boolean;
  createCustomerError: string | null;

  updateCustomerResponse: BaseResponse<ICustomerResponse> | null;
  updateCustomerLoading: boolean;
  updateCustomerError: string | null;

  bills: Array<CustomerBillsResponse> | null;
  billsLoading: boolean;
  billsError: string | null;

  addStoreCreditResponse: BaseResponse<any> | null;
  addStoreCreditLoading: boolean;
  addStoreCreditError: string | null;

  deleteStoreCreditResponse: BaseResponse<any> | null;
  deleteStoreCreditLoading: boolean;
  deleteStoreCreditError: string | null;

  storeCreditHistoryResponse: BaseResponse<StoreCreditHistoryResponse> | null;
  getStoreCreditHistoryLoading: boolean;
  getStoreCreditHistoryError: string | null;

  addUserNoteResponse: BaseResponse<any> | null;
  addUserNoteLoading: boolean;
  addUserNoteError: string | null;

  updateUserNoteResponse: BaseResponse<any> | null;
  updateUserNoteLoading: boolean;
  updateUserNoteError: string | null;

  customerNotes: Array<CustomerNoteResponse> | null;
  customerNotesLoading: boolean;
  customerNotesError: string | null;

  deleteCustomerNoteResponse: BaseResponse<any> | null;
  deleteCustomerNoteLoading: boolean;
  deleteCustomerNoteError: string | null;

  selectedBill: CustomerBillsResponse | null;
}

const initialState: CustomerState = {
  customer: null,
  customerLoading: false,
  customerError: null,

  createCustomerResponse: null,
  createCustomerLoading: false,
  createCustomerError: null,

  updateCustomerResponse: null,
  updateCustomerLoading: false,
  updateCustomerError: null,

  customerCartInfo: null,
  customerCartInfoLoading: false,
  customerCartInfoError: null,

  bills: [],
  billsLoading: false,
  billsError: null,

  addStoreCreditResponse: null,
  addStoreCreditLoading: false,
  addStoreCreditError: null,

  deleteStoreCreditResponse: null,
  deleteStoreCreditLoading: false,
  deleteStoreCreditError: null,

  storeCreditHistoryResponse: null,
  getStoreCreditHistoryLoading: false,
  getStoreCreditHistoryError: null,

  addUserNoteResponse: null,
  addUserNoteLoading: false,
  addUserNoteError: null,

  updateUserNoteResponse: null,
  updateUserNoteLoading: false,
  updateUserNoteError: null,

  customerNotes: [],
  customerNotesLoading: false,
  customerNotesError: null,

  deleteCustomerNoteResponse: null,
  deleteCustomerNoteLoading: false,
  deleteCustomerNoteError: null,

  selectedBill: null,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    clearCustomer: (state: CustomerState) => {
      state.customerCartInfo = null;
      state.customer = null;
      state.customerError = null;
      state.customerLoading = false;
    },
    clearCustomerCartInfo: (state: CustomerState) => {
      state.customerCartInfo = null;
      state.customerCartInfoError = null;
      state.customerCartInfoLoading = false;
    },
    setSelectedBill: (state, action) => {
      state.selectedBill = action.payload;
    },
    clearCustomerNotes: (state: CustomerState) => {
      state.customerNotes = null;
      state.customerNotesError = null;
      state.customerNotesLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerByIdAction.rejected, (state: CustomerState) => {
      state.customer = null;
      state.customerError = "Customer fetching error";
      state.customerLoading = false;
    });
    builder.addCase(getCustomerByIdAction.pending, (state: CustomerState) => {
      state.customer = null;
      state.customerError = null;
      state.customerLoading = true;
    });
    builder.addCase(
      getCustomerByIdAction.fulfilled,
      (state: CustomerState, action) => {
        state.customer = action.payload;
        state.customerError = null;
        state.customerLoading = false;
      }
    );

    builder.addCase(
      getCustomerCartInfoByIdAction.rejected,
      (state: CustomerState) => {
        state.customerCartInfo = null;
        state.customerCartInfoError = "Customer's cart info fetching error";
        state.customerCartInfoLoading = false;
      }
    );
    builder.addCase(
      getCustomerCartInfoByIdAction.pending,
      (state: CustomerState) => {
        state.customerCartInfo = null;
        state.customerCartInfoError = null;
        state.customerCartInfoLoading = true;
      }
    );
    builder.addCase(
      getCustomerCartInfoByIdAction.fulfilled,
      (state: CustomerState, action) => {
        state.customerCartInfo = action.payload;
        state.customerCartInfoError = null;
        state.customerCartInfoLoading = false;
      }
    );

    builder.addCase(
      getCustomerBillHistoryAction.rejected,
      (state: CustomerState) => {
        state.bills = null;
        state.billsError = "Customer's bills fetching error";
        state.billsLoading = false;
      }
    );
    builder.addCase(
      getCustomerBillHistoryAction.pending,
      (state: CustomerState) => {
        state.bills = null;
        state.billsError = null;
        state.billsLoading = true;
      }
    );
    builder.addCase(
      getCustomerBillHistoryAction.fulfilled,
      (state: CustomerState, action) => {
        state.bills = action.payload;
        state.billsError = null;
        state.billsLoading = false;
      }
    );

    builder.addCase(addStoreCreditAction.rejected, (state: CustomerState) => {
      state.addStoreCreditResponse = null;
      state.addStoreCreditError = "Store credit adding error";
      state.addStoreCreditLoading = false;
    });
    builder.addCase(addStoreCreditAction.pending, (state: CustomerState) => {
      state.addStoreCreditResponse = null;
      state.addStoreCreditError = null;
      state.addStoreCreditLoading = true;
    });
    builder.addCase(
      addStoreCreditAction.fulfilled,
      (state: CustomerState, action) => {
        state.addStoreCreditResponse = action.payload;
        state.addStoreCreditError = null;
        state.addStoreCreditLoading = false;
      }
    );

    builder.addCase(
      deleteStoreCreditAction.rejected,
      (state: CustomerState) => {
        state.deleteStoreCreditResponse = null;
        state.deleteStoreCreditError = "Store credit deleting error";
        state.deleteStoreCreditLoading = false;
      }
    );
    builder.addCase(deleteStoreCreditAction.pending, (state: CustomerState) => {
      state.deleteStoreCreditResponse = null;
      state.deleteStoreCreditError = null;
      state.deleteStoreCreditLoading = true;
    });
    builder.addCase(
      deleteStoreCreditAction.fulfilled,
      (state: CustomerState, action) => {
        state.deleteStoreCreditResponse = action.payload as any;
        state.deleteStoreCreditError = null;
        state.deleteStoreCreditLoading = false;
      }
    );

    builder.addCase(
      getStoreCreditHistoryAction.rejected,
      (state: CustomerState) => {
        state.storeCreditHistoryResponse = null;
        state.getStoreCreditHistoryError =
          "Store credit history fetching error";
        state.getStoreCreditHistoryLoading = false;
      }
    );
    builder.addCase(
      getStoreCreditHistoryAction.pending,
      (state: CustomerState) => {
        state.storeCreditHistoryResponse = null;
        state.getStoreCreditHistoryError = null;
        state.getStoreCreditHistoryLoading = true;
      }
    );
    builder.addCase(
      getStoreCreditHistoryAction.fulfilled,
      (state: CustomerState, action) => {
        state.storeCreditHistoryResponse = action.payload;
        state.getStoreCreditHistoryError = null;
        state.getStoreCreditHistoryLoading = false;
      }
    );

    builder.addCase(addUserNoteAction.rejected, (state: CustomerState) => {
      state.addUserNoteResponse = null;
      state.addUserNoteError = "User note adding error";
      state.addUserNoteLoading = false;
    });
    builder.addCase(addUserNoteAction.pending, (state: CustomerState) => {
      state.addUserNoteResponse = null;
      state.addUserNoteError = null;
      state.addUserNoteLoading = true;
    });
    builder.addCase(
      addUserNoteAction.fulfilled,
      (state: CustomerState, action) => {
        state.addUserNoteResponse = action.payload;
        state.addUserNoteError = null;
        state.addUserNoteLoading = false;
      }
    );

    builder.addCase(updateUserNoteAction.rejected, (state: CustomerState) => {
      state.updateUserNoteResponse = null;
      state.updateUserNoteError = "Update user note failed";
      state.updateUserNoteLoading = false;
    });
    builder.addCase(updateUserNoteAction.pending, (state: CustomerState) => {
      state.updateUserNoteResponse = null;
      state.updateUserNoteError = null;
      state.updateUserNoteLoading = true;
    });
    builder.addCase(
      updateUserNoteAction.fulfilled,
      (state: CustomerState, action) => {
        state.updateUserNoteResponse = action.payload;
        state.updateUserNoteError = null;
        state.updateUserNoteLoading = false;
      }
    );

    builder.addCase(getUserNotesByIdAction.rejected, (state: CustomerState) => {
      state.customerNotes = null;
      state.customerNotesError = "User notes fetching error";
      state.customerNotesLoading = false;
    });
    builder.addCase(getUserNotesByIdAction.pending, (state: CustomerState) => {
      state.customerNotes = null;
      state.customerNotesError = null;
      state.customerNotesLoading = true;
    });
    builder.addCase(
      getUserNotesByIdAction.fulfilled,
      (state: CustomerState, action) => {
        state.customerNotes = action.payload;
        state.customerNotesError = null;
        state.customerNotesLoading = false;
      }
    );

    builder.addCase(deleteUserNoteAction.rejected, (state: CustomerState) => {
      state.deleteCustomerNoteResponse = null;
      state.deleteCustomerNoteError = "Delete user note failed";
      state.deleteCustomerNoteLoading = false;
    });
    builder.addCase(deleteUserNoteAction.pending, (state: CustomerState) => {
      state.deleteCustomerNoteResponse = null;
      state.deleteCustomerNoteError = null;
      state.deleteCustomerNoteLoading = true;
    });
    builder.addCase(
      deleteUserNoteAction.fulfilled,
      (state: CustomerState, action) => {
        state.deleteCustomerNoteResponse = action.payload;
        state.deleteCustomerNoteError = null;
        state.deleteCustomerNoteLoading = false;
      }
    );
  },
});

export default customerSlice.reducer;
export const CustomerSliceAction = customerSlice.actions;
