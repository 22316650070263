import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";
import { FC, forwardRef } from "react";
// import { Colors } from "../../../configs";

export interface ICheckBoxProps {
  label?: FormControlLabelProps["label"];
  checked?: boolean;
}

type CustomCheckBoxProps = ICheckBoxProps & CheckboxProps;

export const CustomCheckBox: FC<CustomCheckBoxProps> = forwardRef(
  (props, ref) => {
    const { label = "", checked = false, ...otherProps } = props;
    const inputRef = ref as React.RefObject<HTMLInputElement>;
    return (
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: "2rem",
                color: "secondary.main",
              },
            }}
            inputRef={inputRef}
            checked={checked}
            {...otherProps}
          />
        }
      />
    );
  }
);
