import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CHANGE_PASSWORD, LOGIN_URL, LOGIN_USERS } from "../../configs";
import configureAxios from "../../configs/axiosConfig";
import { ILoginRequest, IUserResponse } from "../../interfaces";
import { ILoginResponse, ITokenResponse } from "../../interfaces";
import { IChangePasswordRequest } from "../../interfaces/requests/ChangePasswordRequest";
import { IChangeePasswordResponse } from "../../interfaces/responces/ChangePasswordResponse";
import { BaseResponse } from "../../typings";
import { LoginUser } from "../../typings/interfaces/responses/LoginUser";

export const loginAction = createAsyncThunk(
  "auth/login",
  async (loginData: ILoginRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<ILoginResponse>(LOGIN_URL, loginData);
      const body = response.data.body;
      let token: ITokenResponse | null = null;
      let user: IUserResponse | null = null;
      if (body !== null) {
        token = body[0] as ITokenResponse;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token.token}`;
        user = body[1] as IUserResponse;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token.token);
        localStorage.removeItem("profile_lock");
      }
      return { user, token, response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const logoutAction = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      delete axios.defaults.headers.common["Authorization"];
    } catch (err) {}
  }
);

export const lockProfileAction = createAsyncThunk(
  "auth/lock",
  async (_, { rejectWithValue }) => {
    try {
      localStorage.setItem("profile_lock", "true");
    } catch (err) {}
  }
);

export const unlockProfileAction = createAsyncThunk(
  "auth/unlock",
  async (_, { rejectWithValue }) => {
    try {
      localStorage.removeItem("profile_lock");
    } catch (err) {}
  }
);

export const authDetailsInitializeAction = createAsyncThunk(
  "auth/initialize",
  async (_, { rejectWithValue }) => {
    try {
      const storedToken = localStorage.getItem("token");
      const storedUserData = localStorage.getItem("user");
      const profileLock = localStorage.getItem("profile_lock") === "true";

      if (storedToken != null) {
        configureAxios(storedToken);
      }

      let user: IUserResponse | null = JSON.parse(
        storedUserData || ""
      ) as unknown as IUserResponse;

      return {
        storedToken,
        user,
        userAvailable: storedToken !== null && user !== null,
        profileLock,
      };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const changePasswordAction = createAsyncThunk(
  "auth/password/change",
  async (changeData: IChangePasswordRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<IChangeePasswordResponse>(
        CHANGE_PASSWORD,
        changeData
      );
      const body = response.data.body;
      let token: ITokenResponse | null = null;
      let user: IUserResponse | null = null;
      if (body !== null) {
        token = body[0] as ITokenResponse;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token.token}`;
        user = body[1] as IUserResponse;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token.token);
      }
      return { user, token, response: response.data };
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const getLoginUserList = createAsyncThunk(
  "auth/getLoginUserList",
  async (tenant: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<LoginUser>>(
        LOGIN_USERS + "?tenant=" + tenant
      );
      return response.data.body;
    } catch (err) {}
  }
);
