import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Typography } from "@mui/material";
import { FC, useState } from "react";
import { Colors } from "../../../configs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import { CustomButton, CustomTextBox } from "../../atoms";
import "./notesCard.css";
import { NoteOccasion } from "../../../typings/enum/noteOccasions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useParams } from "react-router-dom";
import ConfirmDialog from "../../atoms/confirm-box/confirm-box";
import {
  deleteBillNoteAction,
  getBillNoteByIdAction,
  updateBillNoteAction,
} from "../../../redux/actions/cartAction";
import { CartSliceAction } from "../../../redux/slice/cartSlice";

export interface INotesCardProps {
  noteId: number;
  description: string;
  noteBy: string;
  date: Date | null;
  noteMode: "temp" | "perm";
}

const BillNotesCard: FC<INotesCardProps> = ({
  noteId,
  description,
  noteBy,
  date,
  noteMode,
}) => {
  const [note, setNote] = useState(description);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const params = useParams();
  const customerId = params.id;
  const { bill, temporaryBillNotes } = useAppSelector((state) => state.cart);
  const { user } = useAppSelector((state) => state.auth);

  interface IHandleExpandChangeProps {
    newExpanded?: boolean;
  }

  const handleExpandChange = ({
    newExpanded,
  }: IHandleExpandChangeProps = {}) => {
    if (newExpanded !== undefined) {
      setExpanded(newExpanded);
    } else {
      setExpanded((prevExpanded) => !prevExpanded);
    }
  };

  const handleNoteEdit = (newNote: string) => {
    setNote(newNote);
  };

  const handleOnUpdateNote = async () => {
    if (note.length > 0) {
      if (customerId && user) {
        if (bill !== null && Number(bill.id) > 0) {
          await dispatch(
            updateBillNoteAction({
              id: noteId,
              note: note,
              occasions: [] as NoteOccasion[],
              // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
              merchantUserId: 1,
              customerId: Number(customerId),
              billId: Number(bill.id),
            })
          ).then((response) => {
            // if (response) {
            //   console.log(JSON.stringify(response));
            // } else {
            //   alert("Response is null");
            // }
            dispatch(getBillNoteByIdAction({ billId: String(noteId) }));
          });
        } else {
          dispatch(
            CartSliceAction.setTemporaryBillNotes([
              ...temporaryBillNotes.filter((note) => note.tempId !== noteId),
              {
                tempId: noteId,
                note: note,
                merchantUserId: 1,
                // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
                merchantFirstName: user.firstName,
                merchantLastName: user.lastName,
                customerId: Number(customerId),
                occasions: [] as NoteOccasion[],
                date: new Date().toISOString(),
              },
            ])
          );
        }
      }
    } else {
      alert("Cannot add empty notes");
    }
  };

  const handleOnDeleteNote = async () => {
    if (customerId && user) {
      if (bill !== null && Number(bill.id) > 0) {
        await dispatch(deleteBillNoteAction(String(noteId))).then(
          (response) => {
            // if (response) {
            //   console.log(JSON.stringify(response));
            // } else {
            //   alert("Response is null");
            // }
          }
        );
        dispatch(getBillNoteByIdAction({ billId: String(bill.id) }));
      } else {
        dispatch(
          CartSliceAction.setTemporaryBillNotes([
            ...temporaryBillNotes.filter((note) => note.tempId !== noteId),
          ])
        );
      }
    }
  };

  return (
    <>
      <ConfirmDialog
        open={deleteConfirmation}
        title="Delete Confirmation"
        message={<Typography>Do you want to delete this note?</Typography>}
        positiveButtonText="Yes"
        negativeButtonText="No"
        onCancel={() => setDeleteConfirmation(false)}
        onConfirm={() => handleOnDeleteNote()}
      />
      <Box
        sx={{
          borderRadius: "0.714285714rem",
          padding: "1.35232143rem",
          background: Colors.BACKGROUND_GRAY,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "2.28571429rem",
          paddingLeft: "2.28571429rem",
          // marginTop: "0.91rem",
        }}
      >
        <IconButton
          sx={{
            background: Colors.SECONDARY,
            borderRadius: "0.303571429rem",
            width: "2.28571429rem",
            height: "2rem",
            boxShadow: `0rem 0.214285714rem 0.285714286rem rgba(0, 0, 0, 0.25)`,
            color: Colors.WHITE,
          }}
          onClick={() => setDeleteConfirmation(true)}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            gap: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.1rem",
              fontWeight: 500,
            }}
          >
            {description}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1.57142857rem",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: Colors.SECONDARY, marginRight: "auto" }}>
              Added by {noteBy}
            </Typography>
            <Box
              sx={{
                color: Colors.SECONDARY,
                gap: "0.714285714rem",
                alignItems: "center",
                display: date == null ? "none" : "flex",
              }}
            >
              <CalendarMonthIcon
                sx={{ display: date == null ? "none" : "inline-flex" }}
              />
              <Typography>{date?.toLocaleDateString("en-US")}</Typography>
            </Box>
            <IconButton
              sx={{
                width: "1.71428571rem",
                height: "1.71428571rem",
                borderRadius: "0.214285714rem",
                color: Colors.WHITE,
                background: Colors.SECONDARY,
              }}
              onClick={() => handleExpandChange()}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box
        className={
          expanded ? " collapsingSection" : "collapsedSection collapsingSection"
        }
        sx={{
          transition: "all 0.5s",
          marginBottom: "13.75rem",
          marginTop: "0.91rem",
          height: "8.17rem",
        }}
      >
        <Box
          sx={{
            borderRadius: "0.714285714rem",
            padding: "1.35232143rem",
            background: Colors.BACKGROUND_GRAY,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "1.86rem",
            marginBottom: "0.91rem",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <CustomTextBox
              multiline
              sx={{
                width: "100%",
                background: Colors.WHITE,
                border: "none",
                "& .MuiInputBase-input": {
                  height: "4.5rem",
                  minHeight: "4.5rem",
                  maxHeight: "4.5rem",
                },
              }}
              value={note}
              onChange={(event) => handleNoteEdit(event.target.value)}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "row",
              gap: "0.57rem",
              width: "100%",
            }}
          >
            <IconButton
              type="button"
              sx={{
                border: `1px solid ${Colors.MAIN_GRAY}`,
                borderRadius: "3px",
                background: "none",
                height: "2.62571429rem",
                aspectRatio: "1/1",
              }}
              onClick={() => setDeleteConfirmation(true)}
            >
              <DeleteIcon
                sx={{
                  color: Colors.MAIN_GRAY,
                  fontSize: "1.42857143rem",
                  marginTop: "-0.0714285714rem",
                }}
              />
            </IconButton>
            <CustomButton
              type="button"
              onClick={() => {
                handleExpandChange({ newExpanded: false });
              }}
              sx={{
                border: `1px solid ${Colors.MAIN_GRAY}`,
                borderRadius: "3px",
                background: "none",
                color: Colors.MAIN_GRAY,
                fontSize: "0.91rem",
                fontWeight: "600",
                padding: "0.46rem 2.31rem",
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="button"
              sx={{
                border: "none",
                borderRadius: "3px",
                background: "#018752",
                color: "#f8f8f8",
                fontSize: "0.91rem",
                fontWeight: "600",
                padding: "0.46rem 2.31rem",
              }}
              disabled={note.length === 0}
              onClick={handleOnUpdateNote}
            >
              Update
            </CustomButton>
          </Box>
        </Box>
        <Box
          sx={{
            width: "5.31rem",
            height: "0rem",
            borderTop: "0.142857143rem solid #d9d9d9",
            margin: "0.357142857rem auto",
          }}
        ></Box>
      </Box>
    </>
  );
};

export default BillNotesCard;
