export enum CustomizationTypes {
  INSTRUCTIONS = "INSTRUCTIONS",
  UPCHARGES = "UPCHARGES",
  SPOT = "SPOT",
  DAMAGE = "DAMAGE",
  // ADDONS = "ADDONS",
  // DISCOUNTS = "DISCOUNTS",
  // CHANGE_PRICE = "CHANGE_PRICE",
}

export const customizationTypesLabels = {
  [CustomizationTypes.INSTRUCTIONS]: "Instructions",
  [CustomizationTypes.UPCHARGES]: "Upcharges",
  [CustomizationTypes.SPOT]: "Spot",
  [CustomizationTypes.DAMAGE]: "Damage",
  // [CustomizationTypes.ADDONS]: "Addons",
  // [CustomizationTypes.DISCOUNTS]: "Discounts",
  // [CustomizationTypes.CHANGE_PRICE]: "Change Price",
};
