import React, { FC, useState, MouseEvent } from "react";
import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomButton } from "../../../../components";
import AddIcon from "@mui/icons-material/Add";
import { Colors } from "../../../../configs";
import { styled } from "@mui/material/styles";
import {
  ContentCopy,
  ContentCut,
  ContentPaste,
  EditOutlined,
  PanoramaFishEyeOutlined,
} from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Link, useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Cloud from "@mui/icons-material/Cloud";
import StorefrontIcon from "@mui/icons-material/Storefront";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import StoreIcon from "@mui/icons-material/Store";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

interface ICashiersPageProps {}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CashiersPage: FC<ICashiersPageProps> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openContextMenu = Boolean(anchorEl);
  const [contextMenuStore, setContextMenuStore] = useState<null | number>(null);
  const navigate = useNavigate();

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    merchant: number
  ) => {
    setAnchorEl(event.currentTarget);
    setContextMenuStore(merchant);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setContextMenuStore(null);
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          height: "57.5rem",
          marginTop: "4.35714286rem",
          paddingInline: "5rem",
          width: "100%",
          maxHeight: "57.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: Colors.TEXT_GRAY_DARK }}>
            Cashiers
          </Typography>
          <Link to="/admin/config/merchants/1/stores/1/cashiers/create">
            <CustomButton
              sx={{
                width: "11.0714286rem",
                height: "3.07142857rem",
              }}
            >
              Add New
            </CustomButton>
          </Link>
        </Box>

        <Box
          sx={{
            maxHeight: "51.42857143rem",
            width: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0.5rem",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Cashier Name</StyledTableCell>
                <StyledTableCell>Assigned To</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                {
                  id: 1,
                  cashierName: "Cashier 1",
                  cashierAssignedTo: "alex",
                },
                {
                  id: 2,
                  cashierName: "Cashier 2",
                  cashierAssignedTo: "Marie",
                },
              ].map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{row.cashierName}</StyledTableCell>
                  <StyledTableCell>{row.cashierAssignedTo}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={(e) => handleClick(e, row.id)}>
                      <MoreHorizIcon
                        sx={{ fontSize: "2rem" }}
                        color="primary"
                      />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openContextMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Cashier</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete Cashier</ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default CashiersPage;
