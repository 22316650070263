import { Box, IconButton, Modal, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../../configs";

export interface ICustomModalProps {
  title: string;
  onClose?(): void;
  openModal: boolean;
  children?: React.ReactNode;
  width?: string;
  height?: string;
  mode?: "modal" | "popup";
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  py: "3.99321429rem",
  px: "5.57142857rem",
};

const CustomModal: FC<ICustomModalProps> = ({
  title,
  onClose,
  openModal,
  children,
  width = "30%",
  height = "40%",
  mode = "modal",
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 999 }}
      hideBackdrop={false}
    >
      <Box sx={{ ...style, width, height }}>
        <Box
          sx={{
            display: mode === "popup" ? "none" : "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ fontWeight: "bold", color: Colors.TEXT_GRAY_DARK }}
          >
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            size="large"
            onClick={handleClose}
            sx={{ alignSelf: "flex-start", width: "2rem", height: "2rem" }}
          >
            <CloseIcon sx={{ fontSize: "1.75rem" }} />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
