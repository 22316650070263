import { FC } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";

import { Colors } from "../../../configs";

// import CheckInLayout from "../../../components/layouts/pos-layout/CheckInLayout";
import CustomerDetailSection from "../../../components/common/pos/customer-detail-section/customer-detail-section";
import OrderDetailSection from "../../../components/common/pos/order-detail-section/order-detail-section";
import CartSection from "../../../components/common/pos/cart-section/cart-section";
import CartQtyHandlerSection from "../../../components/common/pos/cart-qty-handler-section/cart-qty-handler-section";
import ServiceTabMenu from "../../../components/common/pos/service-tab-menu/service-tab-menu";
import GarmentTypeSection from "../../../components/common/pos/garment-type-section/garment-type-section";
import GarmentModificationSection from "../../../components/common/pos/garment-modification-section/garment-modification-section";
import MoreIcon from "../../../assets/images/more";
import GarmentDesignVariationSection from "../../../components/common/pos/garment-design-variation-section/garment-design-variation-section";
import { CustomButton, PosLayout } from "../../../components";

export interface IAlterationProps {}

const Alteration: FC<IAlterationProps> = () => {
  return (
    <PosLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "32px",
          height: "100%",
          gap: "13px",
        }}
      >
        <Box
          sx={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            height: "100%",
            maxHeight: "100% !important",
          }}
        >
          <CustomerDetailSection
            showAnalysisButton={true}
            showEditButton={true}
            showHistoryButton={true}
          />
          <CartSection onSelect={() => {}} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              flexShrink: 0,
            }}
          >
            <OrderDetailSection />
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.71rem" }}
            >
              <CartQtyHandlerSection />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "0.71rem",
                }}
              >
                <CustomButton
                  sx={{
                    background: Colors.MAIN_GRAY,
                    padding: "16.5px",
                    aspectRatio: "1/1",
                    borderRadius: "0.71rem",
                  }}
                >
                  <DeleteIcon sx={{ color: Colors.WHITE, fontSize: "25px" }} />
                </CustomButton>
                <CustomButton
                  sx={{
                    background: Colors.MAIN_GRAY,
                    color: Colors.WHITE,
                    fontSize: "20px",
                    padding: "16.5px",
                    flexGrow: 1,
                    borderRadius: "0.71rem",
                    textTransform: "capitalize",
                  }}
                >
                  Notes
                </CustomButton>
                <CustomButton
                  sx={{
                    background: Colors.MAIN_GRAY,
                    color: Colors.WHITE,
                    fontSize: "20px",
                    padding: "16.5px",
                    flexGrow: 1,
                    borderRadius: "0.71rem",
                    textTransform: "capitalize",
                  }}
                >
                  Customization
                </CustomButton>
              </Box>
              <CustomButton
                sx={{
                  width: "100%",
                  fontSize: "32px",
                  borderRadius: "0.71rem",
                  background: Colors.PRIMARY,
                  color: Colors.WHITE,
                  textTransform: "capitalize",
                  flexGrow: 1,
                }}
              >
                Check In
              </CustomButton>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "2px", background: Colors.ACCENT_GRAY }}></Box>
        <Box
          sx={{
            flex: 5,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Typography sx={{ fontSize: "2.57rem", color: "#4D4D4D" }}>
            Alteration
          </Typography>
          <ServiceTabMenu currentTab="Alteration" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.71rem",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flex: 8 }}>
              <GarmentTypeSection />
            </Box>
            <Box sx={{ flex: 4 }}>
              <GarmentModificationSection />
            </Box>
          </Box>
          <GarmentDesignVariationSection />
          <Box
            sx={{
              flexShrink: 1,
              background: Colors.BACKGROUND_GRAY,
              borderRadius: "0.71rem",
              padding: "13px",
            }}
          >
            <Typography sx={{ height: "48px" }}>
              Shorten - Plain Bottom, Taper - Knees Down, Sew - Seat Seam
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 13px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "35px",
              }}
            >
              <IconButton style={{ width: "4.59rem", borderRadius: "50%" }}>
                <MoreIcon color={Colors.ACCENT_GRAY_DISABLED} size={45} />
              </IconButton>
              <CustomButton
                sx={{
                  background: "none",
                  border: `3px solid ${Colors.TEXT_GRAY_DARK}`,
                  color: Colors.TEXT_GRAY_DARK,
                  fontSize: "36px",
                  lineHeight: "42px",
                  padding: "16.12px 42px",
                  borderRadius: "0.71rem",
                  textTransform: "capitalize",
                  fontWeight: 400,
                }}
              >
                Pickups
              </CustomButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "17px",
                height: "100%",
              }}
            >
              <IconButton
                sx={{
                  height: "100%",
                  aspectRatio: "1/1",
                  background: Colors.SECONDARY,
                  borderRadius: "0.71rem",
                }}
              >
                <ReplayIcon style={{ fontSize: "42px", color: Colors.WHITE }} />
              </IconButton>
              <CustomButton
                sx={{
                  background: Colors.PRIMARY,
                  color: Colors.WHITE,
                  fontSize: "36px",
                  lineHeight: "42px",
                  padding: "16.12px 42px",
                  borderRadius: "0.71rem",
                  textTransform: "capitalize",
                  fontWeight: 400,
                  height: "100%",
                  whiteSpace: "nowrap",
                  "&:hover": { background: `${Colors.PRIMARY} !important` },
                }}
              >
                Add Item
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </PosLayout>
  );
};

export default Alteration;
