import {FC} from "react";

export interface IMoreIconProps{
    color?: string,
    size?: number
}

const MoreIcon:FC<IMoreIconProps> = ({color="#D9D9D9", size=42}) => {
    
    const radius = (size/100)*15;
    return(
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={radius} cy={radius} r={radius} fill={color}/>
            <circle cx={(size-radius)} cy={radius} r={radius} fill={color}/>
            <circle cx={radius} cy={(size-radius)} r={radius} fill={color}/>
            <circle cx={(size-radius)} cy={(size-radius)} r={radius} fill={color}/>
        </svg>
    );
}

export default MoreIcon;