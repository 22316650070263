import React, { FC, useEffect } from "react";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import * as Yup from "yup";
import { Box, CircularProgress, Typography } from "@mui/material";
import { CustomButton, CustomTextBox } from "../../atoms";
import CustomDateTimePicker from "../../atoms/custom-date-time-picker/custom-date-time-picker";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, Resolver, useForm } from "react-hook-form";
import { CustomCheckBox } from "../../atoms/checkbox/custom-checkbox";
import { MultiTabSelector } from "../../atoms/multi-tab-selector/multi-tab-selector";
import { useAppDispatch } from "../../../hooks";
import { useAppSelector } from "../../../hooks";
import {
  addStoreConfig,
  getStoreConfig,
} from "../../../redux/actions/storeActions";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { storeSliceActions } from "../../../redux/slice/storeSlice";

interface IEditPickupTimeDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  storeId: number;
  merchantId: number;
}

const schema = Yup.object().shape({
  storeId: Yup.number().required("Store ID is required"),
  merchantId: Yup.number().required("Merchant ID is required"),

  pickupTime: Yup.string().required("General Pickup Time is required"),
  // .matches(/^([0-1]\d|2[0-3]):([0-5]\d)$/, "Invalid time format"),
  cutOffTime: Yup.string().required("General Check-in Time is required"),
  // .matches(/^([0-1]\d|2[0-3]):([0-5]\d)$/, "Invalid time format"),

  specialDaysToDeliver: Yup.number().required(
    "Special Days to Process is required"
  ),
  daysToDeliver: Yup.number().required("Special Days to Process is required"),
  workingDays: Yup.array().of(
    Yup.string().oneOf([
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY",
    ])
  ),
});

type FormData = Yup.InferType<typeof schema>;
const resolver: Resolver<FormData> = yupResolver(schema);

const EditPickupTimeDrawer: FC<IEditPickupTimeDrawerProps> = ({
  open,
  toggleDrawer,
  storeId,
  merchantId,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: resolver,
  });
  const dispatch = useAppDispatch();
  const { getStoreConfigResponse } = useAppSelector((state) => state.store);
  //   const getStoreConfigResponse = null;
  const { addStoreConfigResponse, addStoreConfigLoading, addStoreConfigError } =
    useAppSelector((state) => state.store);
  const { showSnackbar } = useSnackbarContext();

  const handleFormSubmit = (data: FormData) => {
    dispatch(
      addStoreConfig({
        storeId: data?.storeId,
        merchantId: data?.merchantId,
        pickupTime: data?.pickupTime,
        cutOffTime: data?.cutOffTime,
        specialDaysToDeliver: data?.specialDaysToDeliver,
        daysToDeliver: data?.daysToDeliver,
        workingDays: data?.workingDays as string[],
      })
    );
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      const firstErrorKey = Object.keys(errors)[0] as keyof typeof errors;
      showSnackbar(`Error: ${errors[firstErrorKey]?.message}`, "warning");
    }
  }, [errors]);

  useEffect(() => {
    if (storeId && merchantId) {
      dispatch(getStoreConfig({ storeId, merchantId }));
    }
  }, [storeId, merchantId, dispatch]);

  useEffect(() => {
    if (getStoreConfigResponse && getStoreConfigResponse?.body !== null) {
      setValue("storeId", getStoreConfigResponse.body?.storeId);
      setValue("merchantId", getStoreConfigResponse?.body?.merchantId);
      setValue("pickupTime", getStoreConfigResponse?.body?.pickupTime);
      setValue("cutOffTime", getStoreConfigResponse?.body?.cutOffTime);
      setValue(
        "specialDaysToDeliver",
        getStoreConfigResponse?.body?.specialDaysToDeliver
      );
      setValue("daysToDeliver", getStoreConfigResponse?.body?.daysToDeliver);
      setValue("workingDays", getStoreConfigResponse?.body?.workingDays);
    } else {
      setValue("storeId", storeId);
      setValue("merchantId", merchantId);
      setValue("pickupTime", "15:00:00");
      setValue("cutOffTime", "10:00:00");
      setValue("specialDaysToDeliver", 0);
      setValue("daysToDeliver", 2);
      setValue("workingDays", [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
      ]);
    }
  }, [getStoreConfigResponse, merchantId, setValue, storeId]);

  // useEffect(() => {
  //   if (getStoreConfigResponse) {

  //   } else {
  //     if (storeId && merchantId) {
  //       alert(3);
  //       dispatch(getStoreConfig({ storeId, merchantId }));
  //     } else {
  //       alert(4);
  //       showSnackbar("Store ID or Merchant ID is missing", "error");
  //     }
  //   }
  // }, [getStoreConfigResponse, storeId, merchantId]);

  // useEffect(() => {
  //   if (getStoreConfigResponse) {
  //     alert(JSON.stringify(getStoreConfigResponse));
  //   } else {
  //     alert("No response");
  //   }
  // }, [getStoreConfigResponse, storeId, merchantId]);

  useEffect(() => {
    if (addStoreConfigError) {
      showSnackbar(addStoreConfigError, "error");
    }
  }, [addStoreConfigError]);

  useEffect(() => {
    if (addStoreConfigResponse) {
      showSnackbar("Store config updated successfully", "success");
      dispatch(storeSliceActions.resetStoreConfig());
      toggleDrawer();
    }
  }, [addStoreConfigResponse]);

  return (
    <CustomDrawer
      isOpen={open}
      toggleDrawer={() => {
        dispatch(storeSliceActions.resetStoreConfig());
        toggleDrawer();
      }}
      sx={{ width: "34.2857143rem" }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "3.5rem",
          padding: "3rem",
        }}
        onSubmit={handleSubmit((data) => {
          handleFormSubmit(data);
        })}
      >
        <Controller
          name="storeId"
          control={control}
          defaultValue={storeId}
          render={({ field }) => (
            <input type="hidden" readOnly disabled {...field} />
          )}
        />
        <Controller
          name="merchantId"
          control={control}
          defaultValue={merchantId}
          render={({ field }) => (
            <input type="hidden" readOnly disabled {...field} />
          )}
        />
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Store Pickup Time Config
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Typography variant="body1" sx={{ width: "12.5rem" }}>
                Check-in Cut-off Time:
              </Typography>
              <Controller
                name="cutOffTime"
                control={control}
                render={({ field }) => (
                  <CustomDateTimePicker
                    type="time"
                    value={field.value ? dayjs(field.value, "HH:mm:ss") : null} // Convert string to Dayjs
                    onChange={(date) =>
                      field.onChange(date ? date.format("HH:mm:ss") : "")
                    } // Convert Dayjs back to string
                    sx={{ width: "10rem" }}
                  />
                )}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Typography variant="body1" sx={{ width: "12.5rem" }}>
                Pickup Time:
              </Typography>
              <Controller
                name="pickupTime"
                control={control}
                render={({ field }) => (
                  <CustomDateTimePicker
                    type="time"
                    value={field.value ? dayjs(field.value, "HH:mm:ss") : null} // Convert string to Dayjs
                    onChange={(date) =>
                      field.onChange(date ? date.format("HH:mm:ss") : "")
                    } // Convert Dayjs back to string
                    sx={{ width: "10rem" }}
                  />
                )}
              />
            </Box>
            <Box
              sx={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}
            >
              <Typography variant="body1" sx={{ width: "12.5rem" }}>
                Working Days:
              </Typography>
              <Controller
                name="workingDays"
                control={control}
                render={({ field, fieldState }) => (
                  <MultiTabSelector
                    items={[
                      { value: "MONDAY", label: "Monday" },
                      { value: "TUESDAY", label: "Tuesday" },
                      { value: "WEDNESDAY", label: "Wednesday" },
                      { value: "THURSDAY", label: "Thursday" },
                      { value: "FRIDAY", label: "Friday" },
                      { value: "SATURDAY", label: "Saturday" },
                      { value: "SUNDAY", label: "Sunday" },
                    ]}
                    value={field.value as string[]}
                    mode="multiple"
                    layout="vertical"
                    onChange={(values) => field.onChange(values)}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Days to Process:
            </Typography>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Typography variant="body1" sx={{ width: "12.5rem" }}>
                General Service:
              </Typography>
              <Controller
                name="daysToDeliver"
                control={control}
                render={({ field }) => (
                  <CustomTextBox
                    type="number"
                    value={field.value}
                    onChange={field.onChange}
                    sx={{ width: "10rem" }}
                  />
                )}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Typography variant="body1" sx={{ width: "12.5rem" }}>
                Special Service:
              </Typography>
              <Controller
                name="specialDaysToDeliver"
                control={control}
                render={({ field }) => (
                  <CustomTextBox
                    type="number"
                    value={field.value}
                    onChange={field.onChange}
                    sx={{ width: "10rem" }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <CustomButton
          sx={{ fontSize: "1.5rem", paddingBlock: "0.5rem" }}
          type="submit"
          disabled={addStoreConfigLoading}
        >
          {addStoreConfigLoading ? <CircularProgress /> : "Save Config"}
        </CustomButton>
      </form>
    </CustomDrawer>
  );
};

export { EditPickupTimeDrawer };
