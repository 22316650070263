import { useEffect, useState } from "react";

const useBarcodeScanner = (): string | null => {
  const [scannedBarcode, setScannedBarcode] = useState<string | null>("");

  useEffect(() => {
    setScannedBarcode(null);
  }, [scannedBarcode]);

  const handleDefocus = () => {
    const activeElement = document.activeElement as HTMLInputElement;

    if (activeElement && typeof activeElement.blur === 'function') {
      activeElement.blur();
    }
  };

  useEffect(() => {
    let keyCode = "";
    let lastTime: number = 0;
    const handleBarcodeInput = (event: KeyboardEvent): void => {
      const currentTime = new Date().getTime();
      
      if (event.key !== "Enter") {
        if (lastTime !== 0) {
            const diff = currentTime - lastTime;
          if (diff > 1000) {
            keyCode = "";
          } else if (diff > 50) {
            lastTime = 0;
            keyCode = "";
            keyCode = event.key;
            return;
          }
        }
        lastTime = currentTime;
        keyCode = keyCode + event.key;
      } else {
        if (lastTime !== 0) {
            const diff = currentTime - lastTime;
          if ((diff < 50)) {
            setScannedBarcode(keyCode);
            handleDefocus()
            lastTime = 0;
            keyCode = "";
          }
        }
      }
    };

    window.addEventListener("keydown", handleBarcodeInput);

    return () => {
      window.removeEventListener("keydown", handleBarcodeInput);
    };
  }, []);

  return scannedBarcode;
};

export default useBarcodeScanner;
