import { createSlice } from "@reduxjs/toolkit";
import { Service } from "../../typings/enum/Service";

export interface PosStet {
  activeServiceTab: Service;
}

const initialState: PosStet = {
  activeServiceTab: Service.GARMENT_WASH_AND_CLEAN,
};

const psSlice = createSlice({
  name: "pos",
  initialState,
  reducers: {
    setActiveServiceTab: (state, action) => {
      state.activeServiceTab = action.payload;
    }
  },
  extraReducers: (builder) => {
   
  },
});

export default psSlice.reducer;
export const PosSliceAction = psSlice.actions;
