import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const data = [
  {
    date: "2022-01-01",
    Dryclean: 5,
    Laundary: 7,
    Alteration: 3,
    Household: 2,
  },
  {
    date: "2022-01-02",
    Dryclean: 8,
    Laundary: 6,
    Alteration: 2,
    Household: 1,
  },
  {
    date: "2022-01-03",
    Dryclean: 10,
    Laundary: 9,
    Alteration: 4,
    Household: 3,
  },
  // Add more data here
];

const BarChart = () => {
  return (
    <ResponsiveBar
      data={data}
      keys={["Dryclean", "Laundary", "Alteration", "Household"]}
      indexBy="date"
      groupMode="grouped"
      margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
      padding={0.3}
      colors={{ scheme: "category10" }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom",
          direction: "row",
          justify: true,
          translateX: 0,
          translateY: 50,
          itemsSpacing: 20,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      // motionStiffness={90}
      // motionDamping={15}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Sales per day",
        legendPosition: "middle",
        legendOffset: -40,
        // Set border dash array to create dotted lines
        // borderDash: "1 1",
      }}
    />
  );
};

export default BarChart;
