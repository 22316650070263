import { Box, Tab, Tabs, Typography } from "@mui/material";
import { FC, useState } from "react";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ICustomTab {
  label: string;
  content: JSX.Element;
  disabled?: boolean;
}
export interface ICustomTabContainerProps {
  tabs: ICustomTab[];
  outsideConrol?: {
    selectedTab: number;
    changeTab: (tab: number) => void;
  };
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const CustomTabContainer: FC<ICustomTabContainerProps> = ({
  tabs,
  outsideConrol = null,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    if (outsideConrol) {
      outsideConrol.changeTab(newValue);
    } else {
      setSelectedTab(newValue);
    }
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={outsideConrol ? outsideConrol.selectedTab : selectedTab}
          onChange={handleChangeTab}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              disabled={tab.disabled}
              label={tab.label}
              sx={{ textTransform: "capitalize", fontSize: "1.5rem" }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <CustomTabPanel
          value={outsideConrol ? outsideConrol.selectedTab : selectedTab}
          index={index}
        >
          {tab.content}
        </CustomTabPanel>
      ))}
    </>
  );
};

export default CustomTabContainer;
