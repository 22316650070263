import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Colors } from "../../../configs/colors";
import {
  CardIcon,
  CashIcon,
  CheckIcon,
  MonthlyIcon,
  PayLaterIcon,
} from "../../../assets/icons/paymentTypes";
import ReplayIcon from "@mui/icons-material/Replay";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { IPaymentModalData } from "./checkinSummary";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { CustomButton } from "../../atoms";
import { PaymentTypeEnum } from "../../../typings/enum/paymentTypes";
import useCart from "../../../hooks/useCart";
import CashValues from "../../atoms/cash-value/cash-value";
import { CashSection } from "./sections/cash";
import { CardSection } from "./sections/card";
import { CheckSection } from "./sections/check";
import { StoreCreditSection } from "./sections/storeCredit";
import MonthlySection from "./sections/monthly";
import { formatCurrency } from "../../../util/common-functions";
import { IOSSwitch } from "../../atoms/custom-toggle-button/custom-toggle-button";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import {
  addStoreCreditAction,
  getCustomerByIdAction,
  getStoreCreditHistoryAction,
} from "../../../redux/actions/customerAction";
import LaterSection from "./sections/later";

export interface IPaymentProps {
  onCheckout(data: IPaymentModalData | null): void;
  onPaymentTypeChange(paymentType: PaymentTypeEnum): void;
  paymentType: PaymentTypeEnum;
}
interface IPaymentTypeProps {
  value: string;
  label: string;
  icon: "CASH" | "CARD" | "CHECK" | "MONTHLY" | "LATER" | "STORE";
  width?: string;
}

export const PaymentType: FC<IPaymentTypeProps> = ({
  value,
  label,
  icon,
  width = "auto",
}) => {
  const icons = {
    CASH: <CashIcon color={Colors.MAIN_GRAY} />,
    CARD: <CardIcon color={Colors.MAIN_GRAY} />,
    CHECK: <CheckIcon color={Colors.MAIN_GRAY} />,
    MONTHLY: <MonthlyIcon color={Colors.MAIN_GRAY} />,
    LATER: <PayLaterIcon color={Colors.MAIN_GRAY} />,
    STORE: <PayLaterIcon color={Colors.MAIN_GRAY} />,
  };
  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={
        <Box
          sx={{
            width: width,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.7rem",
          }}
        >
          {icons[icon]}
          <Typography
            sx={{
              fontSize: "1.26214286rem",
              color: Colors.TEXT_GRAY_DARK,
              fontWeight: 500,
            }}
          >
            {label}
          </Typography>
        </Box>
      }
      sx={{
        p: "0.15rem 0.714285714rem",
        paddingTop: "0.55rem",
        background: Colors.WHITE,
        borderRadius: "0.394285714rem",
        border: `0.0785714286rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
        minWidth: "8.95714286rem",
        minHeight: "6.34357143rem",
        flex: 1,
        margin: "0 !important",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "&>span:last-child": {
          mx: "auto !important",
        },
        "&>span:first-child": {
          padding: "0.2rem !important",
        },
      }}
    />
  );
};

// export const CardSection: FC<{ billValue?: number }> = ({ billValue }) => {

const Payment: FC<IPaymentProps> = ({
  onCheckout,
  onPaymentTypeChange,
  paymentType,
}) => {
  const { billLoading, paymentHalfDetails } = useAppSelector(
    (state) => state.cart
  );
  const { cartTotal, totalDiscountAmount } = useCart();
  const billTotal = cartTotal - totalDiscountAmount;
  const [customBillAmount, setCustomBillAmount] = useState(billTotal ?? 0);
  const [paidAmount, setPaidAmount] = useState(0);
  const remainAmount =
    Number(paymentHalfDetails.fullpaymentAmount) -
    Number(paymentHalfDetails.halfPaymentAmount);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    console.log(`Paid Amount: ${paidAmount}`);
  }, [paidAmount]);
  useEffect(() => {
    console.log(`Bill Amount: ${customBillAmount}`);
  }, [customBillAmount]);

  const [saveBalanceToCredits, setSaveBalanceToCredits] = useState(
    paymentType === PaymentTypeEnum.STORE_CREDIT ||
      paymentType === PaymentTypeEnum.LATER ||
      paymentType === PaymentTypeEnum.MONTHLY ||
      (paymentType === PaymentTypeEnum.CASH && balance <= 0) ||
      (paymentType === PaymentTypeEnum.CARD && balance <= 0)
      ? false
      : false
  );

  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbarContext();
  const { customer } = useAppSelector((state) => state.customer);
  const { user } = useAppSelector((state) => state.auth);
  const { addStoreCreditLoading } = useAppSelector((state) => state.customer);

  const amountInputRefs = {
    [PaymentTypeEnum.CASH]: useRef(null),
    [PaymentTypeEnum.CARD]: useRef(null),
    [PaymentTypeEnum.CHECK]: useRef(null),
    [PaymentTypeEnum.MONTHLY]: useRef(null),
    [PaymentTypeEnum.LATER]: useRef(null),
    [PaymentTypeEnum.STORE_CREDIT]: useRef(null),
  };

  const handlePaymentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onPaymentTypeChange(event.target.value as PaymentTypeEnum);
  };

  const handleOnBillAmountChange = (value: number) => {
    setCustomBillAmount(value);
  };

  const handleOnPaidAmountChange = (value: number) => {
    setPaidAmount(value);
  };

  // const handleAddStoreCredit = async () => {
  //   if (balance > 0) {
  //     if (user && customer) {
  //       await dispatch(
  //         addStoreCreditAction({
  //           storeUserId: user.id,
  //           customerId: customer.id,
  //           paymentType: paymentType,
  //           amount: balance,
  //         })
  //       ).then((res: any) => {
  //         if (res.payload.status === "SUCCESS") {
  //           dispatch(getCustomerByIdAction(String(customer.id)));
  //           showSnackbar("Store Credit added successfuly", "success");
  //         } else {
  //           showSnackbar(res.payload.message, "error");
  //         }
  //       });
  //     } else {
  //       showSnackbar("Customer not found", "warning");
  //     }
  //   } else {
  //     showSnackbar("Please check the balance again!", "warning");
  //   }
  // };

  const handleOnCheckout = () => {
    if (paymentType === PaymentTypeEnum.STORE_CREDIT) {
      if (paidAmount > 0) {
        if (user && customer) {
          dispatch(
            addStoreCreditAction({
              storeUserId: user.id,
              customerId: customer.id,
              paymentType: paymentType,
              amount: 0 - paidAmount,
            })
          ).then((res: any) => {
            if (res.payload.status === "SUCCESS") {
              dispatch(getCustomerByIdAction(String(customer.id)));
            } else {
              showSnackbar(res.payload.message, "error");
            }
          });
        } else {
          showSnackbar("Customer not found", "warning");
        }
      } else {
        showSnackbar("Please enter the amount to pay", "warning");
      }
    }
    if (saveBalanceToCredits) {
      if (balance > 0) {
        if (user && customer) {
          dispatch(
            addStoreCreditAction({
              storeUserId: user.id,
              customerId: customer.id,
              paymentType: paymentType,
              amount: balance,
            })
          ).then((res: any) => {
            if (res.payload.status === "SUCCESS") {
              dispatch(getCustomerByIdAction(String(customer?.id)));
              customer && dispatch(getStoreCreditHistoryAction(customer.id));
              // alert("Store Credit added successfully : " + balance);
              showSnackbar("Store Credit added successfully", "success");
            } else {
              showSnackbar(res.payload.message, "error");
            }
          });
        } else {
          showSnackbar("Customer not found to save store credit", "error");
        }
      } else {
        showSnackbar("Please check the balance again!", "warning");
      }
    }
    // alert(`Payment Type: ${paymentType}\nPaid Amount: ${paidAmount}`);
    onCheckout({
      paymentMode: paymentType,
      paidAmount: paidAmount,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4.03571429rem",
        justifyContent: "space-between",
        height: "50.8571429rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1.57142857rem",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            background: Colors.BACKGROUND_GRAY,
            borderRadius: "0.39428571428rem",
            p: "1.2rem 2.14285714rem",
            //   width: "53.8571429rem",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "0.75rem",
            height: "13.0714286rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.71428571rem",
              fontWeight: "bold",
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            Payment Type
          </Typography>
          <FormControl>
            <RadioGroup
              value={paymentType}
              onChange={handlePaymentTypeChange}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.75rem",
                justifyContent: "space-between",
                //   width: "49.5714286rem",
                width: "100%",
              }}
            >
              <PaymentType
                value={PaymentTypeEnum.CARD}
                label="Card"
                icon="CARD"
                width="12.5rem"
              />
              <PaymentType
                value={PaymentTypeEnum.CASH}
                label="Cash"
                icon="CASH"
                width="12.5rem"
              />
              <PaymentType
                value={PaymentTypeEnum.CHECK}
                label="Check"
                icon="CHECK"
                width="12.5rem"
              />
              <PaymentType
                value={PaymentTypeEnum.MONTHLY}
                label="Monthly"
                icon="MONTHLY"
                width="12.5rem"
              />
              <PaymentType
                value={PaymentTypeEnum.LATER}
                label="Later"
                icon="LATER"
                width="12.5rem"
              />
              <PaymentType
                value={PaymentTypeEnum.STORE_CREDIT}
                label="Store Credit"
                icon="STORE"
                width="12.5rem"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      {/* {PayOptionSections[paymentType]} */}

      {paymentType === PaymentTypeEnum.CARD && (
        <CardSection
          billValue={billTotal}
          paidAmount={paidAmount}
          returnPaidAmount={(value) => handleOnPaidAmountChange(value)}
          ref={amountInputRefs[PaymentTypeEnum.CARD]}
          returnBalance={setBalance}
        />
      )}
      {paymentType === PaymentTypeEnum.CASH && (
        <CashSection
          billValue={billTotal}
          returnPaidAmount={(value) => handleOnPaidAmountChange(value)}
          balance={balance}
          returnBalance={setBalance}
          saveBalanceToCredits={saveBalanceToCredits}
        />
      )}

      {paymentType === PaymentTypeEnum.CHECK && (
        <CheckSection
          billValue={customBillAmount}
          paidAmount={paidAmount}
          ref={amountInputRefs[PaymentTypeEnum.CHECK]}
          returnBalance={setBalance}
          returnPaidAmount={(value) => handleOnPaidAmountChange(value)}
        />
      )}

      {paymentType === PaymentTypeEnum.MONTHLY && (
        <MonthlySection
          billValue={customBillAmount}
          returnPaidAmount={(value) => handleOnPaidAmountChange(value)}
        />
      )}

      {paymentType === PaymentTypeEnum.LATER && (
        <LaterSection
          billValue={customBillAmount}
          returnPaidAmount={(value) => handleOnPaidAmountChange(value)}
        />
      )}

      {paymentType === PaymentTypeEnum.STORE_CREDIT && (
        <StoreCreditSection
          billValue={billTotal}
          paidAmount={paidAmount}
          returnPaidAmount={(value) => handleOnPaidAmountChange(value)}
          ref={amountInputRefs[PaymentTypeEnum.STORE_CREDIT]}
          paymentType="withdraw"
          readonly={true}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.57142857rem",
          mt: "-2rem",
        }}
      >
        <FormControlLabel
          checked={
            paymentType === PaymentTypeEnum.STORE_CREDIT ||
            paymentType === PaymentTypeEnum.LATER ||
            paymentType === PaymentTypeEnum.MONTHLY ||
            (paymentType === PaymentTypeEnum.CASH && balance <= 0) ||
            (paymentType === PaymentTypeEnum.CHECK && balance <= 0) ||
            (paymentType === PaymentTypeEnum.CARD && balance <= 0)
              ? false
              : saveBalanceToCredits
          }
          onChange={() => setSaveBalanceToCredits(!saveBalanceToCredits)}
          control={<IOSSwitch />}
          label={
            <Typography
              sx={{
                fontSize: "1.42857143rem",
                lineHeight: "1.71428571rem",
                fontWeight: 400,
                mr: "0.964285714rem",
              }}
            >
              Save balance to store credits
            </Typography>
          }
          labelPlacement="start"
          sx={{
            display:
              paymentType === PaymentTypeEnum.STORE_CREDIT ||
              paymentType === PaymentTypeEnum.LATER ||
              paymentType === PaymentTypeEnum.MONTHLY
                ? "none"
                : "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
          disabled={
            paymentType === PaymentTypeEnum.STORE_CREDIT ||
            paymentType === PaymentTypeEnum.LATER ||
            paymentType === PaymentTypeEnum.MONTHLY ||
            (paymentType === PaymentTypeEnum.CASH && balance <= 0) ||
            (paymentType === PaymentTypeEnum.CHECK && balance <= 0) ||
            (paymentType === PaymentTypeEnum.CARD && balance <= 0)
          }
        />
        <Box
          sx={{ display: "flex", flexDirection: "row", gap: "1.37678571rem" }}
        >
          <Box
            sx={{
              background: Colors.BACKGROUND_GRAY,
              padding: "2.14285714rem 1.80071429rem",
              height: "5.85714286rem",
              borderRadius: "0.571428571rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "24.6946457rem",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: Colors.TEXT_GRAY_DARK,
                fontSize: "1.42857143rem",
              }}
            >
              Net Total:
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                color: Colors.TEXT_GRAY_DARK,
                fontSize: "1.71428571rem",
              }}
            >
              {formatCurrency({
                amount: customBillAmount,
                prefix: "$",
              })}
            </Typography>
          </Box>
          <Box
            sx={{
              background: Colors.BACKGROUND_GRAY,
              padding: "2.14285714rem 1.80071429rem",
              height: "5.85714286rem",
              borderRadius: "0.571428571rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "24.6946457rem",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: Colors.TEXT_GRAY_DARK,
                fontSize: "1.42857143rem",
              }}
            >
              Remaining:
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                color: Colors.TEXT_GRAY_DARK,
                fontSize: "1.71428571rem",
              }}
            >
              {formatCurrency({
                amount: remainAmount === 0 ? customBillAmount : remainAmount,
                prefix: "$",
              })}
            </Typography>
          </Box>
          <CustomButton
            onClick={handleOnCheckout}
            sx={{
              background: Colors.PRIMARY,
              color: Colors.WHITE,
              textTransform: "capitalize",
              height: "5.85714286rem",
              fontSize: "2.57142857rem",
              width: "32.9285714rem",
              fontWeight: "bold",
              borderRadius: "0.714285714rem",
              "&:hover": {
                background: Colors.PRIMARY,
              },
            }}
          >
            {billLoading || addStoreCreditLoading ? (
              <CircularProgress size={30} color="inherit" />
            ) : (
              "Checkout"
            )}
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Payment;
