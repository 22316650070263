import { Colors } from "../configs/colors";
import { monthNames } from "../data";

export const getTenantId = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");

  // Check if there are at least three parts in the hostname (subdomain, domain, TLD)
  if (parts.length >= 2) {
    const subdomain = parts[0];

    // Replace this with your own validation logic to ensure a valid tenant ID
    if (subdomain && subdomain !== "www") {
      return subdomain;
    }
  }

  return null;
};
export const formatPhoneNumber = (
  phoneNumberString: string | undefined | null
) => {
  if (
    phoneNumberString === null ||
    phoneNumberString === "" ||
    phoneNumberString === undefined
  ) {
    return "N/A";
  }

  const digitsOnly = phoneNumberString.replace(/\D/g, "");

  if (digitsOnly.length < 10) {
    return "invalid Phone Number";
  }

  if (digitsOnly.length === 10) {
    return `${digitsOnly.slice(0, 3)} ${digitsOnly.slice(
      3,
      6
    )} ${digitsOnly.slice(6, 10)}`;
  }

  return `${digitsOnly.slice(1, 4)} ${digitsOnly.slice(
    4,
    7
  )} ${digitsOnly.slice(7, 11)}`;
};

export const formatDate = (dateString: string | undefined) => {
  if (dateString === null || dateString === "" || dateString === undefined) {
    return "N/A";
  }
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US");
};

export const formatDateTime = (dateString: string | undefined) => {
  if (dateString === null || dateString === "" || dateString === undefined) {
    return "N/A";
  }
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true, // This will format the time in 12-hour format with AM/PM
  });
};

export const padText = (
  text: number | string,
  padTextCount: number,
  direction: "end" | "start" = "end",
  fillText: string = " "
) => {
  if (direction === "end") {
    return text.toString().padEnd(padTextCount, fillText);
  } else {
    return text.toString().padStart(padTextCount, fillText);
  }
};

export const getAccentColor = (bgColor: string) => {
  const rgb = parseInt(bgColor.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = rgb & 0xff;

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? Colors.BLACK : Colors.WHITE;
};

export function formatDateNow() {
  const date = new Date();

  // Formatting the date components
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  function getMonthName(month: any) {
    return monthNames[month - 1];
  }

  // Creating the formatted date string
  const formattedDate = `${day}-${getMonthName(
    month
  )}-${year} ${hours}.${minutes}${ampm}`;

  return formattedDate;
}

export const formatUSDate = (date: Date) => {
  return date.toLocaleDateString("en-US");
};
export const formatUS12HourTime = (date: Date): string => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();

  let formattedTime = `${hours}:${minutesStr} ${ampm}`;
  return formattedTime;
};

interface IFormatCurrencyProps {
  amount: number;
  prefix?: string;
}

export const formatCurrency = ({ amount, prefix }: IFormatCurrencyProps) => {
  const newPrefix = prefix || "";
  const formattedAmount: string = amount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `${newPrefix}${formattedAmount}`;
};

export const handleTabAndEnter = (
  refs: React.RefObject<HTMLInputElement>[],
  event: any,
  index: number
) => {
  if (
    event.type === "blur" ||
    (event.type === "keydown" &&
      (event.key === "Enter" ||
        event.key === "Tab" ||
        event.key === "ArrowDown"))
  ) {
    event.preventDefault();
    let nextIndex;
    if (refs.length - 1 === index) {
      nextIndex = index;
    } else {
      nextIndex = index + 1;
      if (refs.length - 1 === nextIndex) return;
      while (refs[nextIndex].current?.disabled) {
        // In here !== false is used to prevent malfunction when disabled is undefined
        if (refs.length - 1 === nextIndex) break;
        nextIndex++;
      }
    }
    console.log(
      `${nextIndex} ${refs[nextIndex].current?.disabled} ${refs[nextIndex].current}`
    );
    if (refs[nextIndex]?.current?.disabled) return;
    refs[nextIndex]?.current?.focus();
  }
};
