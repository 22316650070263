// useUpdateTitle.ts
import { useEffect } from 'react';
import { useTranslation } from '../util/translation-provider';

const useUpdateTitle = (tenantId: string | null) => {
const translations = useTranslation();

  useEffect(() => {
    const defaultTitle = 'Multi-Tenant Laundry Pos';

    if (tenantId) {
      // Update the title based on the tenant ID
      document.title = `POS - ${translations.companyName}`;
    } else {
      // If no tenant ID is provided, use the default title
      document.title = defaultTitle;
    }
  }, [tenantId]);
};

export default useUpdateTitle;
