import React, { createContext, useState, useContext, ReactNode } from "react";
import NotePopup from "../components/pos/note-popup/notePopup";
import { NoteOccasion } from "../typings/enum/noteOccasions";
import { CustomerNoteResponse } from "../typings/interfaces/responses/customer-note-response";

interface UserNoteContextType {
  showNoteModal: boolean;
  showNote: (ocassion: NoteOccasion, userNotes: CustomerNoteResponse[]) => void;
}

const UserNoteContext = createContext<UserNoteContextType | undefined>(
  undefined
);

const UserNoteProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [noteContent, setNoteContent] = useState<any>(null);

  const handleOnClose = () => {
    setShowNoteModal(false);
  };

  const showNote = (
    ocassion: NoteOccasion,
    userNotes: CustomerNoteResponse[]
  ) => {
    const notes = userNotes?.filter((note) =>
      note.noteShowOccasion.includes(ocassion)
    );

    if (notes?.length === 0) {
      return;
    }
    const userId = userNotes[0].customerId.toString();

    const preparedNotes = notes?.map((note, i) => {
      return <p key={i}>{note.note}</p>;
    });
    setNoteContent(preparedNotes);
    if (userId) {
      const currentOccasions = JSON.parse(localStorage.getItem(userId) || "[]");
      if (currentOccasions.includes(ocassion)) {
      } else {
        setShowNoteModal(true);
        localStorage.setItem(
          userId,
          JSON.stringify([...currentOccasions, ocassion])
        );
      }
    }
  };

  return (
    <UserNoteContext.Provider value={{ showNoteModal, showNote }}>
      {children}
      <NotePopup
        showPopup={showNoteModal}
        popupContent={noteContent}
        onClose={handleOnClose}
      />
    </UserNoteContext.Provider>
  );
};

const useUserNote = () => {
  const context = useContext(UserNoteContext);
  if (!context) {
    throw new Error("useUserNote must be used within a UserNoteProvider");
  }
  return context;
};

export { UserNoteProvider, useUserNote };
