import React, { FC } from "react";
import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { Colors } from "../../../../configs";
import { CustomButton, CustomTextBox } from "../../../../components";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ColorizeIcon from "@mui/icons-material/Colorize";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

interface IMerchantCreatePageProps {}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const MerchantCreatePage: FC<IMerchantCreatePageProps> = () => {
  const timeZones = moment.tz.names();

  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          height: "57.5rem",
          marginTop: "4.35714286rem",
          paddingInline: "5rem",
          width: "100%",
          maxHeight: "57.5rem",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: Colors.TEXT_GRAY_DARK,
          }}
        >
          Create Merchant
        </Typography>
        <Box
          sx={{
            maxHeight: "51.42857143rem",
            width: "100%",
            overflowY: "auto",
            // make scrollbar style looks better
            "&::-webkit-scrollbar": {
              width: "0.5rem",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: "1rem",
                  color: Colors.TEXT_GRAY_DARK,
                  marginBottom: "1rem",
                }}
              >
                Merchant Information
              </Typography>
              <InputLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{ color: Colors.TEXT_GRAY_DARK, width: "10rem" }}
                >
                  Merchant Name
                </Typography>
                <CustomTextBox placeholder="Merchant Name" />
              </InputLabel>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{ color: Colors.TEXT_GRAY_DARK, width: "10rem" }}
                >
                  Merchant Address
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.8rem",
                  }}
                >
                  <CustomTextBox placeholder="Street" />
                  <CustomTextBox placeholder="Apt/Unit" />
                  <CustomTextBox placeholder="City" />
                  <CustomTextBox placeholder="State" />
                  <CustomTextBox placeholder="Zip" />
                </Box>
              </Box>
              <InputLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{ color: Colors.TEXT_GRAY_DARK, width: "10rem" }}
                >
                  Merchant Email
                </Typography>
                <CustomTextBox placeholder="Merchant Email" />
              </InputLabel>
              <InputLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{ color: Colors.TEXT_GRAY_DARK, width: "10rem" }}
                >
                  Merchant Phone
                </Typography>
                <CustomTextBox placeholder="Merchant Phone" />
              </InputLabel>
            </Box>
            <Box
              sx={{
                width: "50%",
                background: Colors.BACKGROUND_GRAY,
                padding: "2rem",
                borderRadius: "0.714285714rem",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: "1rem",
                  color: Colors.TEXT_GRAY_DARK,
                  marginBottom: "1rem",
                }}
              >
                Merchant Settings
              </Typography>
              <InputLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{ color: Colors.TEXT_GRAY_DARK, width: "10rem" }}
                >
                  Merchant Logo
                </Typography>
                <CustomButton
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput type="file" />
                </CustomButton>
              </InputLabel>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{ color: Colors.TEXT_GRAY_DARK, width: "10rem" }}
                >
                  Merchant Theme
                </Typography>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <RadioGroup defaultValue="light" name="theme-radio-group">
                    <FormControlLabel
                      value="light"
                      control={<Radio />}
                      label="Light"
                    />
                    <FormControlLabel
                      value="dark"
                      control={<Radio />}
                      label="Dark"
                    />
                  </RadioGroup>
                  <Box
                    sx={{
                      paddingTop: "1rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.8rem",
                    }}
                  >
                    <CustomButton
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<ColorizeIcon />}
                    >
                      Primary Color
                      <VisuallyHiddenInput type="color" />
                    </CustomButton>
                    <CustomButton
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<ColorizeIcon />}
                    >
                      Secondary Color
                      <VisuallyHiddenInput type="color" />
                    </CustomButton>
                  </Box>
                </Box>
              </Box>
              <InputLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{ color: Colors.TEXT_GRAY_DARK, width: "10rem" }}
                >
                  Time Zone
                </Typography>
                <Select
                  // value={value}
                  // onChange={(e) => onChange(e.target.value as string)}
                  defaultValue={timeZones[0]}
                  label="Time Zone"
                  sx={{
                    width: "19.7142857rem",
                  }}
                >
                  {timeZones.map((tz) => (
                    <MenuItem key={tz} value={tz}>
                      {tz}
                    </MenuItem>
                  ))}
                </Select>
              </InputLabel>
            </Box>
          </form>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "auto",
            gap: "1.5rem",
          }}
        >
          <Link to="/admin/config/merchants">
            <CustomButton
              size="large"
              sx={{
                fontSize: "2rem",
                paddingInline: "3rem",
                border: `0.1414285714rem solid ${Colors.TEXT_GRAY_DARK}`,
                background: "none",
                color: Colors.TEXT_GRAY_DARK,
              }}
            >
              Cancel
            </CustomButton>
          </Link>
          <CustomButton
            size="large"
            sx={{ fontSize: "2rem", paddingInline: "3rem" }}
          >
            Save
          </CustomButton>
        </Box>
      </Box>
    </AdminLayout>
  );
};

/*

<RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue="female"
    name="radio-buttons-group"
  >
    <FormControlLabel value="female" control={<Radio />} label="Female" />
    <FormControlLabel value="male" control={<Radio />} label="Male" />
    <FormControlLabel value="other" control={<Radio />} label="Other" />
  </RadioGroup>
*/

export default MerchantCreatePage;
