import { FC, ReactNode, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { Colors } from "../../../configs";
import { PosLayout } from "../../../components/layouts/pos-layout/PosLayout";
import CustomerDetailSection from "../../../components/common/pos/customer-detail-section/customer-detail-section";
import OrderDetailSection from "../../../components/common/pos/order-detail-section/order-detail-section";
import CartSection from "../../../components/common/pos/cart-section/cart-section";
import CartQtyHandlerSection from "../../../components/common/pos/cart-qty-handler-section/cart-qty-handler-section";
import CustomMarquee from "../../../components/common/pos/marquee/marquee";
import CustomRadioButton from "../../../components/atoms/radio-button/custom-radio-button";
import { CustomSelectBox } from "../../../components/atoms/custom-select-box/custom-select-box";
import CustomerHistoryTable from "../../../components/common/pos/customer-history-table/customer-history-table";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import { useNavigate, useParams } from "react-router-dom";
import { getUserNotesByIdAction } from "../../../redux/actions/customerAction";
import { useAppDispatch } from "../../../hooks";
import { CustomerSliceAction } from "../../../redux/slice/customerSlice";
import { useUserNote } from "../../../providers/UserNoteProvider";
import { NoteOccasion } from "../../../typings/enum/noteOccasions";
import PickupDetailsSection from "../../../components/common/pos/pickup-details-section/pickup-details-section";
import { CustomButton } from "../../../components/atoms";

export interface ICustomerHistoryPageProps {
  pickupMode?: boolean;
}

const CustomerHistoryPage: FC<ICustomerHistoryPageProps> = ({
  pickupMode = false,
}) => {
  const [filterExpand, setFilterExpand] = useState(pickupMode ? false : true);
  const [sortOption, setSortOption] = useState("invoice" as string);
  const [sortOrder, setSortOrder] = useState("Asc" as string);
  const { showNote } = useUserNote();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  let tableContentHeight = pickupMode ? 18.92 : 39.07; // to make table height adjustable

  const params = useParams();
  const customerId = params.id;

  const handleSortOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSortOrder(event.target.value);
  };

  useEffect(() => {
    if (customerId) {
      dispatch(getUserNotesByIdAction(customerId as string)).then(
        (res: any) => {
          showNote(NoteOccasion.ON_CHECKOUT, res.payload);
        }
      );
    }
    return () => {
      dispatch(CustomerSliceAction.clearCustomerNotes());
    };
  }, [customerId]);

  const handleSortChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    setSortOption(event.target.value as string);
  };

  return (
    <PosLayout>
      <PosInnerPageLayout
        onClickBack={() => navigate(`/pos/${customerId}`)}
        title={""}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            gap: "0.57rem",
          }}
        >
          <Box
            sx={{
              flex: 4,
              display: "flex",
              flexDirection: "column",
              gap: "0.57rem",
              height: "100%",
              maxHeight: "100% !important",
            }}
          >
            <CustomerDetailSection
              showAnalysisButton={true}
              showEditButton={true}
              showHistoryButton={true}
            />
            <CartSection onSelect={() => {}} />
            <CustomMarquee text="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Labore animi magnam provident cupiditate nisi ea nostrum ipsam, nihil accusantium alias dolorum quasi itaque cum qui dolores quo, recusandae nulla et." />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1.07rem",
                flexShrink: 0,
              }}
            >
              <OrderDetailSection />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.71rem",
                }}
              >
                <CartQtyHandlerSection />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "0.71rem",
                  }}
                >
                  <IconButton
                    sx={{
                      background: Colors.MAIN_GRAY,
                      height: "100%",
                      aspectRatio: "1/1",
                      borderRadius: "0.71rem",
                      color: Colors.WHITE,
                      "&:hover": {
                        background: `${Colors.MAIN_GRAY} !important`,
                      },
                      "&:disabled": {
                        background: `${Colors.MAIN_GRAY} !important`,
                        color: Colors.TEXT_GRAY_DARK,
                      },
                    }}
                    disabled={true}
                  >
                    <DeleteIcon sx={{ fontSize: "1.78rem" }} />
                  </IconButton>
                  <CustomButton
                    sx={{
                      background: Colors.MAIN_GRAY,
                      color: Colors.WHITE,
                      fontSize: "1.42rem",
                      flexGrow: 1,
                      borderRadius: "0.71rem",
                      textTransform: "capitalize",
                      "&:hover": {
                        background: `${Colors.MAIN_GRAY} !important`,
                      },
                    }}
                    disabled={true}
                  >
                    Notes
                  </CustomButton>
                  <CustomButton
                    sx={{
                      background: Colors.MAIN_GRAY,
                      color: Colors.WHITE,
                      fontSize: "1.42rem",
                      flexGrow: 1,
                      borderRadius: "0.71rem",
                      textTransform: "capitalize",
                      "&:hover": {
                        background: `${Colors.MAIN_GRAY} !important`,
                      },
                    }}
                    disabled={true}
                  >
                    Customization
                  </CustomButton>
                </Box>
                <CustomButton
                  sx={{
                    width: "100%",
                    fontSize: "2.28rem",
                    borderRadius: "0.71rem",
                    background: Colors.PRIMARY,
                    color: Colors.WHITE,
                    textTransform: "capitalize",
                    flexGrow: 1,
                    "&:hover": {
                      background: `${Colors.PRIMARY} !important`,
                    },
                  }}
                >
                  Check In
                </CustomButton>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "0.14rem", background: Colors.ACCENT_GRAY }}></Box>
          <Box
            sx={{
              flex: 5,
              display: "flex",
              flexDirection: "column",
              gap: "1.42rem",
            }}
          >
            <Typography sx={{ fontSize: "2.57rem", color: "#4D4D4D" }}>
              {pickupMode ? "Pickup" : "Customer History"}
            </Typography>
            {pickupMode ? null : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.71rem",
                    color: "#4D4D4D",
                    fontWeight: 400,
                  }}
                >
                  Filter
                </Typography>
                <IconButton
                  sx={{
                    borderRadius: "0.71rem",
                    background: Colors.MAIN_GRAY,
                    width: "2rem",
                    height: "2rem",
                    "&:hover": { background: Colors.MAIN_GRAY },
                  }}
                  onClick={() => setFilterExpand(!filterExpand)}
                >
                  <KeyboardArrowDownIcon
                    sx={{
                      fontSize: "1.71rem",
                      color: Colors.WHITE,
                      transform: `rotate(${filterExpand ? 180 : 0}deg)`,
                      transition: "all 0.35s",
                    }}
                  />
                </IconButton>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1.42rem",
                overflow: "hidden !important",
                height: filterExpand ? "11rem" : "0rem",
                marginBottom: filterExpand ? "0rem" : "-1.42rem",
                transition: "all 0.35s",
              }}
            >
              <Box
                sx={{
                  background: Colors.BACKGROUND_GRAY,
                  width: "38.94rem",
                  padding: "1.32rem",
                  borderRadius: "0.78rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", gap: "0.7rem" }}>
                  <TextField
                    size="small"
                    sx={{
                      width: "25rem",
                      // width: "12rem",
                      minHeight: "2.78571429rem !important",
                      height: "2.78571429rem !important",
                      maxHeight: "2.78571429rem !important",
                      borderRadius: "0.357142857rem",
                      background: Colors.WHITE,
                      border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                      boxShadow: "none !important",
                      padding: "0 !important",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "& input": {
                        width: "25rem",
                        padding: "0",
                        margin: "0 !important",
                        textAlign: "left",
                        fontSize: "1.2857143rem",
                        fontWeight: 500,
                        paddingLeft: "1.75rem",
                        boxSizing: "border-box",
                      },
                      "& fieldset": {
                        border: "none !important",
                        height: "2.78571429rem !important",
                      },
                    }}
                    type="search"
                    placeholder="Search Invoice Number"
                  />
                  <CustomButton
                    sx={{
                      background: Colors.PRIMARY,
                      color: Colors.WHITE,
                      textTransform: "capitalize",
                      fontSize: "1.1rem",
                      px: "4rem",
                      py: "0rem",
                      "&:hover": { background: Colors.PRIMARY },
                    }}
                  >
                    Search
                  </CustomButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.35rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      color: Colors.TEXT_GRAY_DARK,
                      fontWeight: "bold",
                    }}
                  >
                    Sort by
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      gap: "1.5rem",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <CustomSelectBox
                        formControlProps={{
                          sx: {
                            width: "100%",
                          },
                        }}
                        value={sortOption}
                        onChange={handleSortChange}
                      >
                        <MenuItem value="invoice">Invoice Number</MenuItem>
                        <MenuItem value="dateIn">Date In</MenuItem>
                        <MenuItem value="dueDate">Due Date</MenuItem>
                        <MenuItem value="payDate">Pay Date</MenuItem>
                        <MenuItem value="pickupDate">Pickup Date</MenuItem>
                        <MenuItem value="pieces">Pieces</MenuItem>
                        <MenuItem value="rack">Rack</MenuItem>
                        <MenuItem value="balance">Balance</MenuItem>
                      </CustomSelectBox>
                    </Box>
                    <RadioGroup
                      value={sortOrder}
                      onChange={handleSortOrderChange}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0rem",
                      }}
                    >
                      <FormControlLabel
                        value="Asc"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: "1rem" }}>
                            Ascending
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Desc"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: "1rem" }}>
                            Descending
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "32.71rem",
                  height: "11rem",
                  borderRadius: "0.78rem",
                  background: Colors.BACKGROUND_GRAY,
                  padding: "1.92rem",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    // gap: "1.5rem",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ margin: "-0.66rem 0rem" }}>
                    <CustomRadioButton
                      radioItems={[
                        { value: "di", label: "Date In" },
                        { value: "dd", label: "Due Date" },
                        { value: "pd", label: "Pay Date" },
                      ]}
                      defaultValue={"di"}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      gap: "0.7rem",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ overflow: "hidden" }}
                      >
                        <DatePicker
                          label="Select Filter Date"
                          sx={{
                            height: "4.48571429rem !important",
                            "& .MuiInputBase-root": {
                              height: "4.48571429rem !important",
                              overflow: "hidden",
                              width: "18.5rem",
                              py: "1.6rem",
                            },
                            "& .css-11v3xop-MuiFormLabel-root-MuiInputLabel-root":
                              {
                                top: "50% !important",
                                left: "0% !important",
                                fontSize: "1.2857143rem",
                                transform: "translate(1rem, -50%) scale(1)",
                              },
                            "& .css-kyuif4-MuiFormLabel-root-MuiInputLabel-root":
                              {
                                top: "0% !important",
                                left: "0% !important",
                                transform: "translate(1rem, -35%) scale(0.75)",
                              },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <IconButton
                      sx={{
                        background: Colors.PRIMARY,
                        color: Colors.WHITE,
                        width: "4.48571429rem",
                        height: "4.48571429rem",
                        borderRadius: "0.714285714rem",
                        "&:hover": {
                          background: Colors.PRIMARY,
                        },
                      }}
                    >
                      <FilterAltIcon sx={{ fontSize: "1.42857143rem" }} />
                    </IconButton>
                    <IconButton
                      sx={{
                        background: Colors.SECONDARY,
                        color: Colors.WHITE,
                        width: "4.48571429rem",
                        height: "4.48571429rem",
                        borderRadius: "0.714285714rem",
                        "&:hover": {
                          background: Colors.SECONDARY,
                        },
                      }}
                    >
                      <ReplayIcon sx={{ fontSize: "1.42857143rem" }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "73.07rem",
                height: filterExpand
                  ? `${tableContentHeight + 6.71}rem`
                  : `${tableContentHeight + 17.71}rem`,
                background: Colors.BACKGROUND_GRAY,
                padding: "1.32rem 0.75rem",
                borderRadius: "0.78rem",
                transition: "all 0.35s",
              }}
            >
              <CustomerHistoryTable
                contentHeight={
                  filterExpand
                    ? `${tableContentHeight}rem`
                    : `${tableContentHeight + 11}rem`
                }
              />
            </Box>
            <Box
              sx={{
                display: pickupMode ? "flex" : "none",
                flexDirection: "row",
                gap: "1.64285714rem",
                height: "21.8571429rem",
              }}
            >
              <Box
                sx={{
                  borderRadius: "0.71rem",
                  padding: "1.64285714rem",
                  background: Colors.BACKGROUND_GRAY,
                  width: "27.21428572rem",
                }}
              >
                <PickupDetailsSection />
              </Box>
              <Box
                sx={{
                  borderRadius: "0.71rem",
                  padding: "2.35714286rem 1rem",
                  background: Colors.BACKGROUND_GRAY,
                  width: "44.28571428rem",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                <CustomButton
                  sx={{
                    width: "20.5685714rem",
                    height: "4.68642857rem",
                    borderRadius: "0.71rem",
                    padding: "1.64285714rem",
                    color: Colors.WHITE,
                    fontSize: "1.64285714rem",
                    background: Colors.SECONDARY,
                    textTransform: "capitalize",
                  }}
                >
                  Pre Pay - Not Pickup
                </CustomButton>
                <CustomButton
                  sx={{
                    width: "20.5685714rem",
                    height: "4.68642857rem",
                    borderRadius: "0.71rem",
                    padding: "1.64285714rem",
                    color: Colors.WHITE,
                    fontSize: "1.64285714rem",
                    background: Colors.PRIMARY,
                    textTransform: "capitalize",
                  }}
                >
                  Pay and Pickup
                </CustomButton>
                <CustomButton
                  sx={{
                    width: "20.5685714rem",
                    height: "4.68642857rem",
                    borderRadius: "0.71rem",
                    padding: "1.64285714rem",
                    color: Colors.WHITE,
                    fontSize: "1.64285714rem",
                    background: Colors.MAIN_GRAY,
                    textTransform: "capitalize",
                  }}
                >
                  Print invoice Location
                </CustomButton>
                <CustomButton
                  sx={{
                    width: "20.5685714rem",
                    height: "4.68642857rem",
                    borderRadius: "0.71rem",
                    padding: "1.64285714rem",
                    color: Colors.WHITE,
                    fontSize: "1.64285714rem",
                    background: Colors.MAIN_GRAY,
                    textTransform: "capitalize",
                  }}
                >
                  Add retail item
                </CustomButton>
                <CustomButton
                  sx={{
                    width: "20.5685714rem",
                    height: "4.68642857rem",
                    borderRadius: "0.71rem",
                    padding: "1.64285714rem",
                    color: Colors.WHITE,
                    fontSize: "1.64285714rem",
                    background: Colors.MAIN_GRAY,
                    textTransform: "capitalize",
                  }}
                >
                  Partial Pickup
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </PosInnerPageLayout>
    </PosLayout>
  );
};

export default CustomerHistoryPage;
