import React from "react";
import { RouterProvider } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { routers } from "./configs";
import StarterContainer from "./components/common/StarterContainer";
import TenantThemeProvider from "./util/tenent-theme-provider";
import TranslationProvider from "./util/translation-provider";
import { getTenantId } from "./util/common-functions";
import BaseSiteHomePage from "./pages/base-site/home";

import "./index.css";
import withStarterContainer from "./components/common/StarterContainer";
import InactivityTracker from "./components/inactivity-tracker/inactivity-tracker";
import { UserNoteProvider } from "./providers/UserNoteProvider";
import { SnackbarProvider } from "./providers/SnackbarProvider";

const container = document.getElementById("root")!;
const root = createRoot(container);
const tenantId = getTenantId();

if (!tenantId) {
  root.render(
    <React.StrictMode>
      <TenantThemeProvider tenantId={tenantId || ""}>
        <BaseSiteHomePage />
      </TenantThemeProvider>
    </React.StrictMode>
  );
} else {
  const WithInitializer = withStarterContainer(() => (
    <RouterProvider router={routers} />
  ));
  root.render(
    // <React.StrictMode>
      <TenantThemeProvider tenantId={tenantId || ""}>
        <TranslationProvider tenantId={tenantId || ""}>
          <Provider store={store}>
            <UserNoteProvider>
              <SnackbarProvider>
                <InactivityTracker />
                <WithInitializer />
              </SnackbarProvider>
            </UserNoteProvider>
          </Provider>
        </TranslationProvider>
      </TenantThemeProvider>
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
