import React from 'react'
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {IUserResponse} from "../../interfaces";

const PublicRoute = (props:{token?:string, children:JSX.Element, user?:IUserResponse, authDetailsInitialized?: boolean}) => {
    if(!props.authDetailsInitialized){
        return <p>Loading...</p>;
    }
    return !(props.token !== null && props.user!==null) ? props.children:<Navigate to={'/pos/lobby'}/>
}

const mapStateToProps = (state:any) => state.auth;
export default connect(mapStateToProps, null)(PublicRoute);