export const MonthlyIcon = ({ color = "#515151" }) => {
  return (
    <svg
      width="27"
      height="31"
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 21.7969V1.45312C27 0.647852 26.3551 0 25.5536 0H5.78571C2.59152 0 0 2.60352 0 5.8125V25.1875C0 28.3965 2.59152 31 5.78571 31H25.5536C26.3551 31 27 30.3521 27 29.5469V28.5781C27 28.124 26.7891 27.7123 26.4636 27.4459C26.2105 26.5135 26.2105 23.8555 26.4636 22.923C26.7891 22.6627 27 22.251 27 21.7969ZM7.71429 8.11328C7.71429 7.91348 7.87701 7.75 8.07589 7.75H20.8527C21.0516 7.75 21.2143 7.91348 21.2143 8.11328V9.32422C21.2143 9.52402 21.0516 9.6875 20.8527 9.6875H8.07589C7.87701 9.6875 7.71429 9.52402 7.71429 9.32422V8.11328ZM7.71429 11.9883C7.71429 11.7885 7.87701 11.625 8.07589 11.625H20.8527C21.0516 11.625 21.2143 11.7885 21.2143 11.9883V13.1992C21.2143 13.399 21.0516 13.5625 20.8527 13.5625H8.07589C7.87701 13.5625 7.71429 13.399 7.71429 13.1992V11.9883ZM22.9862 27.125H5.78571C4.71897 27.125 3.85714 26.2592 3.85714 25.1875C3.85714 24.1219 4.725 23.25 5.78571 23.25H22.9862C22.8717 24.2854 22.8717 26.0896 22.9862 27.125Z"
        fill={color}
      />
    </svg>
  );
};
