export interface IUpcharge {
  id: number;
  label: string;
  subUpcharges: ISubUpcharge[];
}

export interface ISelectedUpcharge {
  id: number;
  label: string;
  subUpcharge: ISubUpcharge;
}

export interface ISubUpcharge {
  id: number;
  label: string;
  price: number;
}

export const upchargePrices: IUpcharge[] = [
  {
    id: 1,
    label: "Beads",
    subUpcharges: [
      {
        id: 1,
        label: "Minor",
        price: 0.5,
      },
      {
        id: 2,
        label: "Medium",
        price: 1.0,
      },
      {
        id: 3,
        label: "Major",
        price: 1.5,
      },
    ],
  },
  {
    id: 2,
    label: "Leather Trim",
    subUpcharges: [
      {
        id: 4,
        label: "Minor",
        price: 6.0,
      },
      {
        id: 5,
        label: "Medium",
        price: 8.0,
      },
      {
        id: 6,
        label: "Major",
        price: 10.0,
      },
    ],
  },
  {
    id: 3,
    label: "Fur Trim",
    subUpcharges: [
      {
        id: 7,
        label: "Minor",
        price: 6.0,
      },
      {
        id: 8,
        label: "Medium",
        price: 8.0,
      },
      {
        id: 9,
        label: "Major",
        price: 10.0,
      },
    ],
  },
  {
    id: 4,
    label: "Faux Trim",
    subUpcharges: [
      {
        id: 10,
        label: "Minor",
        price: 6.0,
      },
      {
        id: 11,
        label: "Medium",
        price: 8.0,
      },
      {
        id: 12,
        label: "Major",
        price: 10.0,
      },
    ],
  },
  {
    id: 5,
    label: "Ruffles",
    subUpcharges: [
      {
        id: 13,
        label: "Minor",
        price: 4.0,
      },
      {
        id: 14,
        label: "Medium",
        price: 6.0,
      },
      {
        id: 15,
        label: "Major",
        price: 8.0,
      },
    ],
  },
  {
    id: 6,
    label: "Roll Hair",
    subUpcharges: [
      {
        id: 16,
        label: "Garment - Light",
        price: 6.0,
      },
      {
        id: 17,
        label: "Garment - Medium",
        price: 8.0,
      },
      {
        id: 18,
        label: "Garment - Large",
        price: 10.0,
      },
      {
        id: 19,
        label: "Household - Light",
        price: 8.0,
      },
      {
        id: 20,
        label: "Household - Medium",
        price: 10.0,
      },
      {
        id: 21,
        label: "Household - Large",
        price: 12.0,
      },
    ],
  },
  {
    id: 7,
    label: "Roll Lint",
    subUpcharges: [
      {
        id: 22,
        label: "Garment - Light",
        price: 6.0,
      },
      {
        id: 23,
        label: "Garment - Medium",
        price: 8.0,
      },
      {
        id: 24,
        label: "Garment - Large",
        price: 10.0,
      },
      {
        id: 25,
        label: "Household - Light",
        price: 8.0,
      },
      {
        id: 26,
        label: "Household - Medium",
        price: 10.0,
      },
      {
        id: 27,
        label: "Household - Large",
        price: 12.0,
      },
    ],
  },
  {
    id: 8,
    label: "Starch",
    subUpcharges: [
      {
        id: 28,
        label: "Garment - Light",
        price: 6.0,
      },
      {
        id: 29,
        label: "Garment - Medium",
        price: 8.0,
      },
      {
        id: 30,
        label: "Garment - Large",
        price: 10.0,
      },
      {
        id: 31,
        label: "Household - Light",
        price: 8.0,
      },
      {
        id: 32,
        label: "Household - Medium",
        price: 10.0,
      },
      {
        id: 33,
        label: "Household - Large",
        price: 12.0,
      },
    ],
  },
  {
    id: 9,
    label: "Sizing",
    subUpcharges: [
      {
        id: 34,
        label: "Garment - Light",
        price: 6.0,
      },
      {
        id: 35,
        label: "Garment - Medium",
        price: 8.0,
      },
      {
        id: 36,
        label: "Garment - Large",
        price: 10.0,
      },
      {
        id: 37,
        label: "Household - Light",
        price: 8.0,
      },
      {
        id: 38,
        label: "Household - Medium",
        price: 10.0,
      },
      {
        id: 39,
        label: "Household - Large",
        price: 12.0,
      },
    ],
  },
  {
    id: 10,
    label: "Box",
    subUpcharges: [
      {
        id: 40,
        label: "Wedding Gown Box",
        price: 60.0,
      },
      {
        id: 41,
        label: "Wedding Gown Box Jumbo",
        price: 80.0,
      },
      {
        id: 42,
        label: "Christening Gown Box",
        price: 40.0,
      },
      {
        id: 43,
        label: "Household Bag",
        price: 0.5,
      },
      {
        id: 44,
        label: "Paper Carrying Bag",
        price: 0.5,
      },
    ],
  },
  {
    id: 11,
    label: "Shave",
    subUpcharges: [
      {
        id: 45,
        label: "Garment - Light",
        price: 6.0,
      },
      {
        id: 46,
        label: "Garment - Medium",
        price: 8.0,
      },
      {
        id: 47,
        label: "Garment - Large",
        price: 10.0,
      },
      {
        id: 48,
        label: "Household - Light",
        price: 8.0,
      },
      {
        id: 49,
        label: "Household - Medium",
        price: 10.0,
      },
      {
        id: 50,
        label: "Household - Large",
        price: 12.0,
      },
    ],
  },
  {
    id: 12,
    label: "Water Repellent",
    subUpcharges: [
      {
        id: 51,
        label: "Small Garment",
        price: 6.0,
      },
      {
        id: 52,
        label: "Medium Garment",
        price: 8.0,
      },
      {
        id: 53,
        label: "Large Garment",
        price: 10.0,
      },
    ],
  },
  {
    id: 13,
    label: "Bulky Garment",
    subUpcharges: [
      {
        id: 54,
        label: "Heavy Garment",
        price: 3.0,
      },
      {
        id: 55,
        label: "Extra Heavy Garment",
        price: 5.0,
      },
    ],
  },
  {
    id: 14,
    label: "Creases",
    subUpcharges: [
      {
        id: 56,
        label: "Per Pleat",
        price: 0.35,
      },
      {
        id: 57,
        label: "Crease Sleeves",
        price: 1.0,
      },
      {
        id: 58,
        label: "Military Crease",
        price: 1.0,
      },
    ],
  },
  {
    id: 15,
    label: "Tie Fringe",
    subUpcharges: [
      {
        id: 59,
        label: "Garment - Light",
        price: 6.0,
      },
      {
        id: 60,
        label: "Garment - Medium",
        price: 8.0,
      },
      {
        id: 61,
        label: "Garment - Large",
        price: 10.0,
      },
      {
        id: 62,
        label: "Household - Light",
        price: 6.0,
      },
      {
        id: 63,
        label: "Household - Medium",
        price: 8.0,
      },
      {
        id: 64,
        label: "Household - Large",
        price: 10.0,
      },
    ],
  },
  {
    id: 16,
    label: "Blocking",
    subUpcharges: [
      {
        id: 65,
        label: "Garment",
        price: 6.0,
      },
      {
        id: 66,
        label: "Household",
        price: 8.0,
      },
    ],
  },
  {
    id: 17,
    label: "Buttons",
    subUpcharges: [
      {
        id: 67,
        label: "Cover Buttons",
        price: 0.1,
      },
      {
        id: 68,
        label: "Cover Buckles",
        price: 0.1,
      },
    ],
  },
  {
    id: 18,
    label: "Ozone",
    subUpcharges: [
      {
        id: 69,
        label: "Garment - Light",
        price: 6.0,
      },
      {
        id: 70,
        label: "Garment - Medium",
        price: 8.0,
      },
      {
        id: 71,
        label: "Garment - Large",
        price: 10.0,
      },
      {
        id: 72,
        label: "Household - Light",
        price: 8.0,
      },
      {
        id: 73,
        label: "Household - Medium",
        price: 10.0,
      },
      {
        id: 74,
        label: "Household - Large",
        price: 12.0,
      },
    ],
  },
  {
    id: 100000,
    label: "Custom",
    subUpcharges: [
      // {
      //   id: 100000,
      //   label: "Custom",
      //   price: 0.0,
      // },
    ],
  },
];
