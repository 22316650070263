import { Box } from "@mui/material";
import { FC } from "react";
import SingleDetail from "../../../atoms/single-detail/single-detail";

export interface IPickupDetailsSectionProps {}

const PickupDetailsSection: FC<IPickupDetailsSectionProps> = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "0.535rem",
      }}
    >
      <SingleDetail label="Total Invoices" value={2} />
      <SingleDetail label="Total Price" value={5} />
      <SingleDetail label="Retail Items" value={`$ ${0.0}`} />
      <SingleDetail label="Total" value={`$ ${22.0}`} />
      <SingleDetail label="Discounts" value={`$ ${0.0}`} />
      <SingleDetail label="Taxes" value={`$ ${0.0}`} />
      <SingleDetail label="Total Due" value={`$ ${22.0}`} />
    </Box>
  );
};

export default PickupDetailsSection;
