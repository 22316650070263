import { createSlice } from "@reduxjs/toolkit";
import {
  BaseResponse,
  CategoryRelationshipResponse,
  CategoryResponse,
  SecondSubCategoryResponse,
  SubcategoryResponse,
} from "../../typings/interfaces";
import {
  getAlterationPrices,
  getAlterationSubCategories,
  getCategoryAction,
  getPricing,
  getFabricTypes,
  getSecondSubCategoryAction,
  getSubCategoryAction,
  getCategoryRelationship,
  getCategoryMaterialRelationship,
  getSubCategoryMaterialRelationship,
  uploadPriceList,
} from "../actions/pricingAction";
import { PricingResponse } from "../../typings/interfaces/responses/pricing-response";
import { AlterationPriceResponse } from "../../typings/interfaces/responses/alteration-price-response";
import { AlterationSubCategoryResponse } from "../../typings/interfaces/responses/alteration-sub-category-response";
import { FabricTypeResponse } from "../../typings/interfaces/responses/fabricTypeResponse";
import { CategoryMaterialRelationshipResponse } from "../../typings/interfaces/responses/category-material-relationship";
import { SubCategoryMaterialRelationshipResponse } from "../../typings/interfaces/responses/sub-category-material-relationship";

export interface PriceState {
  categoryResponse: BaseResponse<CategoryResponse> | null;
  categoryResponseLoading: boolean;
  categoryResponseError: string | null;

  categoryRelationshipResponse: BaseResponse<CategoryRelationshipResponse> | null;
  categoryRelationshipResponseLoading: boolean;
  categoryRelationshipResponseError: string | null;

  subCategoryResponse: BaseResponse<SubcategoryResponse> | null;
  subCategoryResponseLoading: boolean;
  subCategoryResponseError: string | null;

  secondSubCategoryResponse: BaseResponse<SecondSubCategoryResponse> | null;
  secondSubCategoryResponseLoading: boolean;
  secondSubCategoryResponseError: string | null;

  dryCleanResponse: BaseResponse<PricingResponse> | null;
  dryCleanResponseLoading: boolean;
  dryCleanResponseError: string | null;

  alterationModificationType: Array<PricingResponse> | null;

  alterationPrices: Array<PricingResponse> | null;

  alterationPriceResponse: BaseResponse<AlterationPriceResponse> | null;
  alterationPriceResponseError: string | null;
  alterationPriceResponseLoading: boolean;

  alterationSubCategoryResponse: BaseResponse<AlterationSubCategoryResponse> | null;
  alterationSubCategoryResponseError: string | null;
  alterationSubCategoryResponseLoading: boolean;

  fabricTypesResponse: BaseResponse<FabricTypeResponse> | null;
  fabricTypesResponseError: string | null;
  fabricTypesResponseLoading: boolean;

  categoryMaterialRelationship: BaseResponse<CategoryMaterialRelationshipResponse> | null;
  categoryMaterialRelationshipLoading: boolean;
  categoryMaterialRelationshipError: string | null;

  subCategoryMaterialRelationship: BaseResponse<SubCategoryMaterialRelationshipResponse> | null;
  subCategoryMaterialRelationshipLoading: boolean;
  subCategoryMaterialRelationshipError: string | null;

  uploadPriceListResponse: BaseResponse<any> | null;
  uploadPriceListResponseLoading: boolean;
  uploadPriceListResponseError: string | null;
}

const initialState: PriceState = {
  categoryResponse: null,
  categoryResponseError: null,
  categoryResponseLoading: false,

  secondSubCategoryResponse: null,
  secondSubCategoryResponseError: null,
  secondSubCategoryResponseLoading: false,

  subCategoryResponse: null,
  subCategoryResponseError: null,
  subCategoryResponseLoading: false,

  dryCleanResponse: null,
  dryCleanResponseError: null,
  dryCleanResponseLoading: false,

  alterationPriceResponse: null,
  alterationPriceResponseError: null,
  alterationPriceResponseLoading: false,

  alterationSubCategoryResponse: null,
  alterationSubCategoryResponseError: null,
  alterationSubCategoryResponseLoading: false,

  fabricTypesResponse: null,
  fabricTypesResponseError: null,
  fabricTypesResponseLoading: false,

  categoryRelationshipResponse: null,
  categoryRelationshipResponseLoading: false,
  categoryRelationshipResponseError: null,

  categoryMaterialRelationship: null,
  categoryMaterialRelationshipLoading: false,
  categoryMaterialRelationshipError: null,

  subCategoryMaterialRelationship: null,
  subCategoryMaterialRelationshipLoading: false,
  subCategoryMaterialRelationshipError: null,

  uploadPriceListResponse: null,
  uploadPriceListResponseLoading: false,
  uploadPriceListResponseError: null,

  alterationModificationType: [],
  alterationPrices: []
};

const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategoryAction.fulfilled, (state, action) => {
      state.categoryResponse = action.payload.response;
      state.categoryResponseLoading = false;
      state.categoryResponseError = null;
    });
    builder.addCase(getCategoryAction.pending, (state, action) => {
      state.categoryResponse = null;
      state.categoryResponseLoading = true;
      state.categoryResponseError = null;
    });
    builder.addCase(getCategoryAction.rejected, (state, action) => {
      state.categoryResponse = null;
      state.categoryResponseLoading = false;
      state.categoryResponseError = action.error as string;
    });

    builder.addCase(getSubCategoryAction.fulfilled, (state, action) => {
      state.subCategoryResponse = action.payload.response;
      state.subCategoryResponseLoading = false;
      state.subCategoryResponseError = null;
    });
    builder.addCase(getSubCategoryAction.pending, (state, action) => {
      state.subCategoryResponse = null;
      state.subCategoryResponseLoading = true;
      state.subCategoryResponseError = null;
    });
    builder.addCase(getSubCategoryAction.rejected, (state, action) => {
      state.subCategoryResponse = null;
      state.subCategoryResponseLoading = false;
      state.subCategoryResponseError = action.error as string;
    });

    builder.addCase(getSecondSubCategoryAction.fulfilled, (state, action) => {
      state.secondSubCategoryResponse = action.payload.response;
      state.secondSubCategoryResponseLoading = false;
      state.secondSubCategoryResponseError = null;
    });
    builder.addCase(getSecondSubCategoryAction.pending, (state, action) => {
      state.secondSubCategoryResponse = null;
      state.secondSubCategoryResponseLoading = true;
      state.secondSubCategoryResponseError = null;
    });
    builder.addCase(getSecondSubCategoryAction.rejected, (state, action) => {
      state.secondSubCategoryResponse = null;
      state.secondSubCategoryResponseLoading = false;
      state.secondSubCategoryResponseError = action.error as string;
    });

    builder.addCase(getPricing.fulfilled, (state, action) => {
      state.dryCleanResponse = action.payload.response;
      const filtered = action.payload.response?.body?.filter(
        (modification) =>
          modification.categoryName.includes("Alteration") ||
          modification.categoryName.includes("alteration")
      );
      const distinct = (value: any, index: any, self: any) => {
        return self.findIndex((e: any) => e.materialName === value.materialName) === index;
      };
    
      const uniqueList = filtered?.filter(distinct);
      state.alterationModificationType = uniqueList;
      state.alterationPrices = filtered;
      state.dryCleanResponseLoading = false;
      state.dryCleanResponseError = null;
    });
    builder.addCase(getPricing.pending, (state, action) => {
      state.dryCleanResponse = null;
      state.dryCleanResponseLoading = true;
      state.dryCleanResponseError = null;
      state.alterationModificationType = [];
    });
    builder.addCase(getPricing.rejected, (state, action) => {
      state.dryCleanResponse = null;
      state.dryCleanResponseLoading = false;
      state.dryCleanResponseError = action.error as string;
      state.alterationModificationType = [];
    });

    builder.addCase(getAlterationPrices.fulfilled, (state, action) => {
      state.alterationPriceResponse = action.payload.response;
      state.alterationPriceResponseLoading = false;
      state.alterationPriceResponseError = null;
    });
    builder.addCase(getAlterationPrices.pending, (state, action) => {
      state.alterationPriceResponse = null;
      state.alterationPriceResponseLoading = true;
      state.alterationPriceResponseError = null;
    });
    builder.addCase(getAlterationPrices.rejected, (state, action) => {
      state.alterationPriceResponse = null;
      state.alterationPriceResponseLoading = false;
      state.alterationPriceResponseError = action.error as string;
    });

    builder.addCase(getAlterationSubCategories.fulfilled, (state, action) => {
      state.alterationSubCategoryResponse = action.payload.response;
      state.alterationSubCategoryResponseLoading = false;
      state.alterationSubCategoryResponseError = null;
    });
    builder.addCase(getAlterationSubCategories.pending, (state, action) => {
      state.alterationSubCategoryResponse = null;
      state.alterationSubCategoryResponseLoading = true;
      state.alterationSubCategoryResponseError = null;
    });
    builder.addCase(getAlterationSubCategories.rejected, (state, action) => {
      state.alterationSubCategoryResponse = null;
      state.alterationSubCategoryResponseLoading = false;
      state.alterationSubCategoryResponseError = action.error as string;
    });

    builder.addCase(getFabricTypes.fulfilled, (state, action) => {
      state.fabricTypesResponse = action.payload.response;
      state.fabricTypesResponseLoading = false;
      state.fabricTypesResponseError = null;
    });
    builder.addCase(getFabricTypes.pending, (state, action) => {
      state.fabricTypesResponse = null;
      state.fabricTypesResponseLoading = true;
      state.fabricTypesResponseError = null;
    });
    builder.addCase(getFabricTypes.rejected, (state, action) => {
      state.fabricTypesResponse = null;
      state.fabricTypesResponseLoading = false;
      state.fabricTypesResponseError = action.error as string;
    });

    builder.addCase(getCategoryRelationship.fulfilled, (state, action) => {
      state.categoryRelationshipResponse = action.payload.response;
      state.categoryRelationshipResponseLoading = false;
      state.categoryRelationshipResponseError = null;
    });
    builder.addCase(getCategoryRelationship.pending, (state, action) => {
      state.categoryRelationshipResponse = null;
      state.categoryRelationshipResponseLoading = true;
      state.categoryRelationshipResponseError = null;
    });
    builder.addCase(getCategoryRelationship.rejected, (state, action) => {
      state.categoryRelationshipResponse = null;
      state.categoryRelationshipResponseLoading = false;
      state.categoryRelationshipResponseError = action.error as string;
    });

    builder.addCase(
      getCategoryMaterialRelationship.fulfilled,
      (state, action) => {
        state.categoryMaterialRelationship = action.payload.response;
        state.categoryMaterialRelationshipLoading = false;
        state.categoryMaterialRelationshipError = null;
      }
    );
    builder.addCase(
      getCategoryMaterialRelationship.pending,
      (state, action) => {
        state.categoryMaterialRelationship = null;
        state.categoryMaterialRelationshipLoading = true;
        state.categoryMaterialRelationshipError = null;
      }
    );
    builder.addCase(
      getCategoryMaterialRelationship.rejected,
      (state, action) => {
        state.categoryMaterialRelationship = null;
        state.categoryMaterialRelationshipLoading = false;
        state.categoryMaterialRelationshipError = action.error as string;
      }
    );

    builder.addCase(
      getSubCategoryMaterialRelationship.fulfilled,
      (state, action) => {
        state.subCategoryMaterialRelationship = action.payload.response;
        state.subCategoryMaterialRelationshipLoading = false;
        state.subCategoryMaterialRelationshipError = null;
      }
    );
    builder.addCase(
      getSubCategoryMaterialRelationship.pending,
      (state, action) => {
        state.subCategoryMaterialRelationship = null;
        state.subCategoryMaterialRelationshipLoading = true;
        state.subCategoryMaterialRelationshipError = null;
      }
    );
    builder.addCase(
      getSubCategoryMaterialRelationship.rejected,
      (state, action) => {
        state.subCategoryMaterialRelationship = null;
        state.subCategoryMaterialRelationshipLoading = false;
        state.subCategoryMaterialRelationshipError = action.error as string;
      }
    );

    builder.addCase(uploadPriceList.fulfilled, (state, action) => {
      state.uploadPriceListResponse = action.payload.response;
      state.uploadPriceListResponseLoading = false;
      state.uploadPriceListResponseError = null;
    });
    builder.addCase(uploadPriceList.pending, (state, action) => {
      state.uploadPriceListResponse = null;
      state.uploadPriceListResponseLoading = true;
      state.uploadPriceListResponseError = null;
    });
    builder.addCase(uploadPriceList.rejected, (state, action) => {
      state.uploadPriceListResponse = null;
      state.uploadPriceListResponseLoading = false;
      state.uploadPriceListResponseError = action.error as string;
    });
  },
});

export default pricingSlice.reducer;
export const PricingSliceAction = pricingSlice.actions;
